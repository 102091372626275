.icon__notification {
  width: 1.45rem;
  height: 1.45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  cursor: pointer;

  svg {
    width: 0.796rem;
    height: 0.8rem;
  }

  &:hover {
    background-color: $grey-light;
    border: 0.05rem solid $grey;
    border-radius: 0.2rem;
  }

  &--active {
    background-color: $grey-light;
    border: 0.05rem solid $grey;
    border-radius: 0.2rem;

    svg > path {
      fill: $color-green;
    }
  }
}

.icon-arrow-down {
  @include flexCenter;

  svg {
    width: rem(9);
    height: rem(6);

    > path {
      fill: #007e75;
    }
  }
}

.icon-arrow-up {
  @include flexCenter;

  svg {
    width: rem(9);
    height: rem(6);

    > path {
      fill: #007e75;
    }
  }
}

.icon-calender {
  @include flexCenter;
}

.icon-checklist {
  @include flexCenter;

  svg {
    width: rem(22);
    height: rem(14);
  }
}

.icon-close {
  @include flexCenter;
  cursor: pointer;

  &.background {
    &_white {
      background-color: #fff;
      width: rem(24);
      height: rem(24);
      border: rem(1) solid #caced5;
      border-radius: rem(4);
    }
  }

  svg {
    width: rem(10.67);
    height: rem(10.67);
  }
}

.icon-close {
  @include flexCenter;
  cursor: pointer;

  svg {
    width: rem(20);
    height: rem(14);
  }
}

.icon-config {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }
}

.iconCsv {
  @include flexCenter;

  svg {
    width: rem(15.03);
    height: rem(18.17);
  }
}

.icon-display {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }
}

.icon-edit {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }

  &.disable {
    & svg path {
      fill: #364961;
    }
  }
}

.icon-filter {
  @include flexCenter();

  svg {
    width: rem(15.04);
    height: rem(12.86);
  }
}

.icon-file {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }

  &.disable {
    & svg path {
      fill: #364961;
    }
  }
}

.iconHide {
  @include flexCenter;

  svg {
    width: rem(15);
    height: rem(19);
  }
}

.icon-info {
  @include flexCenter;
  background-color: #caced5;
  border-radius: rem(11);
  width: rem(24);
  height: rem(24);

  svg {
    width: rem(3.72);
    height: rem(11.87);
  }

  &:hover {
    background-color: #ffd500;

    & svg path {
      fill: #657991;
    }
  }
}

.iconInfoSimple {
  @include flexCenter;

  svg {
    width: rem(16.17);
    height: rem(16.17);
  }
}

.icon-layer {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }
}

.icon-minus {
  @include flexCenter;

  svg {
    width: rem(14);
    height: rem(2);
  }

  &:hover {
    & svg rect {
      fill: #007e75;
    }
  }
}

.icon-padlock {
  @include flexCenter;

  svg {
    width: rem(14);
    height: rem(16);
  }
}

.iconPdf {
  @include flexCenter;

  svg {
    width: rem(15.03);
    height: rem(18.17);
  }
}

.icon-plus {
  @include flexCenter;

  svg {
    width: rem(14);
    height: rem(14);
  }

  &:hover {
    & svg rect {
      fill: #007e75;
    }
  }
}

.icon-route {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }

  &.disable {
    & svg path {
      fill: #364961;
    }
  }
}

.icon-security {
  @include flexCenter;

  svg {
    width: rem(12.75);
    height: rem(13.71);
  }
}

.iconShow {
  @include flexCenter;

  svg {
    width: rem(15);
    height: rem(19);
  }
}

.icon-statistic {
  @include flexCenter;

  svg {
    width: rem(15.81);
    height: rem(14.23);
  }
}

.icon-user {
  @include flexCenter;

  svg {
    width: rem(11.67);
    height: rem(13.47);
  }
}

.iconView {
  @include flexCenter;

  svg {
    width: rem(18.45);
    height: rem(14.41);
  }
}

.iconXls {
  @include flexCenter;

  svg {
    width: rem(15.03);
    height: rem(18.17);
  }
}

.icon-zoom-pass {
  @include flexCenter;

  svg {
    width: rem(18);
    height: rem(18);
  }

  &:hover {
    & svg path {
      fill: #007e75;
    }
  }
}
