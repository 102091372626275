.AccountDetailCard {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &--title {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #495057;
    padding: 0 0 0.75rem 0;
  }
  &--body-content {
    padding: 1.5rem 0 0 0;
  }
  &--footer {
    padding: 1.25rem 0 0 0;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
