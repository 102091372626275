.AsmanCardSESComponent {
  &__wrapper {
    display: flex;
    gap: 1rem;
  }

  &__chart {
    position: relative;

    &-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      &--title {
        font-weight: 700;
        color: #474747;
        font-size: 22px;

      }

      &--value {
        margin-top: 0.5rem;
        color: #696969;
      }
    }
  }

  &__list {
    width: 100%;
  }

  &__chart-wrap {
    margin-top: 0.75rem;
  }

  &__chart-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000000;
    margin-bottom: 0.5rem;
  }

  &__chart-bar-item {
    width: 100%;
    height: 2.5rem;
    background: #e8e8e8;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  &__chart-bar {
    left: 0;
    width: 0;
    background-color: #07827c;
    padding: 0.375rem;
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 2.5rem;
  }
}
