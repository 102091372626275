.other-services-popover {
  &__box {
    text-align: center;
    cursor: pointer;
  }
  &__label {
    padding: 8px 0 16px 0;
  }
  &__content {
    padding: 12px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafcfd;
    padding-left: 16px;
    padding-right: 12px;
    &__title {
      font-size: 22px;
      color: #007e75;
      font-weight: 500;
    }
  }
  &__button {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    color: #4d4f5c;
  }
}
