.whats-new {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0.313rem;
  background-color: #ffffff;
  height: 43rem;
  margin-top: 1rem;

  &__header {
    background-color: #f2f4f7;
    border-top-left-radius: 0.313rem;
    border-top-right-radius: 00.313rem;

    span {
      color: #364961;
      margin: 0rem 1rem;
      line-height: 2rem;
    }
  }

  &__title {
    margin: 1rem;
    border-bottom: 0.063rem solid #61647730;

    p {
      font-size: 2.75rem;
      width: 8.5rem;
      line-height: 3rem;
      margin-bottom: 1rem;
      color: #364961;
    }
  }

  &__content {
    margin: 1rem;
    width: 33rem;

    &__text {
      p {
        font-size: 0.875rem;
        margin-bottom: 0rem !important;
      }
      ul {
        margin-bottom: 0rem !important;
        margin-left: 0.75rem;
      }

      li {
        font-size: 0.875rem;
        list-style: '-  ';
      }
    }
  }

  &__add {
    margin: 1rem 1rem 1.25rem;
    border-bottom: 0.063rem solid #61647730;
    p {
      font-size: 1rem;
      margin-bottom: 0rem;
    }
    &__list {
      margin: 1rem;
      width: 33rem;
      p {
        margin-bottom: 0rem;
      }
      ul li {
        font-size: 0.875rem;
      }
    }
  }
}
