.step-breadcrumbs {
  &--wrapper {
    display: flex;
  }

  &--index {
    display: flex;
    align-items: center;
  }

  &--text {
    font-size: 0.625rem;
    color: #ffffff;
    margin: 0 0.25rem 0 0.5rem;
  }

  &--number {
    font-size: 0.813rem;
    color: #ffffff;
    background-color: #06534d;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: space-around;
    border-radius: 50%;
    align-items: center;
    margin-top: 0.1rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
}
