.PertaminaLegend {
  
  &.fixed {
    position: absolute;
    z-index: 1200;
    bottom: 1rem;
    right: 1rem;
    transition: 0.25s ease-in-out;
  }

  &.wrapper {
    background-color: #fff;
    max-height: 14rem;
    overflow-y: auto;
    min-width: 10rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: rgba(97, 97, 97, 1);

    .title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .icon {
          width: 24px;
          height: auto;
        }

        .name {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
    }
  }
}