$base-color: #007d74;

.bvt-advanced {
  font-size: 1rem;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.3);
  z-index: 4024;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  &__card {
    background: #fff;
    height: auto;
    width: 22rem;
    box-shadow: rgb(99 99 99 / 20%) 0px 0.125rem 0.5rem 0px;
    border-radius: 0.5rem;
    font-weight: 600;

    &__header {
      border-radius: 0.5rem 0.5rem 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fafcfd;
      padding: 0.5rem 1rem;

      &__button {
        font-weight: 500;
        border: 1px solid #b1b7c1;
        background: #fff;
        color: #616477;
        border-radius: 0.25rem;
        padding: 0.25rem;

        &:hover {
          color: $base-color;
          border: 1px solid $base-color;
        }
      }
      &__title {
        font-size: 1.25rem;
        font-weight: 400;
        color: $base-color;
      }
    }
    &__footer {
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      padding-bottom: 1rem;

      &__button {
        font-weight: 500;
        padding: 0.25rem 2rem;
        border-radius: 0.25rem;
        color: #b1b7c1;
        background: #f8f8fb;
        transition: all 0.2s;
        cursor: not-allowed;
        border: 1px solid #f8f8fb;

        &__active {
          cursor: pointer;
          font-weight: 500;
          border: 1px solid #b1b7c1;
          padding: 0.25rem 2rem;
          background: #fff;
          border-radius: 0.25rem;
          color: #007e75;

          &:hover {
            color: $base-color;
          }
        }
      }
    }
    &--dropdown-container {
      padding: 1rem 1.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
