.task-analyze {
  &.TaskProcessItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;

    .TaskProcessItem {
      &__process-item {
        margin-right: auto;
        display: flex;
        flex-direction: column;

        &-dynamic-icon {
          width: 2rem;
          height: 2rem;
          margin-right: 0.5rem;

          &.should-spin {
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }
      }

      &__project-type {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: #364961;
      }

      &__project-name {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        color: #657991;
      }

      &__procces-label {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 999999px;
        font-size: 0.75rem;
        background: #6e6e6e;
        color: #fff;
        flex: 0;
        text-align: center;
      }

      &__right-wrapper {
        min-width: 4rem;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__elapsed-time {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: right;
        color: #657991;
      }

      &__button {
        border-radius: 0.25rem;
        background: #fff;
        border: 1px solid #caced5;
        padding: 0.25rem 0.5rem;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: #00a8ff;
        margin-bottom: auto;
        margin-top: 0.25rem;
      }
    }
  }
}