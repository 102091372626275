.feature-pertamina {
  &.PertaminaDownloadNotification {
    position: absolute;
    top: 11.5rem;
    right: 1rem;
    width: 15.6875rem;
    background: #fff;
    z-index: 999999;
    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid #e1e1e1;
      background: #fff;
      .header {
        display: flex;
        justify-content: space-between;
        span {
          color: #616161;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
        button {
          background: transparent;
        }
      }
      .show-hide {
        position: absolute;
        top: -0.8rem;
        left: 0.2rem;
        z-index: -1;
        max-height: 4rem;
        transform: translateX(0rem);
        transition: 0.25s ease-in-out;

        &:hover {
          transform: translateX(-0.5rem);
          transition: 0.25s ease-in-out;
        }

        .show-hide-button {
          padding-top: 1rem;
          padding-bottom: 0.25rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          border-radius: 0.25rem;
          background: #fff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
          transform: rotate(90deg) translate(1.25rem, 1.5rem);
          display: flex;
          align-items: center;

          span {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: center;
            color: #657991;
            margin-left: 0.5rem;
          }

          &:hover {
            color: #007e75;

            span {
              color: #007e75;

              svg {
                color: #007e75;
              }
            }
          }
        }
      }
      .content {
        .item {
          display: flex;
          justify-content: space-between;
          .name {
            color: #101010;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 70%;
          }
          .persen {
            color: #888;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
          }
        }
        .size {
          display: block;
          color: #888;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
        .information {
          color: #101010;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
      }
    }
  }
}

@keyframes progress-loading {
  100% {
    background-position: -200% 0;
  }
}
