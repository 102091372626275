.feature-genset-parameter {
  &.ParameterInput {
    &--container {
      padding: 0.25rem 1rem;
      display: flex;
      .error-message {
        color: red;
        font-size: 0.875rem;
      }
    }
    &--input_group {
      display: flex;
      min-width: 20rem;
      flex-direction: row;
      align-items: center;
      border-radius: 0.25rem;
      background: #fff;
      border: 1px solid #caced5;
      overflow: hidden;
      padding: 0.25rem;

      &:focus-within {
        border-color: #007e75;
      }
      input {
        border: none;
        font-family: sans-serif, 'Source Sans Pro';
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #007e75;
        padding: 0.2rem 0.5rem;
        background: transparent;
        width: 100%;
        &::placeholder {
          color: #caced5;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
      .close-icon {
        margin-right: 0.25rem;
        .imageBox {
          padding: 0.25rem;
          border-radius: 4px;
          background: #fff;
          border: 1px solid #caced5;
          cursor: pointer;
        }
      }
    }
    &--title {
      font-size: 1rem;
      font-weight: 300;
    }
  }
}
