$base-color: #007d74;
.bvt-mapsearch {
    top: 8rem;
    z-index: 1099;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__card {
        display: flex;
        background: rgba(255, 255, 255, .45);
        padding: 0.35rem 0.35rem 0.35rem 0.35rem;
        border-radius: 0.5rem;
        margin: 1rem 0.5rem 0 0;
        box-shadow: rgb(99 99 99 / 20%) 0px 0.125rem 0.5rem 0px;
        z-index: 2000;
        .space {
            margin: 0 0.2rem;
        }
        .bvt-mapsearch-tag {
            background: #f8f8fb;
            padding: 0.15rem 1rem;
            border-radius: 0.4rem;
            border: 1px solid #caced5;
            color: #616477;
            &__button {
                font-weight: 500;
                border: 1px solid #b1b7c1;
                padding: 0 0.25rem;
                background: #fff;
                color: #b1b7c1;
                border-radius: 0.4rem;
                &:hover {
                    color: $base-color;
                }
                &:disabled{
                    cursor: not-allowed;
                    opacity: 0.5;
                }
                .close {
                    background: #fff;
                    width: 0.9rem;
                }
            }
        }
        
    }
}

.bvt-map__toolbar-group {
    display: flex;
    flex-direction: column;
    width: min-content;
}
