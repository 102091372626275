.AsmanCardLandValueComponent {
  &__des {
    color: #696969;
    font-style: normal;
    font-weight: 600;
    margin: 1rem 0;
    display: flex;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
  }

  &__previous, &__current {
    padding: 1rem;
    color: #ffffff;
    border-radius: 4px;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__previous {
    background-color: #2077CD;
  }

  &__current {
    background-color: #F97B1F;
  }

  &__icon {
    width: 100%;
  }

  &__value {
    font-size: 30px;
    margin-bottom: 1rem;

    span {
      font-size: 18px;
    }
  }


}
