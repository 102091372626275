.feature-auth {
  &.LoginForm {
    .ant-alert.ant-alert-error {
      background: #fee7e7;
      border: 1px solid #f44e4a;
      margin-bottom: 1rem;
      .ant-alert-message {
        color: #f44e4a;
        border-radius: 0.25rem;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
    }
    &__litya {
      font-family: sans-serif, 'Source Sans Pro';
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: left;
      color: #000;
      margin-bottom: 0.5rem;
    }
    &__remember-forgot-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2rem;
      .ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #007e75;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #007e75 !important;
      }
      .ant-checkbox-checked::after {
        border-color: #007e75;
      }
      .ant-checkbox-wrapper {
        span {
          font-size: 1rem;
          line-height: 0.75rem;
          text-align: left;
          transition: 0.25s ease;
        }
        &.ant-checkbox-wrapper-checked {
          span {
            color: #007e75;
          }
        }
        &:not(.ant-checkbox-wrapper-checked) {
          span {
            color: #caced5;
            &:hover {
              transition: 0.25s ease;
              color: #000;
            }
          }
        }
      }
    }
    &__remember-forgot-button {
      margin-left: auto;
      cursor: pointer;
      font-size: 0.875rem;
      &:hover {
        text-decoration: underline;
      }
    }
    &__bottom-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__bottom-company {
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #007e75;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &__submit-button {
      padding: 0.2rem 1.5rem;
      border-radius: 0.25rem;
      background: #007e75;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #fff;
      margin-left: auto;
      display: flex;
      flex: 0;
      transition: 0.25s ease-in-out;
      align-items: center;

      svg {
        animation-name: button-svg;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      &--not-valid {
        color: #caced5;
        background: #f8f8fb;
        cursor: not-allowed;
      }
      &--loading {
        background: #007e75;
        cursor: not-allowed;
      }
    }
  }
}
@keyframes button-svg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
