.SwitchInput {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__track {
    position: relative;
    height: 16px;
    width: 32px;
    border-radius: 100px;
    cursor: pointer;
    &.disabled{
      cursor: not-allowed;
    }
  }
  &__ball {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 100px;
    top: 2px;
    background: #fff;
    transition: 0.2s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  }
  &__text {
    &--start {
      font-size: 16px;
      margin-right: 12px;
      color: #4d4f5c;
      font-family: 'Source Sans Pro', sans-serif;
    }

    &--end {
      font-size: 16px;
      margin-left: 12px;
      color: #4d4f5c;
      font-family: 'Source Sans Pro', sans-serif;
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .arrow {
      text-align: center;
      width: 200px;
    }
    
    .arrow---down {
      position: absolute;
    }
    
    .arrow---down:after,
    .arrow---down:before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border: solid transparent;
      pointer-events: none;
    }
    
    .arrow---down:after {
      border-top-color: #000;
      border-width: 10px;
      left: 13%;
      margin-left: -10px;
      bottom: -50px;
    }
  }

  &__popover-wrapper {
    display: block;
  }
}
