.MainMapLeftSideLayout{
    &__layout{
        position: absolute;
        left: 1rem;
        top: 9rem;
        bottom: 1rem;
        display: flex;
        width: 30rem;
        z-index: 1000;
        flex-direction: column;
    }
}