.main-new {
  &__button-add-module {
    padding: 0.22rem 1rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    background: #007e75;
    border-radius: 0.22rem;
    color: white;
    svg {
      margin-right: 0.22rem;
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 1.25rem 0rem;
    &.divider-setup {
      margin-top: 0.5rem;
    }
  }

  &__recent-button {
    background: $green;
    padding: 0.25rem 1rem;
  }

  &__recent-button-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem 0 0;
  }

  &__recent-button-icon {
    fill: #fff;
  }

  &__recent-button-text {
    color: white;
  }

  .setup-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 1.625rem;
    line-height: 1.875rem;
    text-align: left;
    color: #c9d8dc;
  }

  &__popup-desc {
    &-1 {
      width: 17rem;
      margin-bottom: 1rem;
    }

    &-2 {
      font-weight: bold;
    }
  }
}
