.feature-mainmap{
    &.MainMapMapPickerBaseMapListItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.75rem 0.5rem;
        background-color: #f8f8fb;
        border: 1px solid #f8f8fb;
        transition: 0.25s ease-in-out;
        border-radius: 0.25rem;
        cursor: pointer;
        &--active,&:hover{
            border: 1px solid #007e75;
        }
        margin-bottom: 0.5rem;
    }
    .MainMapMapPickerBaseMapListItem{
        &__left-side{
            display: flex;
            align-items: center;
            flex: 50%;
        }
        &__checker{
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid #85868f;
            border-radius: 99999rem;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .checker-item{
                width: 1rem;
                height: 1rem;
                border-radius: 9999rem;
                background-color: transparent;
            }
            &--active{
                .checker-item{
                    background-color: #007e75;
                }
            }
        }
        &__title{
            font-size: 0.875rem;
            color: #6C757D;
            font-family: 'Source Sans Pro',sans-serif;
        }
        &__right-side{
            display: flex;
            align-items: center;
            flex: 50%;
        }
        &__image{
            height:3.125rem;
            width: 13rem;
            max-width: 100%;
            border-radius: 0.25rem;
        }
    }
}