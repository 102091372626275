.SingleSelectedCard {
  &__wrapper {
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d7dae2;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14);
    &:hover:not(.SingleSelectedCard__wrapper--active):not(.SingleSelectedCard__wrapper--disabled) {
      transition: 0.25s ease-in-out;
      background: #f0f0f3;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }
    &--active {
      border: 1px solid #007e75;
    }
    &--disabled {
      cursor: not-allowed;
      box-shadow: none;
    }
  }
  &__image-container {
    margin-bottom: 8px;
    border: 1px solid #caced5;
    border-radius: 4px;
    overflow: hidden;
  }
  &__image {
    width: 100%;
    height: auto;
    max-height: 146px;
    object-fit: fill;
  }
  &__bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__checkbox {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: #f2f2f2;
    border: 1px solid #caced5;
    position: relative;
  }
  &__checkbox-item {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #007e75;
    left: 3px;
    top: 3px;
    border-radius: 16px;
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    color: #616477;
    display: flex;
    align-items: center;
    &--disabled {
      color: #caced5;
    }
  }
}
