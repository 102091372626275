.AccountNoteInfo {
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #495057;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  background: #f8f8fb;
  border-radius: 4px;
  height: fit-content;
  &.bordered {
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  .green-link-text {
    color: #07827c;
    text-decoration: underline;
  }
}
