.AccountContainer {
  height: 100%;
  &--content {
    padding: 0.75rem 0;
    height: 100%;
  }
  &--tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 0.75rem;
    background: #f8f8fb;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
  }
}
