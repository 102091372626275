.AsmanDashboardSidebarComponent {
  &__wrapper {
    min-width: 300px;
    max-width: 300px;
    background: #ffffff;
    color: #07827C;
    transition: all 0.3s;

    &.active {
      margin-left: -245px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    padding: 0.75rem;

    .ant-btn-primary {
      color: #07827c;
      background-color: transparent;
      border-color: #07827c;
      box-shadow: none;
      border-radius: 8px;

      &:hover {
        color: #1e8f85;
        border-color: #1e8f85;
      }

      &:active {
        color: #1e8f85;
        border-color: #1e8f85;
      }

      &:focus {
        color: #1e8f85;
        border-color: #1e8f85;
      }
    }
  }

  &__list {
    margin: 0;
    list-style: none;
    padding: 0.75rem;
  }

  &__list-item {
    padding: 0.75rem;
    color: #696969;
    cursor: pointer;

    &--active {
      background-color: #07827c1a;
      color: #07827C;
      border-radius: 4px;
    }

    &--title {
      margin-left: 0.5rem;
    }
  }

  &__list-item-icon {
    &--collapsed {
      float: right;
    }
  }

  &__list-title {
    font-family: 'Source sans serif', sans-serif;
    font-weight: 600;
  }

}
