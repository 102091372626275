.DatexStepPOICategoryCollection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;
  gap: 0.75rem;
  isolation: isolate;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  z-index: 1;

  &__footer {
    background: #D8FFFD;
    box-shadow: 0px 4px 10px rgba(122, 136, 147, 0.2);
    position: fixed;
    bottom: 50px;
    padding: 12px 8px;
    right: 0;
    transform: translateX(0%);
    width: 23.25rem;
    
    &--text {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #0D8C84;
    }
  }
}
