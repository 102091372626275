.menu-bar__user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.55rem;
  cursor: pointer;

  &:hover {
    background-color: #f8f8fb;
  }

  &.selected {
    background-color: #eeeef3;
    color: #007e75;

    .menu-bar__user__left::after {
      background-color: #007e75;
      border: 1px solid #007e75;
      border-radius: 0.125rem;
      position: absolute;
      content: '';
      width: 100%;
      top: 1.75rem;
      -webkit-animation: scale-up-hor-center 0.25s;
      animation: scale-up-hor-center 0.25s;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }
  
  &__right {
    display: flex;
    align-items: center;
    position: relative;
  }

  &--greeting {
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: #888888;
  }

  &--name {
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    color: #888888;
  }

  &--arrow {
    fill: #4d4f5c;
    margin: 0 1rem;

    &.active {
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-animation: rotate-0 0.25s linear;
      animation: rotate-0 0.25s linear;
    }

    &.selected {
      fill: #007e75;
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-animation: rotate-180 0.25s linear;
      animation: rotate-180 0.25s linear;
    }
  }

  &--photo {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
  }
}

@-webkit-keyframes rotate-0 {
  from {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotate-0 {
  from {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes rotate-180 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes rotate-180 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.ant-popover {
  z-index: 10000;
  padding: 0;
}
.ant-popover-inner {
  border-radius: 0.5rem;
}
.ant-popover-arrow-content {
  top: 0;
  opacity: 0;
}

.ant-popover-inner-content {
  padding: 0.5rem 0;

  .menu-bar__user {
    &--info-list {
      width: 9rem;
    }

    &--li {
      display: flex;
      align-items: center;
      height: 1.875rem;
      cursor: pointer;

      &:hover {
        background-color: #f8f8fb;

        .menu-bar__user--li-icon {
          fill: #007e75;
        }
      }

      p {
        margin: 0;
      }
    }

    &--li-icon {
      fill: #4d4f5c;
      margin: 0 0.75rem;
    }

    &__logout {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0 0 0;

      button {
        border: 1px solid #caced5;
        background-color: #ffffff;
        padding: 0.25rem 1rem;

        &:hover {
          background-color: #ffefef;
          border: 1px solid #ff171740;

          & > p {
            color: #ff1717;
          }

          .menu-bar__user__logout--icon {
            fill: #ff1717;
          }
        }
      }

      p {
        margin: 0;
        color: #364961;
      }

      &--icon {
        margin: 0 0 0 0.5rem;
        fill: #364961;
      }
    }
  }
}
