.map-container {
  width: 100%;
  height: 100vh;
  .leaflet-control-scale {
    opacity: 0;
    margin: 0;
    padding: 0 0.25rem 0.2rem 0.25rem;
    width: 12.5rem;
    background: rgb(225 225 225 / 90%);
    .leaflet-control-scale-line {
      border: 1px solid #4d4f5c;
      border-top: none;
      border-left: none;
      border-right: none;
      font-size: 0.8rem;
      line-height: 1.3;
      white-space: nowrap;
      overflow: hidden;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: none;
      padding: 0.03rem 0 0 0;
      &:last-child {
        display: none;
      }
    }
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0.25rem;
  }
  .leaflet-popup-content {
    margin: 0.313rem 0.75rem 0.188rem;
    text-align: center;
    font-size: 0.75rem;
  }
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .leaflet-popup-tip {
    width: 14px;
    height: 14px;
  }
}
.bvt-map {
  &__attribution {
    display: flex;
    z-index: 1001;
    position: fixed;
    bottom: 0;
    right: 0;
    background: rgb(225 225 225 / 90%);
    &__text {
      padding: 0.1rem 0.5rem;
      font-size: 0.8rem;
    }
    &__scale {
      padding: 0.1rem 0.5rem 0.1rem 0;
      .container-scale {
        border: 1px solid #4d4f5c;
        border-top: none;
        border-left: none;
        border-right: none;
        font-size: 0.8rem;
        line-height: 1.3;
        white-space: nowrap;
        overflow: hidden;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: none;
        padding: 0.03rem 0 0 0;
        transition: width 1s;
      }
    }
  }
}

.leaflet-popup-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
