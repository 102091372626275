.feature-auth {
  &.ForgotPasswordForm {
    &__lottie {
      width: 16rem;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
    }
    &__title {
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.25rem;
      text-align: left;
      color: #000;
      margin-bottom: 0.5rem;
    }
    &__title-desc {
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: left;
      color: #000;
      margin-bottom: 1rem;
    }
    &__helper-text {
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: left;
      color: #bfbfbf;
      max-width: 18rem;
      a {
        text-decoration: underline;
        &:hover {
          color: #bfbfbf;
        }
      }
      margin-bottom: 3.25rem;
    }
    &__button-wrapper {
      display: flex;
      flex-direction: row;
    }
    &__button {
      padding: 0.2rem 1.5rem;
      border-radius: 0.25rem;
      background: #007e75;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #fff;
      display: flex;
      flex: 0;
      transition: 0.25s ease-in-out;
      align-items: center;
      margin-left: auto;
      &.back-to-login {
        background: #fff;
        border: 1px solid #caced5;
        color: #007e75;
      }
      svg {
        animation-name: button-svg;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      &--not-valid {
        color: #caced5;
        background: #f8f8fb;
        cursor: not-allowed;
      }
      &--loading {
        background: #007e75;
        cursor: not-allowed;
        width: 100%;
        flex: 1;
      }
    }
  }
}
