.AsmanCardAssetDetailsComponent {
  &__wrapper{
    display: flex;
    justify-content: space-between;
    color: #696969;
    border-bottom: 1px solid #BEBEBE;


    &-name{
      display: flex;
      flex-direction: column;
      align-items: start;
      color: #696969;
      font-weight: 600;
      font-size: 16px;

      &__name{
        margin: 1rem 0;
      }
    }

    &-icon{
      display: flex;
      flex-direction: column;
      align-items: start;
      color: #696969;
      font-weight: 600;

      &__icon{
        margin: 1rem 0;
      }
    }
  }

  &__list-item{
    display: flex;
    border-bottom: 1px solid #BEBEBE;
    padding: 0.5rem 0;
    font-weight: 600;
    align-items: center;
    &--key {
      flex: 0 0 30%;
      max-width: 30%;
      color: #696969;
      font-size: 16px;
    }

    &--value {
      flex: 0 0 70%;
      max-width: 70%;
      color: #474747;
      font-size: 16px;
      flex-direction: row;
      display: flex;

      &-label{
        padding: 0.125rem 0.5rem;
        font-weight: normal;
        color: #6C757D;
        background-color: #F5F5F5;
        border: 1px solid #CED4DA;
        margin-right: 0.75rem;
        border-radius: 4px;
      }
    }
  }

  &__list-item:last-child{
    border-bottom: none;
  }
}
