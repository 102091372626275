.AdvanceSearchGeojsonComponent{
	&__popup-wrapper{
		&.leaflet-popup{
			min-width: 16rem;
			.leaflet-popup-content-wrapper{
				border-radius: 0.5rem;
				// padding-top: 1rem;
				.leaflet-popup-content{
					margin: 0px;
					padding: .5rem .5rem;
					display: flex;
					flex-direction: column;
					align-items: start;
				}
			}
			.leaflet-popup-close-button{
				width: 1rem;
				height: 1rem;
				right: 0.5rem;
				top: 0.5rem;
				border: 1px solid #CED4DA;
				border-radius: 0.25rem;
				display: flex;
				align-items: center;
				justify-content: center;
				&:hover{
					background-color: #FF3939;
					border-color: #FF3939;
					color: #fff;
				}
				span{
					font-weight: bold;
				}
			}
		}
	}
}
.AdvanceSearchGeojsonPopupInternalComponent {
	&__wrap {
		display: flex;
		flex-direction: row;
        align-items: center;
	}
	&__icon {
        color: #ff7a00;
        margin-right: 0.625rem;
        margin-top: 0.25rem;
	}
    &__text-wrap{
        display: flex;
        flex-direction: column;
        font-family: 'Source Sans Pro',sans-serif;
        color: #6c757d;
    }
	&__title {
		font-style: normal;
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.25rem;
		color: inherit;
	}
}
