.popup-form {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000010;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  &__content {
    background-color: #fff;
    min-width: 18rem;
    border-radius: 4px;
    min-height: 9rem;
  }

  &__header {
    background-color: #f2f4f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #007e75;
    font-size: 1rem;
    padding: 0.5rem 0.25rem 0.5rem 1.25rem;
    display: flex;
    justify-content: space-between;

    button.closeButton {
      background-color: #ffffff;
      width: 1.5rem;
      height: 1.5rem;
      border: 1.875px solid #f8f8f8;
    }
  }

  &__form {
    width: 34.375rem;
    padding: 0.875rem 1rem 0rem;
    margin: 0rem;

    .inputContainer {
      height: 2rem;
    }
    input.input {
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #007e75;
    }

    button.removeButton {
      background-color: #ffffff;
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f44e4a;
      border-radius: 4px;
      background: #fee7e7;
      color: #f44e4a;
      font-size: 00.875rem;
      padding: 0.25rem;

      button.errorCloseButton {
        border: 1px solid #f44e4a;
        padding: 0.25rem;
        background-color: #ffffff;

        img {
          filter: invert(33%) sepia(91%) saturate(2583%) hue-rotate(341deg)
            brightness(115%) contrast(91%);
        }
      }
    }
  }

  &__footer {
    display: flex;
    padding: 0.875rem 1rem 1.25rem;
    justify-content: space-around;

    &__button {
      &--left {
        width: 7.25rem;
        margin: 0rem 0.5rem 0rem 2rem;
        color: #007e75;
        background-color: #ffffff;
        border: 1px solid #caced5;
      }

      &--right {
        width: 7.25rem;
        margin: 0rem 2rem 0rem 0.5rem;
        border: none;
      }
    }
  }
}
