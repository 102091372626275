.SharedCardMobileData {
  .shared.BaseCard.CardMobileData {
    border-bottom: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .BaseCard__content--with-padding {
      padding-bottom: 0;
    }
  }

  .shared.BaseCard:not(.CardMobileData) {
    background: #fff;
    display: block;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none !important;
    box-shadow: none;
    padding: 0 0.5rem 1rem 0.5rem;
  }

  &-wrapper {
    padding: 0;
  }

  &-title {
    color: #6C757D;
    font-size: 14px;
    font-weight: 600;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
    }

    &-btn {
      color: #CACED5;
      font-size: 20px;
    }
  }

  &-summary {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-card {
      padding: 0.5rem;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      overflow-x: hidden;

     .text {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-wrap: nowrap;
     }
    }
  }

  &-chart {
    &-wrapper {
      border-bottom: 1px solid #CED4DA;
    }
    &-data {
      padding-left: 0.5rem;
    }
    &-title {
      font-size: 0.875rem;
      font-weight: 600;
      color: #616477;
      padding-left: 12px;
      padding-bottom: 12px;
    }
  }
}