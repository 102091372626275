.SiproPOI {
  &__popup {
    width: 300px;
    padding: 1rem;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: .25rem;
      .icon {
        width: auto;
        height: auto;
      }
      .wrapper-title {
        .title {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
        .subtitle {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #A0A6A9;
        }
      }
    }
    .address {
      margin : 0 !important;
    }
  }
}