.card {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 20px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &__header {
    background-color: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &--fill {
      background-color: #fafcfd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 0.375rem 1rem;
    }

    &--title {
      font-weight: 700;
      color: #616477;
    }
  }

  &__content {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
