.button {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  // Size
  &__small {
    height: 1.25rem;
    width: 4rem;
  }

  &__medium {
    padding: 0.25rem 0.875rem;
  }

  &__large {
    height: 1.75rem;
    width: 6rem;
  }

  // Variant
  &__contained {
    background-color: $green;

    &--hover {
      background-color: $green;
      color: $white;

      &:hover {
        box-shadow: 0 4px 14px -5px $green;
      }
    }
  }

  &__white {
    background-color: $white;
    border: 0.063rem solid #caced5;
    &--hover {
      background-color: $green;
      color: $white;
      &:hover {
        box-shadow: 0 4px 14px -5px $green;
      }
    }
  }

  &__outlined {
    background-color: $white;
    border: 1px solid $grey;
    color: $green;

    &:hover {
      box-shadow: 0 4px 14px -5px $grey;
    }
  }

  &__disabled {
    background-color: $grey-light;
    border: 1px solid $grey-light;
    cursor: default;

    &:hover {
      background-color: $grey-light;
      border: 1px solid $grey-light;
    }
  }
}
