.BaseSearchCardDatex__search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0.5rem;
  gap: 05rem;
  isolation: isolate;
  width: 100%;
  background: #ebeff5;

  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6c757d;
  }

  .ant-input-group-wrapper {
    background: #ffffff;
    opacity: 0.6;
    border-radius: 4px;
    .ant-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ant-input-group-addon {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fafafa;
      border: none;
    }
  }
  .icon-addonAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }
}