.menu-bar__notification {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0 0.55rem;

  &:hover {
    background-color: #f8f8fb;
  }

  &.selected {
    background-color: #eeeef3;

    &::after {
      background-color: #007e75;
      border: 1px solid #007e75;
      border-radius: 0.125rem;
      position: absolute;
      content: '';
      width: 40%;
      bottom: 0.25rem;
      -webkit-animation: scale-up-hor-center 0.25s;
      animation: scale-up-hor-center 0.25s;
    }

    & > svg {
      fill: #007e75;
    }
  }

  svg {
    fill: #4d4f5c;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      border: 1px solid #caced5;
      color: #007e75;
      background-color: #ffffff;
      padding: 0.25rem 1rem;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }
    }

    p {
      margin: 0;
    }
  }

  &__detail {
    p {
      margin: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--right {
        text-align: right;
      }
    }

    &__body {
      border: 1px solid #c9d8dc;
      background-color: #f8f8fb;
      border-radius: 0.5rem;
      margin: 0.5rem 0;
      padding: 0.5rem;
      width: 30rem;

      &.date {
        display: flex;
        justify-content: space-between;
      }
    }

    .popup__footer {
      padding: 0;
      margin: 0 0 1rem 0;
    }
  }
}

.ant-popover-inner-content {
  .menu-bar__notification__table {
    margin: 0 0 0.75rem 0;

    tbody tr {
      border: 1px solid #f2f2f2;
      border-left: none;
      border-right: none;
      display: flex;
      cursor: pointer;

      &:hover {
        background-color: #f8f8fb;
      }

      td {
        display: flex;
        align-items: center;
        padding: 0.25rem;
      }

      p {
        margin: 0;
      }
    }
  }
}
