.feature-auth {
  &.AuthInput {
    &__input-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.625rem;
      .error-message {
        color: red;
        font-size: 0.875rem;
      }
    }
    &__input-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      .icon {
        width: 1rem;
        height: 0.875rem;
        margin-right: 0.5rem;
      }
      .title {
        font-family: sans-serif, 'Source Sans Pro';
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: #007e75;
      }
    }
    &__input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 0.25rem;
      background: #fff;
      border: 1px solid #caced5;
      overflow: hidden;
      padding: 0.25rem;
      input {
        flex: 1;
        border: none;
        font-family: sans-serif, 'Source Sans Pro';
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #007e75;
        padding: 0px 0.5rem;
        background: transparent;
        &::placeholder {
          color: #caced5;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
      .close-icon {
        margin-right: 0.25rem;
        .imageBox {
          padding: 0.3rem;
          border-radius: 4px;
          background: #fff;
          border: 1px solid #caced5;
          cursor: pointer;
        }
      }
      .show-hide {
        font-weight: normal;
        font-size: 0.875rem;
        text-align: left;
        color: #caced5;
        padding: 0rem 0.5rem;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #caced5;
        flex: 0;
        cursor: pointer;
      }
      &:hover,
      &:focus-within {
        background-color: #f8f8fb;
      }
      &:focus-within,
      &--filled {
        border-color: #007e75;
        input,
        .show-hide {
          color: #007e75;
        }
      }
    }
  }
}
