@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexLeft($direction: row) {
  display: flex;
  justify-content: center;
  flex-direction: $direction;
}

@mixin selectContent {
  > .content {
    border-radius: rem(4);
    border: rem(1) solid #caced5;
    height: rem(30);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8fb;
    padding: 0 rem(5) 0 rem(10);
    cursor: pointer;

    > span {
      font-size: rem(13);
      color: #caced5;
    }

    &.idle {
      background-color: #ffffff;

      > span {
        color: #657991;
      }
    }

    svg {
      transition: transform 0.3s;
    }

    &.active {
      border: rem(1) solid #007d74;
      background-color: #ffffff;

      svg {
        transform: rotate(180deg);
      }

      > span {
        color: #657991;
      }
    }

    &.disable {
      cursor: not-allowed !important;
    }

    &:hover {
      background-color: #f8f8fb;
    }

    svg path {
      fill: #657991;
    }
  }
}

@mixin selectModal {
  > .modal {
    position: absolute;
    top: rem(30);
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    border: rem(1) solid #caced5;
    border-radius: rem(4);
    cursor: pointer;
    z-index: 1;

    > span {
      font-size: rem(13);
      height: rem(30);
      display: flex;
      align-items: center;
      padding-left: rem(10);

      &:hover {
        background-color: #f8f8fb;
        color: #007d74;
      }

      &.active {
        font-weight: bold;
        color: #007d74;
        background-color: #f8f8fb;
      }
    }
  }
}

@mixin hover($color: #caced5) {
  &:hover {
    box-shadow: 0 rem(4) rem(14) rem(-5) $color;
  }
}
