.DatexStepPOIAreaSelection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;
  gap: 0.75rem;
  isolation: isolate;
  width: 100%;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  z-index: 1;

  .DatexStepPOIAreaSelection__search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    isolation: isolate;
    width: 100%;
    border-radius: 6px;
    .ant-select {
      width: 100%;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 4px;
      border-color: #ced4da;
      background-color: #ffffff;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #07827c;
    }
  }
}
