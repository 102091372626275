$open-legend-width: 14.375rem;
$close-legend-width: 6rem;

@keyframes showLegend {
  0% {
    width: $close-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    width: $open-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes hideLegend {
  0% {
    width: $open-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    width: $close-legend-width;
    --webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes showContent {
  0% {
    width: $close-legend-width;
    min-height: 0rem;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    width: $open-legend-width;
    min-height: 2.75rem;
    -webkit-transform: 100% 100%;
    transform-origin: 100% 100%;
    visibility: visible;
  }
}

@keyframes hideContent {
  0% {
    width: $open-legend-width;
    min-height: 2.75rem;
    -webkit-transform: 100% 100%;
    transform-origin: 100% 100%;
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    width: $close-legend-width;
    height: 0rem;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    visibility: hidden;
  }
}

@keyframes rotateIconShow {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotateIconHide {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.bvt-map__legend {
  position: fixed;
  z-index: 1200;
  bottom: 3rem;
  right: 1rem;
  cursor: default;
  transition: 0.25s ease-in-out;

  &.hidden {
    display: none;
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    border: 1px solid #caced5;
    border-radius: 0.25rem;
    background-color: #ffffff80;
    -webkit-animation: hideContent 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: hideContent 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    max-height: 285px;
    // visibility: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &.show {
      -webkit-animation: showContent 0.25s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: showContent 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      // visibility: visible;
    }

    &__ul {
      margin: 0 0 0.35rem 0;
      // padding: 0.25rem; // move to child
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .legend-collapse {
        width: 100%;
        background-color: transparent;
        border-radius: 6px;

        .legend-collapse-header {
          border: none;
          .ant-collapse-header {
            padding: 6px 12px;
            flex-direction: row-reverse;
            background-color: #FFFFFF;

            .ant-collapse-header-text {
              color: #00B4E8;
            }
          }
          .ant-collapse-content {
            background-color: transparent;
            .ant-collapse-content-box {
              padding: 0 6px 6px 6px;

              .legend-collapse-content {
                .legend-collapse-sub-title {
                  background-color: #fff;
                  padding: 4px 12px;
                  margin-bottom: 6px;
                  margin-left: -6px;
                  margin-right: -6px;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  color: #495057;
                }
                .legend-collapse-content-item {
                  margin-top: 6px;
                }
              }
            }

          }
        }
      }
    }

    &__li {
      border: 1px solid #caced5;
      border-radius: 0.25rem;
      background-color: #ffffff96;
      display: flex;
      align-items: center;
      margin: 0rem 0rem 0.25rem 0rem;
      width: 100%;
      padding: 0.25rem 0;

      &--icon {
        margin: 0.25rem;
      }

      &--desc {
        color: #616477;
        font-weight: 500;
        line-height: 1rem;
        padding-right: 0.5rem;
      }

      &.center {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 0.5rem;
    }
  }

  &__action {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__button {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 1.875rem;
    animation: hideLegend 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    border-radius: 0.25rem;
    border: 1px solid #d7dae2;

    &.show {
      -webkit-animation: showLegend 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: showLegend 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    &--arrow {
      margin: 0 0 0 0.5rem;
      fill: #007e75;
      -webkit-animation: rotateIconHide 0.12s ease-in-out both;
      animation: rotateIconHide 0.12s ease-in-out both;

      &.show {
        -webkit-animation: rotateIconShow 0.12s ease-in-out both;
        animation: rotateIconShow 0.12s ease-in-out both;
      }
    }

    &__container {
      display: flex;
      align-items: center;
    }
  }
}
