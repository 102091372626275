.AdvanceSearchLabelListComponent {
	&__wrapper {
    cursor: default;
		display: flex;
		border-radius: 0.25rem;
		padding: 0.5rem;
    flex-grow: 0;
		.ant-row {
			flex-grow: 1;
		}
	}
  &__label{
    cursor: default;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  }
  &__label-text{
    font-size: 0.875rem;
    cursor: default;
    padding: 0.325rem 0.5rem;
    background-color: #ebebeb;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  &__label-icon{
    cursor: pointer;
    padding: 0.625rem 0.5rem;
    background-color: #fff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
