.bvt-skeleton {
  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    width: 4rem;
    height: 4rem;
    position: relative;
    overflow: hidden;
    background-color: #e2eef1;
    line-height: 12rem;
    &__svg {
      line-height: 3rem;
      max-width: 12rem;
      max-height: 12rem;
      min-width: 1rem;
      min-height: 1rem;
      &__path {
        fill: #fff;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
  &__square {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #e2eef1;
    width: 100%;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
  &__circle {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #e2eef1;
    border-radius: 50%;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
