.txt {
    // Font Color
    &-green { color : $green }
    &-grey { color : $grey }
    &-grey-light { color : $grey-light }
    &-red { color : $red }
    &-red-light { color : $red-light }
    &-black { color : $black }
    &-white { color : $white }

    // Font Weight
    &-light { font-weight: $light; }
    &-regular { font-weight: $regular; }
    &-semi-bold { font-weight: $semi-bold; }
    &-bold { font-weight: $bold; }
    &-dark { font-weight: $dark; }

    // Font Position
    &-left { text-align: left; }
    &-center { text-align: center; }
    &-right { text-align: right; }

    // Font Transform
    &-lowercase { text-transform: lowercase; }
    &-uppercase { text-transform: uppercase; }
    &-capitalize { text-transform: capitalize; }
}
