.feature-datex-telco {
  width: 100%;
  .CardLayerResult {
    &__container {
      background: #fff;
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      width: 100%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    &__header-info {
      display: flex;
      padding-bottom: 0.25rem;
      align-items: center;
      gap: 0.25rem;
      border-bottom: 1px solid #bebebe;
      &--text {
        color: #07827c;
        font-family: sans-serif, 'Source Sans Pro';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
      }
    }
    &__periode {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid #bebebe;
      &--text {
        padding: 0 0.75rem;
        color: #6c757d;
        font-family: sans-serif, 'Source Sans Pro';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
      }
      &--value {
        padding: 0 0.75rem 0.5rem;
        color: #495057;
        font-family: sans-serif, 'Source Sans Pro';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem; /* 120% */
      }
    }
    &__area-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      box-shadow: -3px 3px 3px 1px rgba(0, 0, 0, 0.07);
      border-radius: 0.5rem;
      &__header {
        display: flex;
        padding: 0.5rem 0.75rem;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 0.375rem 0.375rem 0rem 0rem;
        background: #f8f8fb;
        &--text {
          color: #07827c;
          font-family: sans-serif, 'Source Sans Pro';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem; /* 142.857% */
        }
      }
      &__content {
        display: flex;
        width: 18.5rem;
        padding: 0.5rem 0.75rem;
        align-items: center;
        gap: 0.5rem;
        &--text {
          color: #6c757d;
          font-family: sans-serif, 'Source Sans Pro';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem; /* 142.857% */
        }
        &--icon {
          color: #07827c;
          width: 0.75rem;
        }
      }
    }
    &__mobile-data {
      .shared.BaseCard .BaseCard__header {
        display: none;
      }
      .shared.BaseCard .BaseCard__content--with-padding {
        padding: 0 !important;
      }
      .SharedCardMobileData-summary-card {
        .text {
          font-size: 10px;
        }
      }
    }
  }
}
