.task-analyze {
  &.PopupCardTaskAnalyzeProcessStatus {
    .ant-popover-inner-content {
      padding: 0rem;
    }

    .PopupCardTaskAnalyzeProcessStatus {
      &__card {
        display: flex;
        flex-direction: column;
        min-width: 26rem;
        background: #fafcfd;
        border-radius: 0.5rem;
        overflow: hidden;
        padding-bottom: 0.5rem;
      }

      &__card-header {
        padding: 0.25rem 1rem;
        background: #fafcfd;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__card--close-button {
        display: block;
        display: flex;
        flex-shrink: 1;
        margin-left: auto;
        padding: 0.25rem;
        border-radius: 0.25rem;
        background: #fff;
        border: 1px solid #caced5;
        cursor: pointer;
      }

      &__card--header--text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 2rem;
        text-align: left;
        color: #007e75;
      }

      &__card-body {
        background: #fff;
        border: 1px solid #f2f2f2;
        display: flex;
        width: 100%;
      }

      &__card-list {
        width: 100%;
        max-height: 16rem;
        overflow-y: scroll;
      }
    }
  }
}