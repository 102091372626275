.PasswordStrengthBar {
  &__info-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #a0a6a9;
  }
  &__info-value {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--LabelColor);
  }
  .ant-progress-outer {
    height: 0.25rem;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: var(--ProgressBgColor);
  }
  .ant-progress-bg {
    height: 0.25rem !important;
  }
}
