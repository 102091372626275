.formResetPass {
  width: 20rem;
  height: 30.25rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  &__title {
    font-size: 1.25rem;
    background-color: #fafcfd;
    display: block;
    padding: 0.85rem 0;
    padding-left: 1.25rem;
    color: $color-green;
    font-weight: 400;
  }

  &__img {
    width: 8.6328rem;
    display: block;
    margin: auto;
    margin-top: 1.3rem;
    margin-bottom: 1.05rem;
  }

  &__content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    > span {
      font-size: 1rem;
      font-weight: 400;
    }

    p {
      font-size: 0.65rem;
    }
  }

  &__email {
    display: flex;
    color: $color-green;
    align-items: center;
    margin-top: 0.35rem;

    &--svg {
      width: 0.75rem;
      margin-right: 0.3rem;
    }

    span {
      font-size: 0.85rem;
    }
  }

  &__input {
    position: relative;

    &--value {
      height: 1.8rem;
      margin-top: 0.15rem;
      width: 100%;
      border: 1px solid $color-disabled;
      border-radius: 4px;
      padding: 0.25rem;
      padding-right: 1.75rem;
      font-size: 0.65rem;
      color: $color-green;

      &::placeholder {
        color: $color-disabled;
      }

      &:focus {
        border: 1px solid $color-green;
      }
    }

    &--close {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 53%;
      height: 1.2rem;
      width: 1.2rem;
      border: 1px solid $color-disabled;
      right: 2.25rem;
      padding: 0.15rem;
      border-radius: 0.25rem;
      cursor: pointer;
    }

    &--button {
      font-size: small;
      color: $color-green;
      background-color: transparent;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 53%;
      height: 1.2rem;
      width: 2.25rem;
      border: 1px solid $color-disabled;
      right: -0.75rem;
      border-radius: 0.25rem;
      cursor: pointer;
    }
  }

  &__trouble {
    color: $color-grey;
    margin-top: 0.4rem;
    background-color: $background-color-dark-light;
    border-radius: 0.25rem;

    p {
      font-size: 0.7rem;
      line-height: 1rem;
      padding: 0.5rem;
    }

    span {
      margin-left: 0.15rem;
      font-size: 0.7rem;
      text-decoration: underline;
    }
  }

  &__button {
    border-radius: 0.15rem;
    display: flex;
    justify-content: space-between;
    margin-top: 1.35rem;

    span {
      padding: 0.15rem 0.75rem;
      font-size: 0.65rem;
      min-width: 4.45rem;
    }
  }
}
