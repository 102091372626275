.feature-auth {
  &.LoginContainer {
    &__wrap {
      position: relative;
    }
    &__title {
      font-weight: 600;
      font-size: 1.5rem;
      text-align: left;
      color: #007e75;
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 2;
    }
  }
}
