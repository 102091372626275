.ResetPasswordContainer {
  &__animation {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 25%;
  }

  &__success-text {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 30%;
  }
}
