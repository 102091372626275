.SharedBaseCardTypeOneComponent {
	&__wrapper {
		background: #ffffff;
		border: 1px solid #bebebe;
		border-radius: 4px;
		padding: 8px;
		display: flex;
		flex-direction: column;
	}

	&__top-bar {
		font-family: 'Source Sans Pro',sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.25rem;
		color: #07827c;
        display: flex;
        flex-direction: row;
        align-items: center;
		padding: 0.25rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid #BEBEBE;
        svg{
			margin-right: 0.5rem;
			width: 1rem;
			height: 1rem;
        }
	}
	&__top-title{
		display: flex;
		align-items: center;
		width: 100%;
	}
}
