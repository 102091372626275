.fgt-pwd-pg {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-evenly;

  &__li-logo {
    height: 2.5rem;
  }

  &__version {
    color: $color-disabled;
  }

  &__bvt {
    display: flex;
    flex-direction: column;
    justify-self: start;

    &-logo {
      height: 2rem;
    }

    span {
      font-size: 0.55rem;
    }
  }
}

.GeneralSetupPOI {
  &__header-segment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: rem(11);
  }
  .text-spare {
    font-size: 0.875rem;
  }
  &_action-segment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: rem(11);
    margin: rem(10) 0rem;
  }

  &_upload-download-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__create-button {
    background: $green;
    padding: 0.25rem 1rem;
    div {
      display: flex;
    }
  }

  &__upload-button {
    background: $color-white;
    border: rem(1) solid #c9d8dc;
    padding: 0.25rem 1rem;
    margin: rem(0) rem(10);
  }

  &__download-button {
    background: $color-white;
    border: rem(1) solid #c9d8dc;
    padding: 0.25rem 1rem;

    &__disabled {
      background-color: #f0f0f3;
      padding: 0.25rem 1rem;
      cursor: default;
      pointer-events: none;
    }
  }

  &__create-button-icon {
    fill: $color-white;
    height: rem(1);
    width: rem(1);
  }

  &_upload-button-icon {
    fill: $color-green;
    margin-right: rem(5);
  }

  &__download-button-icon {
    color: $color-green;
    height: 1rem;
    width: 1rem;
    margin-right: rem(5);

    &__disabled {
      color: #caced5;
      height: 1rem;
      width: 1rem;
      margin-right: rem(5);
    }
  }

  &__upload-button-text,
  &__download-button-text {
    color: $color-green;
    padding: 0.25rem 1rem;

    &__disabled {
      color: #caced5;
      padding: 0.25rem 1rem;
    }
  }

  &__create-button-text {
    color: $color-white;
    padding: 0.25rem 1rem;
  }

  .select-option {
    width: rem(206);
    height: rem(30);
    border: rem(1) solid #c9d8dc;

    .ant-select-selector .ant-select-selection-item {
      line-height: 1.5rem;
    }
  }

  .search-box__wrapper {
    width: rem(421) !important;

    .ant-input-affix-wrapper {
      padding: 0 rem(6);
      height: rem(30);
    }
  }

  .bvt-date-picker__picker {
    height: rem(30);
    width: rem(241);
  }

  &__date-range-picker {
    width: rem(470);
  }

  &__poi-categories {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__pop-over {
      background-color: #ffffff;
      border: rem(1) solid #f2f2f2;
      height: rem(30);
      width: rem(30);

      &__content {
        margin: rem(5);
        height: 100%;
        width: rem(211);

        &__tag {
          margin-bottom: 0.15rem;
          margin-top: 0.15rem;
        }
      }

      &__icon {
        height: 0.5rem;
        width: 0.5rem;

        &-down {
          height: 0.5rem;
          width: 0.5rem;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__page-list {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    border: 1px solid #caced5;
    padding: 0.5rem 1rem 1.6rem 1rem;
    border-radius: 0.5rem;

    h5 {
      line-height: 2rem;
    }

    &__size-changer {
      display: flex;

      .select-option {
        margin: 0 0.5rem;
        height: 1.813rem;
        width: 4.188rem;
        background-color: $color-white;

        .ant-select-selector {
          padding: 0 rem(7);
        }
        .ant-select-arrow {
          right: rem(5);
        }
      }
    }

    &__goto-page {
      display: flex;

      :hover {
        border-color: $color-green !important;
      }

      h5 {
        margin: 0 1rem;
      }

      &__input {
        height: 1.813rem;
        width: rem(67);
        margin-right: 0.25rem;
        border-radius: 0.25rem;

        &__icon {
          &.background {
            &_white {
              border-radius: rem(1);
            }
          }
        }
      }
    }

    &__page-item {
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-next,
      .ant-pagination-jump-prev,
      .ant-pagination-item:not(.ant-pagination-item-active) {
        background: $color-white !important;
        height: 1.813rem !important;
        width: 1.813rem !important;

        .anticon svg {
          fill: $color-green;
        }
      }

      .ant-pagination-item-active {
        background-color: $color-green;
        height: 1.813rem !important;
        width: 1.813rem !important;
        border: none;

        a {
          color: $color-white;
        }
      }

      .ant-pagination-disabled {
        .anticon svg {
          fill: $color-grey;
        }
      }
    }

    &__goto-page-button {
      transform: rotate(-90deg);
      fill: $color-green;
    }
  }

  &__arrow-right {
    fill: $color-green;
    transform: rotate(-90deg);
  }

  &__arrow-down {
    fill: $color-green;
  }

  &__arrow-up {
    fill: $color-green;
    transform: rotate(180deg);
  }

  &__content-open-project__size-changer {
    margin: 0 0.5rem;
  }

  .header-segment__title--outlined {
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  &__main-table {
    .ant-table {
      background-color: #f8f8fb;
      margin-right: 0.25rem;
      margin-top: 0.5rem;

      .ant-table-thead {
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: $color-green;
        }
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-green;
      border-color: $color-green;
    }

    &__action-button {
      background-color: $color-white;
      :active {
        fill: $color-green;
      }

      &__icon {
        margin-top: rem(5);
      }
    }

    &__pop-over {
      width: 100%;
      &:hover {
        background-color: #caced5;
      }

      &__button {
        background-color: $color-white;
        margin: rem(5);
        &:hover {
          background-color: #caced5;
          svg {
            fill: $color-green;
          }
        }

        &__icon {
          height: 1rem;
          width: 1rem;
          margin-right: rem(5);
        }
      }
    }
  }

  //Input Field Pagination
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: $color-green !important;
  }
  .ant-input-affix-wrapper {
    padding: rem(5);
  }
  .anticon.ant-input-clear-icon-has-suffix {
    margin: 0 rem(2);
  }

  .ant-input-suffix {
    margin: 0rem;
  }

  .ant-input-suffix > *:not(:last-child) {
    margin-right: rem(6);
  }

  .content-open-project__page-list__size-changer .select-option {
    display: flex;
    align-items: center;

    .ant-select-selector {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .content-open-project__page-list__goto-page__input {
    width: rem(60);

    .anticon svg {
      margin-right: rem(-5);
    }
  }

  .ant-input-affix-wrapper > input.ant-input {
    font-size: rem(14);
    color: $color-green;
  }

  .ant-pagination-item a,
  .ant-pagination-item-active a {
    @include flexCenter;
    height: 100%;
    font-size: rem(14);
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-input-clear-icon-has-suffix {
    display: flex;
    align-items: center;
  }

  &__FormSetup__card-container {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 0.5rem;
    padding: 0.5rem 0.5rem 0rem 0rem;
  }
  .sort-filter-list__popup > .sort-filter-list__popup--filter,
  .sort-filter-list__popup > span {
    display: none;
  }

  .popup__body__text > span {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 200;
    align-items: center;
  }
}

.ParsetPoi {
  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    min-width: 15rem;

    .text-spare {
      font-size: 0.875rem;
    }
  }
  .ant-input-affix-wrapper {
    display: flex;
    align-items: center;
  }

  &__body {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 0.5rem;
    padding: 0.5rem 0.5rem 0 0;
  }

  &__content {
    position: relative;
    border: 1px solid #c9d8dc;
    border-radius: 0.5rem;
    background-color: #f8f8fb;
    width: 100%;
    margin-right: 0.5rem;
    overflow: hidden;
    display: grid;
    grid-template-rows: 8% 84% 8%;
  }

  &__menu {
    position: relative;
    border: 1px solid #c9d8dc;
    border-radius: 0.5rem;
    background-color: #f8f8fb;
    width: 100%;
    margin-right: 0.5rem;
    overflow: hidden;
    display: grid;
    grid-template-rows: 8% 84% 8%;
  }

  &__content_header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2.5rem;
    padding: 0 0.5rem;
    background-color: #fff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #c9d8dc;
  }

  &__menu_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 2.5rem;
    padding: 0 0.5rem;
    background-color: #fff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #c9d8dc;

    &__label {
      min-width: 3rem;
    }

    &__radio {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  &__menu_body {
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    &__info {
      color: #657991;
      font-size: 0.75rem;
      border-bottom: 1px solid #c9d8dc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.8rem 0.5rem;
      &__icon {
        fill: #657991;
      }
    }

    &__label {
      min-width: 5.5rem;
    }
    &__box {
      width: 100%;
      p {
        font-size: 0.75rem;
        color: #f10031;
      }
    }
    &__input {
      border: 1px solid #caced5;
      padding: 0.9rem 0.75rem;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      width: 100%;

      &:focus {
        border: 1.3px solid #07827c;
        color: #07827c;
      }

      &::placeholder {
        color: #bfbfbf;
      }
    }

    &__input-failed {
      border: 1px solid #f10031;
      padding: 0.9rem 0.75rem;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      width: 100%;

      &:focus {
        border: 1.3px solid #07827c;
        color: #07827c;
      }

      &::placeholder {
        color: #bfbfbf;
      }
    }

    &__group-name {
      border-bottom: 1px solid #c9d8dc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
    }
  }

  &__menu_footer {
    width: 100%;
    align-items: center;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 1px solid #c9d8dc;
  }

  &__popover-wrapper {
    display: flex;
    padding: 0.25rem;
    background-color: #fff3b7;
    border-color: #fff3b7;
  }

  &__search-box {
    margin: auto;
  }

  &__popover-container {
    display: flex;
    padding: 0.25rem;

    &-active {
      padding: 0.25rem;
      display: flex;
      background-color: #fff3b7;
    }
  }

  &__popover-content {
    padding: 0.25rem;

    p {
      margin: 0;
      font-size: 0.625rem;
      color: #4d4f5c;
    }
  }

  &__popover-icon {
    fill: #caced5;

    &-active {
      fill: #ffd500;
    }
  }

  &__popover-icon:hover {
    fill: #ffd500;
  }

  &__content_body {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &__content_footer {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 1px solid #c9d8dc;

    &__button {
      border-radius: 0.25rem;
      background-color: #f8f8fb;
      color: #caced5;
      padding: 0.25rem 1rem;
      width: auto;
      font-weight: 400;
      border: 1px solid #ced4da;

      &.active:nth-child(1) {
        background-color: #007e75;
        color: #ffffff;
        border-color: #007e75;
      }

      &.active:nth-child(2) {
        color: #ffffff;
        background-color: #007e75;
        border-color: #007e75;
      }
    }
  }

  &__swipe {
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    height: 8.2rem;
  }

  &__none {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    background-color: #f0f0f3;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #c9d8dc;
  }

  &__button {
    border-radius: 0.25rem;
    background-color: #f8f8fb;
    color: #007e75;
    padding: 0.125rem;
    width: 4.5rem;
    border: 1px solid #ced4da;

    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }

  &__container {
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f8f8fb;
    border-radius: 0.5rem;
    border: 1px solid #c9d8dc;
    overflow: hidden;

    &__box {
      padding: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      overflow-y: auto;
      align-content: baseline;

      .box-skeleton {
        border-radius: 0.25rem;
        margin: 0.35rem;
      }

      &__col {
        margin: 0.35rem;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      height: inherit;

      .picture {
        margin: 2rem;
      }
    }
  }

  &__footer {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 1px solid #c9d8dc;

    &__selection {
      display: flex;
      align-items: center;
    }

    &__content {
      display: flex;
      align-items: center;
      width: 50%;

      &:nth-child(2) {
        justify-content: flex-end;
      }

      button {
        border-radius: 0.25rem;
        background-color: #f8f8fb;
        color: #caced5;
        padding: 0.125rem;
        margin: 0 0.2rem;
        width: 6rem;
        border: 1px solid #ced4da;
        pointer-events: none;

        &.active:nth-child(1) {
          color: #007e75;
          background-color: #ffffff;
          border-color: #caced5;
          pointer-events: all;
        }

        &.active:nth-child(2) {
          color: #ffffff;
          background-color: #007e75;
          border-color: #007e75;
          pointer-events: all;
        }
      }
    }
  }
}

.main-home {
 padding: 0;
 background: transparent;

 &__header {
  border-radius: 0.5rem;
  padding: 0.45rem 2.5rem;
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--default,
  &--gray {
   background-color: #f0f0f3;
   border: 0.063rem solid #caced5;
  }

  &--outlined {
   background: #f8f8fb;
   border: 0.063rem solid #c9d8dc;
  }

  &__title-container {
   display: flex;
   align-items: center;
  }

  &__right-container {
   display: flex;
   align-items: center;
  }

  &__title {
   &.ant-typography {
    font-weight: 400;
    font-size: 1.875rem;
    margin-bottom: 0;
   }

   &--default,
   &--gray {
    &.ant-typography {
     color: #657991;
    }
   }

   &--outlined {
    &.ant-typography {
     color: #c9d8dc;
     font-size: 1.5rem;
    }
   }
  }

  &__right-wrapper {
   display: flex;
  }

  &__back-button {
   padding: 0.5rem;
   border-radius: 0.25rem;
   background: #f8f8fb;
   border: 0.063rem solid #d7dae2;
   display: flex;
   align-items: center;
   justify-content: center;
   justify-items: center;
   cursor: pointer;
   transition: 0.1s linear;
   color: #364961;

   &:hover {
    border-color: #007e75;
    color: #007e75;
    transition: 0.1s linear;
   }

   &:focus {
    box-shadow: 0px 0px 0px 2px rgba(0, 126, 117, 0.5);
    transition: 0.1s linear;
   }
  }

 }

 &__popup-desc {
  &-1 {
   width: 17rem;
   margin-bottom: 1rem;
  }

  &-2 {
   font-weight: bold;
  }
 }

 &__recent-used {
  display: flex;
 }

 &__search-box {
  margin: 0 1rem 0 auto;
 }

 &__add-module {
  background: #007e75;
  padding: 0rem 1rem;
 }

 &__add-module-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem 0 0;
 }

 &__add-module-icon {
  fill: #fff;
 }

 &__add-module-text {
  color: white;
  font-size: 0.75rem;
 }

 &__content {
  padding: 0 0 0 2rem;

  &__space {
   margin: 0 1rem 0 0;
  }

  &__loading {
   height: 2rem;
   width: 6rem;
   margin-right: 1rem;
  }
 }

 &__pagination {
  position: absolute;
  z-index: 1;
 }

 &__arrow {
  border: 0.063rem solid #caced5;
  border-radius: 0.25rem;
  height: 1.65rem;
  width: 1.65rem;
 }

 &__arrow-left {
  height: 100%;
  width: 0.75rem;
  transform: rotate(90deg);
  margin: 0 0.125rem 0.125rem 0;
 }

 &__arrow-right {
  height: 100%;
  width: 0.75rem;
  transform: rotate(-90deg);
  margin: 0 0 0.125rem 0.125rem;
 }

 &__show-button {
  display: flex;
  margin: 0.5rem 1rem 1rem 0.5rem;

  &__less {
   margin-right: 0.625rem;
   margin-left: auto;

   .arrow {
    fill: #007e75;

    &.up {
     transform: rotate(180deg);
    }
   }
  }

  &__all {
   &:hover {
    box-shadow: -0.25rem 0.313rem 0.625rem -0.313rem #007e75;
   }
  }
 }

 .header-box {
  font-size: 1.5rem;
  color: #c9d8dc;
  border-radius: 0.5rem;
  border: 0.063rem solid #c9d8dc;
  padding: 0.45rem 0 0.45rem 2.2rem;
  margin: 1rem 0;
 }

 &__content-title {
  width: 5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #caced5;
  margin: 1rem 0 0 0;

  &__space {
   margin: 1rem 0 3rem 0;

   svg {
    height: 3.75rem;
    width: 3.75rem;
   }
  }
 }

 &__divider {
  margin: 3rem 0 1rem 0;
  height: 0.063rem;
  width: 99%;
  background: #caced5;
 }

 &__recent-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0 1rem 0 2.25rem;

  &__loading {
   height: 2rem;
   width: 6rem;
  }
 }

 &__recent-action-sort {
  display: flex;
 }

 &__recent-action-sort-text {
  font-weight: 600;
  color: #616477;
 }

 &__recent-button {
  background: #007e75;
  padding: 0.25rem 1rem;
 }

 &__recent-button-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem 0 0;
 }

 &__recent-button-icon {
  fill: #fff;
 }

 &__recent-button-text {
  color: white;
 }
}
.rst-pwd-pg {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;

  &__li-logo {
    height: 2.5rem;
  }

  &__version {
    color: $color-disabled;
  }

  &__bvt {
    display: flex;
    flex-direction: column;
    justify-self: start;

    &-logo {
      height: 2rem;
    }

    span {
      font-size: 0.55rem;
    }
  }
}
