.SharedPrintableComponent {
  &__wrapper {
    background: rgb(204, 204, 204);

    .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only {
      border-radius: 8px;
    }
  }

  &__container {
    width: 210mm;
    min-height: 100vh;
    position: relative;
    background: white;
    display: block;
    margin: 0 auto 0.5cm;
  }

  &__header {
    padding: 50px 50px 0 50px;

    &-title {
      font-size: 28px;
      color: #07827C;
      font-weight: bold;
    }

    &-img {
      height: 2.5rem;
    }
  }

  &__body {
    padding: 10px 50px 20px 50px;

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #07827C;
      }
    }

    .ant-tabs-tab:hover {
      color: #07827C;
    }

    .ant-table table .ant-table-row {
      box-shadow: none;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 0.75rem;
    margin-right: 0.75rem;
    position: fixed;
    bottom: 2rem;
    right: 0;

    &-btn {
      width: 60px !important;
      height: 60px !important;
    }

    .ant-btn:active,.ant-btn:hover,.ant-btn:focus {
      color: #07827C;
      border-color: #07827C;
      background: #fff;
  }
  }

}
