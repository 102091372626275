.map-aside-right {
  z-index: 1100;
  right: 0;
  position: fixed;
  width: 20rem;
  top: 0;
  height: 100vh;
  background-color: #fff;
  &.open {
    right: 0;
  }
  &.closed {
    right: -20rem;
  }
  &__toggle {
    right: 1.75rem;
    background: #000;
    position: relative;
    width: 20px;
    cursor: pointer;
  }
}
