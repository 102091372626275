.AsmanCardGenderByAgeComponent{
  &__wrapper-gender{
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: normal;
  }
  &__row{
    color: #696969;
    font-weight: 600;
    border: 1px solid #BEBEBE !important;

    .ant-table-cell{
      //padding: 0 !important;
      border-bottom: unset;

      &-row-hover{
       background: none !important;
      }
    }
  }

  th {
    background: unset !important;
  }
  th:before {
    background-color: unset !important;
  }


  tr:first-child{
    & td.AsmanCardGenderByAgeComponent__cell-gender{
      border: 1px solid #BEBEBE !important;
    }
    & td.AsmanCardGenderByAgeComponent__cell-male, & td.AsmanCardGenderByAgeComponent__cell-female{
      border-top: 1px solid #BEBEBE !important;
      border-bottom: 1px solid #BEBEBE !important;
    }
    & td.AsmanCardGenderByAgeComponent__cell-total{
      border-top: 1px solid #BEBEBE !important;
      border-bottom: 1px solid #BEBEBE !important;
      border-right: 1px solid #BEBEBE !important;
    }
  }

  tr:not(:first-child){
    & td.AsmanCardGenderByAgeComponent__cell-gender{
      border-left: 1px solid #BEBEBE !important;
      border-bottom: 1px solid #BEBEBE !important;
      border-right: 1px solid #BEBEBE !important;
    }

    & td.AsmanCardGenderByAgeComponent__cell-male, & td.AsmanCardGenderByAgeComponent__cell-female{
      border-bottom: 1px solid #BEBEBE !important;
    }
    & td.AsmanCardGenderByAgeComponent__cell-total{
      border-bottom: 1px solid #BEBEBE !important;
      border-right: 1px solid #BEBEBE !important;
    }
  }

}
