.leaflet-popup-content-wrapper .leaflet-popup-content {
  width: auto;
  margin: 0;
}

.leaflet-popup-close-button {
  // display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem !important;
  height: 1.5rem !important;
  top: .625rem !important;
  right: .625rem !important;
  flex-shrink: 1;
  margin-left: auto;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  background: #fff !important;
  border: 0.063rem solid #caced5 !important;
  cursor: pointer;
  span {
    font-weight: 900;
    font-size: 1rem;
  }
}

.datex-popup-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  min-width: 12.875rem;

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;

    &-close {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &-button {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 1;
        margin-left: auto;
        padding: 0.25rem;
        border-radius: 0.25rem;
        background: #fff;
        border: 0.063rem solid #caced5;
        cursor: pointer;

        > svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    &-flow-padding {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.875rem;
    }

    &-flow {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 0.25rem;

        > svg {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0.25rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &-text-bold {
        font-family: sans-serif, 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        color: #6C757D;
        padding: 0.125rem 0.25rem;
      }

      &-text {
        font-family: sans-serif, 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        color: #6C757D;
        padding: 0.25rem;
      }
    }
  }
}

