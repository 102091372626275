.shared-dropdown {
  &.dropdown-place {
    font-size: 0.875rem;
    width: 100%;
    font-weight: 500;
    color: #616477;

    &__container {
      position: relative;
    }

    &__display-box {
      padding: 0.425rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #cacedc;
      border-radius: 0.25rem;
      cursor: pointer;

      &:hover {
        background-color: #f8f8fb;
      }

      &.active {
        border-color: #007d74;
      }

      &.disabled {
        background-color: #f8f8fb;
        cursor: auto;
        pointer-events: none;
      }

      &-input {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid #cacedc;
        border-right: none;
        border-radius: 0.25rem;
        background-color: #f8f8fb;

        &_placeholder {
          padding: 0.075rem 0;
          color: #caced5;
        }

        &_text {
          text-align: center;
          padding: 0 2rem 0 0.25rem;
        }

        &_button {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          padding: 0.15rem;
          border: 1px solid #cacedc;
          border-radius: 0.25rem;
          background-color: #fff;
          cursor: pointer;
        }
      }

      &__arrow-down {
        border-left: 0.3rem solid transparent;
        border-right: 0.3rem solid transparent;
        border-top: 0.4rem solid #4d4f5c;
      }

      &__arrow-up {
        border-left: 0.3rem solid transparent;
        border-right: 0.3rem solid transparent;
        border-bottom: 0.4rem solid #4d4f5c;
      }
    }

    &__options {
      position: absolute;
      width: 100%;
      background-color: #fff;
      border: 1px solid #cacedc;
      border-radius: 0.25rem;
      z-index: 1;

      &-search {
        padding: 0.425rem 0;
        background-color: #f8f8fb;
        border-bottom: 1px solid #caced5;
        border-radius: 0.25rem 0.25rem 0 0;
        align-items: center;
        overflow: hidden;
        input {
          flex: 1;
          border: none;
          font-family: sans-serif, 'Source Sans Pro';
          font-weight: normal;
          font-size: 0.875rem;
          padding: 0 0.425rem;
          background: transparent;
          width: 100%;
          &::placeholder {
            color: #caced5;
            font-size: 0.875rem;
          }
        }
      }

      &-list-container {
        padding: 0.25rem 0;
        height: 12rem;
        overflow-y: scroll;
      }

      &-list {
        padding: 0.425rem;
        cursor: pointer;

        &:hover {
          background-color: #007d74;
          color: #fff;
        }

        &.active {
          color: #fff;
          background-color: #007d74;
        }
        &.disabled {
          color: #caced5;
          cursor: not-allowed;
          background-color: #fff;
        }
      }

      &-not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12rem;
      }
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__popover-wrapper {
    display: block;
  }

  .arrow {
    text-align: center;
    width: 200px;
  }
  
  .arrow---down {
    position: absolute;
  }
  
  .arrow---down:after,
  .arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  .arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 13%;
    margin-left: -10px;
    bottom: -50px;
  }
}
