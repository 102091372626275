.AsmanCardDemographyCathmentComponent{
  &__wrapper{
    margin-top: 1rem;
  }
  &__top-title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  &__title-accent{
    border: 1px solid #07827C;
    border-radius: 4px;
    padding: 4px 8px;
  }

  &__chart-total{
    background-color: #07827C;
    padding: 0.375rem;
    color: #fff;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    border-radius: 4px;
    height: 30px;
  }
  &__percent{
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    color: #696969;
    font-weight: 600;
  }
  &__chart{
    display: flex;

    &-male{
      background-color: #00A8FF;
      padding: 0.375rem;
      color: #fff;
      display: flex;
      align-items: center;
      white-space: nowrap;
      border-radius: 4px 0 0 4px;
      height: 30px;
    }
    &-female{
      background-color: #F425A3;
      padding: 0.375rem;
      color: #fff;
      display: flex;
      align-items: center;
      white-space: nowrap;
      border-radius: 0 4px 4px 0;
      height: 30px;
      justify-content: end;
    }
  }
}
