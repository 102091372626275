@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;800;900&display=swap');

// DEHH JANGAN DI GANTI
:root {
  font-size: 1vw;
  font-family: sans-serif, 'Source Sans Pro';
  @media (min-width: 1200px) {
    font-size: 16px;
  }
  @media (min-width: 1445px) {
    font-size: 16px;
  }
  background-color: #f8f8fb;
}
// DEHH JANGAN DI GANTI
* {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Old version of Firefox */
  -ms-user-select: none; /* Internet Explorer or Edge */
  user-select: none; /* All modern browsers */
}

::-webkit-scrollbar {
  background: $grey;
  width: 0.375rem;
  height: 0.375rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1.5rem;
  background: $green;
  position: absolute;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0.3125rem 1.25rem rgba(74, 74, 74, 0.2);
  border-radius: 1.5rem;
}

.ant-message {
  z-index: 1300;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

.ant-notification{
  z-index: 2024;
}