.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;

    &__li-logo {
      height: 2.5rem;
    }

    &__bvt-logo {
      height: 2rem;
    }
  }
}
