.feature-main-map {
  &.MarkerSumPoi {
    border-radius: 50%;
    text-align: center;
    padding: 5px 0 0 0;
    background-color: #efefef;
    border: 5px solid #c1c1c144;

    span {
      font-size: 12px;
    }
  }
}
