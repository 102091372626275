.AsmanCardBrandByIndustryComponent {
  th {
    background: unset !important;
    border-bottom: none !important;
    color: #BEBEBE !important;
  }

  th:before {
    background-color: unset !important;
  }

  &__row td {
    color: #696969;
    font-weight: 600;
    border-bottom: 1px solid #BEBEBE !important;
  }

  &__wrapper-name {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__total-th {
    .ant-table-column-title {
      text-align: end;
    }
  }

  &__wrapper-total {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: end;
  }

  &__pointer {
    cursor: pointer;
  }

  &__modal {
    .ant-modal-body {
      height: 15rem;
      overflow: auto;
      overflow-x: hidden;
    }

    &-title {
      font-weight: normal;
    }

    &-body {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
      flex-wrap: wrap;
    }

    &-item {
      background: #F5F5F5;
      padding: 8px;
      border: 1px solid #CED4DA;
      border-radius: 4px;
    }
  }

}
