.poi-category-swipe {
  display: flex;
  height: 8.2rem;
  overflow: hidden;
  width: 100%;

  .poi-category-swipe__navigation--prev,
  .poi-category-swipe__navigation--next {
    background-color: #ffffff;
    border: 1px solid #c9d8dc;
    width: 2.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.swiper-button-disabled {
      cursor: unset;
      background-color: #f8f8fb;

      svg {
        fill: #caced5;
      }
    }
  }

  .poi-category-swipe__navigation--prev {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .poi-category-swipe__navigation--next {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .poi-category-swipe__navigation--prev svg {
    transform: rotate(0deg);
    fill: #657991;
  }

  .poi-category-swipe__navigation--next svg {
    transform: rotate(180deg);
    fill: #657991;
  }

  .swiper-wrapper {
    height: 7.2rem;
  }

  .swipe-box {
    background-color: #f8f8fb;
    border: 1px solid #c9d8dc;
    width: 92%;
    cursor: -webkit-grab;
    cursor: grab;

    &__container {
      height: 8rem;
    }

    .loader {
      margin-top: -7rem;
      display: flex;
      div {
        margin: 0 0.2rem;
      }
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0.625rem;

      .bvt-skeleton__picture {
        height: 4rem;
        width: 4rem;
      }

      div:nth-child(2) {
        display: flex;
        justify-content: center;
        margin-top: 0.25rem;

        .image-link-box-text {
          margin: 0.25rem 0 0;
          transform: translate(-15%, -190%);
        }
      }

      &.disabled{
        cursor: not-allowed !important;
        transform: unset;
        transition: unset;
        top: 0 !important;
      }

      .poi-category-swipe__content {
        height: 4.5rem;
        margin: auto;
        width: 4.5rem;

        svg path {
          fill: #ffffff;
        }

        img {
          height: 1.875rem;
          width: 1.875rem;
        }
      }

      .poi-category-swipe__content_2 {
        height: 4.5rem;
        margin: auto;
        width: 4.5rem;
        cursor: inherit;

        svg path {
          fill: #ffffff;
        }

        img {
          height: 4.5rem;
          width: 4.5rem;
        }

        div:nth-child(2) {
          display: flex;
          justify-content: center;
          // margin-top: 0.25rem;

          .image-link-box-text {
            margin: 0.8rem 0 0;
            transform: translate(-15%, -190%);
          }
        }

        .image-link-box-text {
          cursor: inherit;
          color: #1D1E31;
          display: block;
          margin-left: auto;
          margin-right: auto;
          font-size: 0.875rem;
          margin-top: 1.625rem;
          font-weight: 400;
          width: 6.5rem;
          text-align: center;
          line-height: normal;
          position: relative;

          &--landscape {
            max-width: 5.75rem;
            word-break: break-word;
            font-size: 0.875rem;
            font-weight: bold;
            line-height: 1rem;

            &.hasColor {
              color: white;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1281px) {
  .poi-category-swipe {
    .swipe-box {
      .swiper-slide {
        .poi-category-swipe__content_2 {
          div:nth-child(2) {
            .image-link-box-text {
              margin: 0.2rem 0 0;
            }
          }
          .image-link-box-text {
            font-size: 0.680rem;
            margin: 0.2rem 0 0;
          }
        }
      }
    }
  }

}