.AgeRangeSelectedBox {
  &__card {
    min-width: 8rem;
    min-height: 9.25rem;
    width: 100%;
    max-width: 100%;
    border-radius: 0.25rem;
    background: #f2f2f5;
    border: 1px solid #c2c5cc;
    transition: all ease-in 0.2s;
    cursor: pointer;
    position: relative;
    &-check {
      position: absolute;
      right: 0.5rem;
      top: 2rem;
      opacity: 0;
      transition: all 0.25s ease-in;
    }
    &-header {
      display: flex;
      flex-direction: column;
      padding: 0.25rem 0.5rem;

      &-typeheading {
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        color: #657991;
      }

      &-agetype {
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        color: #657991;
      }
    }
    &-body {
      padding: 0.5rem;
      display: flex;
      align-items: flex-start;
      flex: 1;
      justify-content: center;

      &-icon {
        transition: all ease-in 0.2s;
        color: #657991;
        flex: 0;
        display: flex;
        transform: translateY(0rem);
      }
    }

    &-bottom {
      display: flex;
      border-top: 1px solid #c2c5cc;

      &-age {
        border-right: 1px solid #c2c5cc;
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: center;
        color: #657991;
        padding: 1.2rem;
        width: 50%;
      }
      &-agerange {
        font-weight: bold;
        font-size: 0.857rem;
        line-height: 0.875rem;
        text-align: center;
        color: #657991;
        padding: 0.5rem;
        border-bottom: 1px solid #c2c5cc;
        width: 100%;

        &-years {
          font-size: 0.857rem;
          line-height: 0.875rem;
          text-align: center;
          color: #657991;
          padding: 0.5rem;
          width: 100%;
        }

        &-container {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          width: 50%;
        }
      }
    }
  }

  &:hover,
  &.AgeRangeSelectedBox__card--active {
    &.AgeRangeSelectedBox__card {
      transition: all ease-in 0.2s;
      background-color: #ffffff;
      border: 1px solid #c2c5cc;
      &:not(.AgeRangeSelectedBox__card--active) {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      }
    }
    .AgeRangeSelectedBox__card-header-agetype {
      color: #007e75;
    }
    .AgeRangeSelectedBox__card-body-icon {
      color: #007e75;
    }
  }
  &.AgeRangeSelectedBox__card--active {
    .AgeRangeSelectedBox__card-body-icon {
      color: #007e75;
      transition: all ease-in 0.2s;
      transform: translateY(-0.5rem);
    }
    .AgeRangeSelectedBox__card-check {
      top: 0.25rem;
      opacity: 1;
      transition: all 0.25s ease-in;
    }
  }
}
