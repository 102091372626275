.AsmanPertaminaAssetsItems {
  .top-container {
    padding: 0.5rem 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.125rem 0.5rem;
      border-radius: 0.5rem;
      &.exist {
        background: #eee;
        border: 1px solid #e1e1e1;
        span {
          color: #616161;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }
      &.constraction {
        border: 1px solid #8cd7b6;
        background: #e6f6ef;
        span {
          color: #047845;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }
      &.oprational {
        border: 1px solid #ADC3FD;
        background: #EDF2FF;
        span {
          color: #3659B2;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }
    }
    .action-button {
      display: flex;
      gap: 0.5rem;
      .btn {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.375rem;
        border: 1px solid #ced4da;
      }
    }
  }
  .container-horizontal {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 1rem 1rem;
    width: 100%;
    border-bottom: 1px solid #ebeff5;
    cursor: pointer;
    .left-content {
      display: flex;
      flex-direction: column;
      gap: 0.94rem;
      width: 13.9375rem;
      .top-inner-content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        .title {
          color: #000;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.625rem;
          line-break: anywhere;
          text-wrap: balance;
        }
        .coordinate {
          color: #878a92;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
        .address {
          color:  #878a92;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem; 
        }
      }
      .bottom-inner-content {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 0.25rem;
        align-self: stretch;
        flex-wrap: wrap;
        .tag {
          display: flex;
          padding: 0.125rem 0.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
          border: 1px solid #e1e1e1;
          background: #fff;
          .text {
            color: #878a92;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
          }
        }
      }
    }
    .right-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .img {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .container-vertical {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    .left-content {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .top-inner-content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        .title {
          color: #000;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.625rem;
        }
        .coordinate {
          color: #878a92;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem;
        }
        .address {
          color:  #878a92;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem; 
        }
      }
      .bottom-inner-content {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 0.25rem;
        align-self: stretch;
        flex-wrap: wrap;
        .tag {
          display: flex;
          padding: 0.125rem 0.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
          border: 1px solid #e1e1e1;
          background: #fff;
          .text {
            color: #878a92;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
          }
        }
      }
    }
    .right-content {
      display: flex;
      align-items: center;
      .img {
        width: 100%;
        height: 11.75rem;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}
