.feature-auth {
  &.LinkExpired {
    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 4.5rem 1rem 1rem 1rem;
      gap: 1.5rem;
    }
    &__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-size: 32px;
      text-align: center;
    }
    &__image {
      text-align: center;
    }
    &__paragraph {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 15px;
      text-align: center;
      line-height: 22px;
      p {
        margin: 0;
      }
      &.muted {
        color: #b9b9b9;
        margin-bottom: 1.5rem;
        .bold {
          font-weight: 500;
        }
      }
    }
    &__button {
      width: 100%;
      padding: 0.375rem 1.5rem;
      border-radius: 0.25rem;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      display: flex;
      transition: 0.25s ease-in-out;
      align-items: center;
      background: #fff;
      border: 1px solid #caced5;
      color: #007e75;
    }
  }
}