.DatexTelcoStepOnePoi__search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0.5rem;
  gap: 05rem;
  isolation: isolate;
  width: 100%;
  background: #ebeff5;

  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6c757d;
  }

  .ant-input-group-wrapper {
    background: #ffffff;
    opacity: 0.6;
    border-radius: 4px;
    .ant-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ant-input-group-addon {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fafafa;
      border: none;
    }
  }
  .icon-addonAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }
}

.DatexTelcoStepOnePoi__container {
  margin: 0.75rem 0;
  padding: 0.25rem 0.25rem 0.25rem 0;
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .DatexTelcoStepOnePoi__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0.4375rem;
    gap: 0.5rem;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 3px solid #ffffff;
    cursor: pointer;
    width: 31%;
    height: 8rem;
    text-align: center;

    svg {
      width: 2.25rem;
      height: 2rem;
      align-items: center;
    }

    &--active {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
      color: #495057;
      border: 3px solid #07827c;
    }

    .DatexTelcoStepOnePoi__item--image {
      width: 2.25rem;
    }

    &.disabled {
      cursor: not-allowed;
      filter: grayscale(1);
    }

    .DatexTelcoStepOnePoi__item--text {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #616477;
    }

    &--spin {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      animation: spinner-animation 1s infinite;
      svg {
        width: 3rem;
        height: 3rem;
      }
      &-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.625rem;
        display: flex;
        align-items: center;
        color: #07827c;
      }
    }
    &--data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.75rem;
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 0;
      }
      &-item {
        &:hover {
          .ant-checkbox {
            input.ant-checkbox-input {
              border-color: #07827c;
            }
          }
        }
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ced4da;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 0.75rem;
        .ant-checkbox {
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #07827c;
              border-color: #07827c;
            }
          }
          input.ant-checkbox-input {
            border-color: #07827c;
          }
        }
        &__description {
          display: flex;
          flex-direction: column;
          gap: 0;
          padding: 0;
          &--highlight {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: #495057;
          }
          &--detail {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: #a0a6a9;
          }
        }
      }
      .DatexTelcoStepOnePoi__item--image {
        width: 2.25rem;
      }

      .DatexTelcoStepOnePoi__item--text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #616477;
      }

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        color: #616477;
        inline-size: 5rem;
        overflow-wrap: break-word;
      }
    }
  }

  .no_data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    flex-direction: column;
    height: calc(100vh - 32rem);

    &__title {
      font-weight: 700;
      font-size: 20px;
      color: #6C757D;
    }

    &__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6C757D;
    }
  }

  .loading-search-brand {
    color: #007E75;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 32rem);
  }

  .POISearchResult {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;

    .ant-radio-group {
      width: 100%;
    }

    .ant-collapse {
      background-color: #ffffff;
      border: none;

      .ant-collapse-item,
      .ant-collapse-header,
      .ant-collapse-content {
        border: none;
      }

      .ant-collapse-header {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem;
        gap: 1.8125rem;
        width: 100%;
        background: #fafafa;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px !important;
        flex: none;
        flex-grow: 0;
        flex-direction: row-reverse;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #064f4b;
        box-sizing: border-box;

        &:has(.ant-radio-input:checked) {
          border: 1px solid #07827c;
        }

        .ant-radio-wrapper {
          display: flex;
          align-items: center;

          .ant-radio {
            top: 0;
          }

          span:not(.ant-radio) {
            pointer-events: none;
            display: flex;
            align-items: center;
            gap: 0.625rem;
            img {
              width: 24px;
            }
          }
        }

        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input + .ant-radio-inner {
          border-color: #07827c;
        }
        
        .ant-radio-input:checked:not(:hover) + .ant-radio-inner {
          border-color: #CED4DA;
        }
        
        .ant-radio-inner::after {
          background-color: #07827c;
        }
      }

      .ant-collapse-content-box {
        padding: 16px 0 16px 26px;
        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .BrandList {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      width: 100%;
      
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 12px;
        background: #fafafa;
        margin: 0;
      }

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #1d1e31;
      }
      .ant-checkbox-inner {
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      .ant-checkbox-input:checked + .ant-checkbox-inner {
        border: 1px solid #07827c;
        background-color: #07827c;
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner {
        border: 1px solid #07827c;
      }

      .ant-checkbox.ant-checkbox-checked::after {
        border-radius: 4px;
        border: 1px solid #07827c;
      }

      .ant-checkbox {
        top: 0;
      }
    }
  }
}

.DatexTelcoStepOnePoi {

  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
      overflow: hidden; /* Ensures the overflow is hidden */
      text-overflow: ellipsis; /* Adds an ellipsis to signify clipped text */
      width: 100%;
    }
  }
  &.__popover-wrapper {
    display: block;
  }
  
  &.arrow {
    text-align: center;
    width: 200px;
  }
  
  &.arrow---down {
    position: absolute;
  }
  
  &.arrow---down:after,
  &.arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  &.arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 44%;
    margin-left: -10px;
    bottom: -50px;
  }
}