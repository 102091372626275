.confirm-remove {
  color: #616477;
  &__header {
    background-color: #f2f4f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #007e75;
    font-size: 1rem;
    padding: 0.325rem 0.625rem;
  }

  &__body {
    padding: 1rem;
    &__icon {
      display: flex;
      justify-content: center;
    }

    &__text {
      margin-top: 0.5rem;
      text-align: center;
      color: #616477;

      h1 {
        color: #616477;
      }
      p {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__footer {
    display: inline-flex;
    padding: 1rem;

    &__button {
      &--left {
        flex-grow: 1;
        margin: 0rem 0.5rem 0rem 2rem;
        background-color: #ffffff;
        border: 1px solid #caced5;
      }

      &--right {
        flex-grow: 1;
        margin: 0rem 2rem 0rem 0.5rem;
        color: #ffffff;
        background-color: #ff5454;
        border: none;
      }
    }
  }
}
