.MenuItem {
  &--container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &--icon {
    display: flex;
    align-items: center;
  }

  &--item {
    font-size: 1rem;
    align-self: center;
  }
}
