.MainMapTopSideLayout{
    &__wrapper{
        position: absolute;
        left: 32rem;
        top: 9rem;
        z-index: 1000;
        width: 100%;
        display: flex;
    }
    &__row{
        flex-grow: 1;
    }
}