.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000030;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &__header {
    background-color: #f2f4f7;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    color: #007e75;
    padding: 0.325rem 0.95rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  &__content {
    background-color: #fff;
    min-width: 19rem;
    border-radius: 0.75rem;
    min-height: 16.25rem;
  }

  &__body {
    padding: 1rem;

    span {
      font-size: 1rem;
    }

    &__title {
      color: #616477;
      font-weight: 500;
      text-align: center;
      font-size: 2rem;
      margin-bottom: 0;

      &.text-bold {
        font-weight: 700;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
    }

    &__text {
      margin-top: 0.5rem;
      text-align: center;
      color: #616477;

      h1 {
        color: #616477;
      }

      p {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  &__footer {
    display: flex;
    padding: 2rem 1rem 1rem 1rem;
    justify-content: center;
    gap: 1rem;

    &__button {
      &--left {
        padding: 0.125rem 1rem;
        color: #007e75;
        background-color: #ffffff;
        border: 1px solid #caced5;

        &.text-grey {
          color: #616477;
        }
      }

      &--right {
        padding: 0.125rem 1rem;
        color: #ffffff;
        background-color: #007e75;
        border: none;
        -webkit-box-shadow: 0px 10px 20px 0px #f15e5e40;
        -moz-box-shadow: 0px 10px 20px 0px #f15e5e40;
        box-shadow: 0px 10px 20px 0px #f15e5e40;

        &.red {
          background-color: #ff5454;
        }
      }
    }
  }
}
