.action-btn-table {
  padding: 0.125rem;
  color: $color-dark;
  background-color: $color-white;
  fill: $color-white;

  &__content {
    margin-left: 0.375rem;
    padding-right: 0.375rem;
    font-size: 0.875rem;
  }

  &.resume {
    fill: #00a8ff;
    &:hover {
      background-color: #00a8ff;
      fill: $color-white;
      color: $color-white;
    }
  }

  &.duplicate {
    fill: #24ccb8;
    &:hover {
      background-color: #24ccb8;
      fill: $color-white;
      color: $color-white;
    }
  }

  &.create-report {
    fill: #ff6700;
    &:hover {
      background-color: #ff6700;
      fill: $color-white;
      color: $color-white;
    }
  }

  &.remove {
    fill: #ff3939;
    &:hover {
      background-color: #ff3939;
      fill: $color-white;
      color: $color-white;
    }
  }

  &.edit {
    fill: $color-white;
  }

  &.disabled {
    fill: $color-grey;
    color: $color-grey;
    pointer-events: none;
  }

  &:hover {
    fill: #00a8ff;
    color: $color-white;
  }
}
