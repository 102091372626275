.SesSelectedBox {
  &__card {
    display: flex;
    flex-direction: column;
    height: 10.9rem;
    width: 10.9rem;
    align-items: center;
    border-radius: 0.5rem;
    background: #f2f2f5;
    border: 1px solid #c2c5cc;
    cursor: pointer;
    transition: all ease-in 0.3s;
    position: relative;
    &:hover:not(&--active) {
      background: #fff;
      border: 1px solid #c2c5cc;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }
    &--active {
      border: 1px solid transparent;
      .SesSelectedBox__ses-text,
      .SesSelectedBox__ses-title {
        color: #fff;
      }
      .SesSelectedBox__ses-check {
        opacity: 1;
        transform: translate(-50%, 0%);
        transition: all ease-in 0.3s;
      }
      .SesSelectedBox__image {
        transform: translateY(-20%);
        transition: all ease-in 0.3s;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  &__ses {
    &-text {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-align: center;
      color: #657991;
    }
    &-title {
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-align: center;
      color: #657991;
    }
    &-check {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, 40%);
      transition: all ease-in 0.3s;
      opacity: 0;
    }
  }
  &__body {
    padding-bottom: 1.25rem;
    margin-top: auto;
  }
  &__image {
    transform: translateY(0%);
    transition: all ease-in 0.3s;
    img,
    svg {
      display: block;
      width: auto;
      height: auto;
    }
  }
}
