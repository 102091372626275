.AccountCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 1.5rem;
  gap: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  width: 100%;

  &--header {
    padding: 0.5rem 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #495057;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    background: #f9fbfc;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
  }

  &--content {
    padding: 0 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
