.AsmanMarkerGeojsonInternalComponent {
	&__popup {
		.leaflet-popup-content {
			width: auto !important;
			padding: 0px;
			margin: 0px;
		}
		.leaflet-popup-content-wrapper {
			border-radius: 0.25rem;
			padding: 0px;
		}
	}
	&__popup-wrap {
		min-width: 40.375rem;
		display: flex;
		flex-direction: column;
		padding: 1rem;

		.ant-btn-primary {
			border-radius: 0.5rem;
			border-color: #07827c;
			background: #07827c;
		}
		.ant-btn-primary:hover, .ant-btn-primary:focus {
			border-color: #1e8f85;
			background: #1e8f85;
		}
	}
	&__popup-table {
		tr {
            td{
                padding-bottom: 0.25rem;
                padding-top: 0.25rem;
				vertical-align: top;
            }
			td:first-child {
				font-family: 'Source Sans Pro', sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 26px;
				color: #878a92;
			}
            td:nth-child(2){
                padding-left: 1rem;
                padding-right: 1rem;
            }
			td:last-child {
				font-family: 'Source Sans Pro', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 26px;
				color: #696969;
			}
		}
	}
}
