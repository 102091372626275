.DatexTelcoStepOne {
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  .DatexTelcoStepOne--wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: #ffffff;
    .DatexTelcoStepOne--item {
      padding: 0 0.5rem 0.75rem 0.75rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      &__search {
        padding: 0.75px;
        background: #ffffff;
        border-bottom: 1px solid #f0f0f3;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);

        .ant-input-group-wrapper {
          background: #ffffff;
          opacity: 0.6;
          border: 1px solid #ced4da;
          border-radius: 4px;

          .DatexTelcoStepOne--item__search__add-on-after {
            display: flex;
            align-items: center;
            gap: 0.375rem;
          }
        }
      }
    }

    .DatexTelcoStepOne--buttonType {
      top: 0;
      z-index: 2;
      height: auto;
      position: sticky;
      background-color: #fff;
      padding: 0.75rem 0.5rem 0.25rem 0.75rem;
    }
  }
  &-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    &__no-selected {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 3.8125rem;
      &__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #6C757D;
        text-align: center;
      }
      &__desc {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #6C757D;
        text-align: center;
      }
      &__img {
        width: 9.375rem;
        height: 10rem;
        margin-bottom: 2rem;
      }
    }
  }
}
