.PoiSearch {
    &__popup {
      width: 252px;
      padding: 1rem;
      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: .25rem;
        max-width: 200px;
        .icon {
          width: auto;
          height: auto;
        }
        .wrapper-title {
          .title {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
          }
          .subtitle {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #A0A6A9;
          }
        }
      }
      .address {
        margin : 0 !important;
      }
      .info-wrapper {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .content {
          color: #495057;
          font-weight: 400;
          font-family: "Source Sans Pro", sans-serif;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .value {
            text-align: end;
          }
        }
        .line {
          border: 1px solid #ECEDEE;
        }
      }
    }
  }