.main-table {
  .ant-table {
    margin-right: 1rem;
    margin-bottom: 1rem;

    table {
      background-color: #f8f8fb;
      border-collapse: separate;
      border-spacing: 0 0.35rem;
      .ant-table-thead {
        // background-color: #f8f8fb;
        background-color: #f8f8fb !important;
        font-weight: bold;
        font-size: 0.875rem;
        th:last-of-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .ant-table-pagination {
    .ant-pagination {
      margin: 0.25rem 0;
    }
  }

  .ant-table-title {
    background-color: #f8f8fb;
  }
}

.popupFormCreateProject {
  > .header {
    display: flex;
    align-items: center;
    margin: rem(10) 0 rem(30);

    > .logo {
      @include flexCenter;
      width: rem(43);
      height: rem(43);
      background-color: #fff;
      border-radius: rem(8);
      box-shadow: rgba(100, 100, 111, 0.2) 0 rem(7) rem(29) 0;
    }

    > .title {
      margin-left: rem(15);

      > .name {
        font-weight: bold;
        font-size: rem(17);
      }

      > .lastUsed {
        font-size: rem(12);
      }
    }
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: rem(1) solid #caced5;
    padding: 0 rem(6) 0 rem(9);
    border-radius: rem(4);
    font-size: rem(15);
    color: #caced5;
    height: rem(36);

    &:hover {
      background-color: #f8f8fb;
    }

    &.active {
      border: rem(1) solid #007e75;
    }

    &.failed {
      background-color: #fee7e7;
      border: rem(1) solid #f44e4a;
      color: #f44e4a;

      .icon-close.background_white {
        border: rem(1) solid #f44e4a;
      }

      svg path {
        fill: #f44e4a;
      }
    }

    &_value {
      border: transparent;
      flex: 1;
      padding-right: rem(9);
      font-weight: 600;
      background-color: transparent;
      &::placeholder {
        color: #caced5;
        font-weight: normal;
      }

      &.active {
        color: #007e75;
        font-weight: 400;

        &:hover {
          background-color: #f8f8fb;
        }
      }
    }

    .endadornment {
      display: flex;

      .icon-close {
        cursor: pointer;
      }
    }
  }

  > .note {
    @include flexCenter;
    margin-top: rem(17);
    height: rem(43);
    width: rem(550);
    background-color: #f8f8fb;
    border-radius: rem(4);

    > .logo {
      margin-right: rem(12);
    }

    > .text {
      font-size: rem(11);
    }
  }

  > .footer {
    @include flexCenter;
    margin-top: rem(18);

    > .cancel,
    > .create {
      font-size: rem(13);
      height: rem(29);
      border-radius: rem(4);
    }

    > .cancel {
      color: #007e75;
      background-color: #fff;
      border: rem(1) solid #caced5;
      width: rem(103);
      margin-right: rem(15);

      &:hover {
        box-shadow: 0 rem(4) rem(14) rem(-5) $grey;
      }
    }

    > .create {
      background-color: #f8f8fb;
      color: #caced5;
      width: rem(120);
      cursor: default;

      &.active {
        background-color: #007e75;
        color: #fff;
        cursor: pointer;

        &:hover {
          box-shadow: 0 rem(4) rem(14) rem(-5) #007e75;
        }
      }
    }
  }
}

.modalCreateProject {
  width: rem(590) !important;
  top: rem(200);

  .ant-modal-body {
    padding: 0 rem(20) rem(20);
  }
}

.popupModules {
  > .header {
    width: 100%;
    height: rem(44);
    background-color: #f8f8fb;
    border-radius: rem(8);
    border: rem(1) solid #c9d8dc;
    display: flex;
    align-items: center;
    font-size: rem(20);
    color: #c9d8dc;
    padding-left: rem(12);
  }

  > .content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: rem(17);
    row-gap: rem(24);
    column-gap: rem(9);
    margin-bottom: rem(20);

    > .module {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      > .icon {
        background-color: #fff;
        border-radius: rem(8);
        width: rem(92);
        height: rem(92);
        @include flexCenter;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
        position: relative;
        top: 0;
        transition: top ease 0.2s;

        &:hover {
          top: rem(-10);
        }

        svg {
          height: rem(60);
          width: rem(60);
        }
      }

      > .text {
        margin-top: rem(14);
        text-align: center;
        line-height: 1.2;
        width: rem(90);
        font-size: rem(14);
      }
    }
  }
}

.ant-modal-close-x {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: rem(12);
}

.ant-modal-header {
  display: flex;
  align-items: center;
  background-color: #fafcfd;
  border-radius: rem(8) rem(8) 0 0;
  padding: 0 0 0 rem(12);
}

.ant-modal-title {
  font-size: rem(20);
  color: #007e75;
}

.ant-modal-content {
  border-radius: rem(8);
}

.ant-modal-header,
.ant-modal-close {
  height: rem(40);
}

.ant-modal-body {
  padding: rem(8) rem(32);
}

.modalPopupModules {
  width: rem(565) !important;
  top: rem(150);
}
