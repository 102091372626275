.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: #ffffff;
  z-index: 1000;
  padding: 0rem 1.25rem;

  &__left,
  &__middle,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    .ant-divider-vertical {
      height: 2rem;
      margin: 0.5rem 0.5rem;
    }
  }
}
