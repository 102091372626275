.SubMenu {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.652rem;
    border: 1px solid #c9d8dc;
    border-radius: 0.5rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.25rem;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #007e75;
    }
  }
  &__item {
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    cursor: pointer;
    align-items: center;
    font-weight: 600;
    &--variant-default {
      color: #364961;
      border: 0.0625rem solid transparent;
      &.hover-active {
        background-color: #f0f0f3;
        border-color: #caced5;
      }
    }
    &--variant-new {
      color: #f0f0f3;
      background-color: #007e75;
      border: 0.0625rem solid #007e75;
      &.hover-active {
        background-color: #007e75;
        border-color: #007e75;
      }
      &-divider {
        width: 100%;
        height: 1px;
        display: block;
        background: #c9d8dc;
        margin-bottom: 0.625rem;
        margin-top: 0.625rem;
      }
    }
    &.isDisabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &__item-child {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
