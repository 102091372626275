.AccountTab {
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  &--item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    gap: 0.5rem;
    background: #f8f8fb;
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    &:hover {
      background: #f0f0f3;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
    &.active {
      background: #f0f0f3;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
    &[disabled] {
      pointer-events: none;
      cursor: not-allowed;
      .AccountTab--label {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #6c757d;
  }
}
