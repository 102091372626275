.form-setup-poi {
  position: relative;
  border: 1px solid #c9d8dc;
  border-radius: 0.5rem;
  background-color: #f8f8fb;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 8% 84% 8%;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: #ffffff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #c9d8dc;

    &__content {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        position: absolute;
        left: 1rem;
      }
    }
  }

  &__body {
    &__row {
      padding: 0.5rem;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        border-bottom: 1px solid #c9d8dc;
      }

      &__content {
        overflow-y: scroll;
        // height: 30rem;
        min-height: 0rem;
        width: 100%;
      }
    }

    &__content {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 70%;
      }

      .input-group-name {
        width: 100%;
        border-radius: 0.25rem;
        border: 1px solid #caced5;
        padding: 0.15rem;
      }

      .input-group-name--close {
        position: absolute;
        transform: translate(-25px, 8px);
        height: 1.2rem;
        width: 1.2rem;
        border: 0.05rem solid #caced5;
        top: 3.3rem;
        right: -0.75rem;
        padding: 0.15rem;
        border-radius: 0.25rem;
        cursor: pointer;
      }
    }
  }

  &__footer {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 1px solid #c9d8dc;

    &__content {
      display: flex;
      align-items: center;
      width: 50%;

      &:nth-child(2) {
        justify-content: flex-end;
      }

      button {
        border-radius: 0.25rem;
        background-color: #f8f8fb;
        color: #caced5;
        padding: 0.125rem;
        margin: 0 1rem;
        width: 6rem;
        border: 1px solid #f8f8fb;
        pointer-events: none;

        &.active:nth-child(1) {
          color: #007e75;
          background-color: #ffffff;
          border-color: #caced5;
          pointer-events: all;
        }

        &.active:nth-child(2) {
          color: #ffffff;
          background-color: #007e75;
          border-color: #007e75;
          pointer-events: all;
        }
      }
    }
  }

  &-popup {
    .popup__footer__button--right {
      background-color: #ffffff;
      color: #007e75;
      border: 1px solid #caced5;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}
