.ant-table {
  .ant-table-thead {
    .ant-table-cell {
      background: none;
      border: none;
      font-weight: bold;
      font-size: 0.875rem;
    }

    .action {
      background-color: aqua;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-size: 0.875rem;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 0.35rem;
    .ant-table-row {
      background: #ffff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      font-size: 0.875rem;
    }
  }
  // .ant-pagination-item {
  //   display: none !important;
  // }
}
