.feature-mainmap{
    &.MainMapMapControlButton{
        padding: 0.625rem;
        background-color: #FFFFFF;
        border-radius: 0rem;
        border:1px solid #FFFFFF;
        transition: 0.25s ease-in-out;
        color: #495057;
        &:hover:not(.MainMapMapControlButton--disabled){
            color:#007d74;
            transition: 0.25s ease-in-out;
            box-shadow: rgba(0,0,0,0.25) 0px 3px 8px;
        }
        &.MainMapMapControlButton--disabled{
            cursor: not-allowed;
            opacity: 0.5;
        }
        &--top{
            border-radius: 0.5rem 0.5rem 0px 0px;
        }
        &--bottom{
            border-radius: 0px 0px 0.5rem 0.5rem;
            margin-bottom: 0.5rem;
        }
        &--single{
            border-radius: 0.5rem;
        }
    }
}