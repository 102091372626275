.ButtonGroupDatex{

  &__container {
    display: flex;
    flex-direction: row;
    padding: 6px;
    gap: 6px;
    width: 100%;
    background: #f0f0f3;
    border-radius: 4px;
    overflow-x: auto;
    .ButtonGroupDatex__wrapper{
      display: flex;
      gap: 6px;
    }
    .ButtonGroupDatex__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 78px;
      padding: 4px 0px;
      gap: .5rem;
      height: 3.75rem;
      flex-grow: 1;
      cursor: pointer;
      border-radius: 4px;
      color: #6c757d;
  
      // svg {
  
      // }
  
      &--active {
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
        color: #495057;
  
        svg {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
        }
      }
  
      &--disabled:hover, &--disabled:active, &--disabled:focus{
        cursor: not-allowed;    }
      
      &--disabled{
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
      }
  
      span {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #495057;
      }
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__popover-wrapper {
    display: block;
  }
  .arrow {
    text-align: center;
    width: 200px;
  }
  
  .arrow---down {
    position: absolute;
  }
  
  .arrow---down:after,
  .arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  .arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 44%;
    margin-left: -10px;
    bottom: -50px;
  }
  
}
