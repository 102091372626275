.no_data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  flex-direction: column;
  height: calc(100vh - 32rem);

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: #6c757d;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6c757d;
  }
}
