.loading {
  &-small {
    &-green {
      height: 1rem;
      width: 1rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.125rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 1rem;
      width: 1rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.125rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }

  &-medium {
    &-green {
      height: 2rem;
      width: 2rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 2rem;
      width: 2rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }

  &-large {
    &-green {
      height: 3rem;
      width: 3rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.375rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 3rem;
      width: 3rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.375rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }
}
