.linkText {
  cursor: pointer;

  &:hover {
    .linkTo {
      text-decoration: underline;
      text-decoration-color: $black;
    }
  }

  &__primary {
    &:hover {
      .linkTo {
        text-decoration: underline;
        text-decoration-color: $green;
      }
    }

    h5 {
      color: $green;
    }
  }
}
