.LayoutTopBarMainApp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #ffffff;
  padding: 1rem 2.5rem;
  margin-left: 7.875rem;
  height: 4.5rem;
  width: 100%;
  top: 0;
  z-index: 999;

  &__left-side {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__left-side-info {
    display: flex;
  }

  &__text-welcome {
    height: 2rem;
    font-size: 1.75rem;
    margin: 0 0.375rem 0 0;
    color: #4d4f5c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    font-family: 'Source Sans Pro', sans-serif;
  }

  &__text-user {
    height: 2rem;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0 0.75rem 0 0;
    color: #4d4f5c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    line-height: 2.5rem;
    font-family: 'Source Sans Pro', sans-serif;
  }

  &__display-photo {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background: #f5f5f5;
    display: flex;
    font-size: 0.625rem;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &__right-side {
    display: flex;
    padding: 1rem 2.5rem;
    margin-right: 7.875rem;
    justify-content: center;
    align-items: center;
  }

  &__action-menu {
    display: flex;
    margin: 0 2.625rem 0 0;
    gap: 1rem;
  }

  &__action-notif {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.375rem;
  }

  &__action-logout {
    padding: 0.125rem 1rem;
    background: white;
    margin: 0 1rem 0 0;

    h5 {
      font-size: 0.875rem;
      margin-right: 0.583125rem;
    }

    &:hover {
      background: #ffcccb;

      .LayoutTopBarMainApp__action-logout-text {
        color: red;
      }

      .LayoutTopBarMainApp__action-logout-icon {
        fill: red;
      }
    }
  }

  &__action-logout-text {
    font-weight: semi bold;
    margin: 0 0.5rem 0 0;
  }

  &__action-logout-icon {
    fill: #4d4f5c;
  }

  &__icon-menu {
    padding: 0.375rem;
    background: none;
    color: #4d4f5c;
    &__active {
      padding: 0.3125rem;
      background: none;
      border: 0.063rem solid #caced5;
      color: #007e75;
    }
    &:hover {
      padding: 0.312rem;
      border: 0.063rem solid #caced5;
      color: #007e75;
    }
  }
}
