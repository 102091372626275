.DatexTelcoStepOneThematic__search {
  padding: 12px;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f3;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);

  .ant-input-group-wrapper {
    background: #ffffff;
    opacity: 0.6;
    border-radius: 4px;
    .ant-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ant-input-group-addon {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .icon-addonAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}

.DatexTelcoStepOneThematic__container {
  margin: 12px 0px;
  display: flex;
  gap: 12px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
  padding: 2px;
  border-radius: 8px;

  .DatexTelcoStepOneThematic__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;
    gap: 8px;
    cursor: pointer;
    border: 3px solid #ffffff;
    border-radius: 8px;
    height: 104px;
    flex-basis: 30%;
    justify-content: center;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.102);


    &--active {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
      color: #495057;
      border: 3px solid #07827c;
    }

    &.disabled {
      cursor: not-allowed;
      filter: grayscale(1);
    }

    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #616477;
    }
  }
}

.DatexTelcoStepOneThematic {
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__popover-wrapper {
    display: block;
  }
  &.arrow {
    text-align: center;
    width: 200px;
  }
  
  &.arrow---down {
    position: absolute;
  }
  
  &.arrow---down:after,
  &.arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  &.arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 44%;
    margin-left: -10px;
    bottom: -50px;
  }
}
