.bg {
  &-default {
    background-color: $color-default;
  }

  &-white {
    background-color: $color-white;
  }

  &-grey {
    background-color: $color-dark-light;
  }
}
