.feature-mainmap {
  &.MainMapLayerVisibilityItem {
    padding: 0.5rem;
    background-color: #f8f8fb;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  .MainMapLayerVisibilityItem {
    &__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.5rem;
      color: #495057;
      margin-bottom: 0.25rem;
    }
    &__subtitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      &-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: .625rem;
        line-height: 1.25rem;
        color: #6C757D;
      }
      &-dot {
        padding: .125rem;
        border-radius: .25rem;
        background-color: #6C757D;
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    &__input {
        -webkit-appearance: none;
        height: 0.25rem;
        outline: none;
        opacity: 0.7;
        border-radius: 999px;
        background: #caced5;
        flex: 80%;

        &::-webkit-slider-thumb {
          appearance: none;
          cursor: pointer;
          width: 0.875rem;
          height: 0.875rem;
          background: #fff;
          border: 1px solid #b1b7c1;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
          border-radius: 99999px;
        }
      }
  }
}
