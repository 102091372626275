.shared {
  &.BaseCard {
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 10px 20px rgba(202, 206, 213, 0.1);
    .BaseCard {
      &__header {
        display: flex;
        flex-direction: row;
        background: #fafcfd;
        padding: 0.6875rem;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &__header-icon {
        margin-right: 0.25rem;
      }
      &__header-title {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: #007e75;
      }
      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &__content--with-padding {
        padding: 0.6875rem;
      }
    }
  }
}
