.auth-gate {
  &.Force-logout {
    &__container {
        width: 100%;
      .ant-modal-body {
        padding: 0;
      }
    }
    &__content {
      display: flex;
      padding: 1.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      align-self: stretch;
      &--img {
        width: 5.56325rem;
        height: 5.56231rem;
        flex-shrink: 0;
      }
      &--title {
        color: #6c757d;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
      }
      &--desc {
        color: #6c757d;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
    &__footer {
      display: flex;
      padding: 0rem 1.5rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
      &--button {
        display: flex;
        flex: 1 0 0;
        padding: 0.3125rem 1rem;
        align-items: center;

        color: #07827c;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }
}
