.new-overview {
  height: 44rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.313rem;
  }
  &__header {
    border-bottom: 0.063rem solid #61647730;
    margin-top: 1rem;
    span {
      line-height: 2rem;
      font-size: 1.5rem;
      color: #657991;
    }
  }

  &__title {
    margin: 1rem 0rem 0rem;

    &--text {
      font-size: 2.75rem;
      line-height: 3rem;
      margin-bottom: 1rem;
      color: #364961;
      width: 10.625rem;
    }
    &--icon {
      width: 6rem;
      height: 6rem;

      &.link-box {
        margin-left: auto;
      }
    }
  }
  &__button {
    box-shadow: 2px 5px 10px 0px rgba(0, 126, 117, 0.75);
    -webkit-box-shadow: 2px 5px 10px 0px rgba(0, 126, 117, 0.75);
    -moz-box-shadow: 2px 5px 10px 0px rgba(0, 126, 117, 0.75);
    margin: auto;
    height: 2.25rem;
    width: 100%;
    position: relative;

    &::before {
      transition: 0.5s all ease;
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      bottom: 0;
      opacity: 0.4;
      background-color: red;
    }
    &:hover {
      cursor: pointer;
    }
    &:hover::before {
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
      z-index: 1;
    }
  }

  &__video {
    position: relative;
    background-color: #f8f8fb;
    margin: 1.5rem 0rem;
    height: 26rem;
    width: 100%;

    &__swiper {
      border-radius: 0.5rem;
      background: #f8f8fb;
    }

    img {
      height: 26rem;
      width: 45rem;
    }

    &__swiper__arrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      bottom: 15rem;
      z-index: 100;
      position: relative;
    }

    &__swiper--prev,
    &__swiper--next {
      background-color: rgba(0, 0, 0, 0.41);
      border-radius: 0.25rem;
      width: 3rem;
      height: 4.625rem;
      padding: 1.5rem 1rem;
      cursor: pointer;

      &.swiper-button-disabled {
        cursor: unset;
      }
    }

    &__swiper__icon--next,
    &__swiper__icon--prev {
      fill: #ffffff;
    }

    &__swiper__icon--prev {
      transform: rotate(90deg);
    }

    &__swiper__icon--next {
      transform: rotate(270deg);
    }
  }

  &__desc {
    font-size: 1.375rem;
    &--content {
      p,
      ul li {
        font-size: 0.875rem !important;
      }
    }
  }
}
