.AsmanDashboardLayout{
  &__wrapper{
    background: #EFEFEF;
    display: flex;
    height: calc(100vh - 3rem);
    margin-top: 3rem;
    overflow: hidden;

  }

  &__body{
    width: 100%;
    background: #EFEFEF;
    overflow: scroll;
    padding-bottom: 2rem;

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #07827C;
      }
    }

    .ant-tabs-tab:hover {
      color: #07827C;
    }

    .ant-table table .ant-table-row {
      box-shadow: none;
    }

    &-header{
      padding: 1rem 2rem;
      background-color: #E2EEF1;
      color: var(--main-bvt-700);
      font-size: 28px;
      font-weight: 600;
      border: 1px solid #BEBEBE;
      margin-bottom: 0.75rem;
    }

    &-result{
      margin: 0.75rem;
    }
  }
}
