.mapp-RecentActivity {
  .RecentActivityTable {
    &__wrapper {
      padding-left: 2rem;
      padding-right: 1rem;
      position: relative;
    }
    .ant-pagination-simple {
      .ant-pagination-simple-pager {
        display: none;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        background: #fff;
        border-radius: 0.25rem;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
        height: 30px;
        .ant-pagination-item-link {
          height: 30px;
        }
      }
    }
    &__table {
      .ant-table {
        margin-bottom: 1rem;
        background: transparent;
        .ant-table-cell::before {
          display: none;
        }
        .ant-table-thead {
          tr {
            th.ant-table-cell {
              padding-bottom: 0.5rem;
              color: #616477;
            }
          }
        }
        .ant-table-row {
          border-radius: 0.5rem;
          overflow: hidden;
          border-spacing: 0 0.625rem;
        }
        .ant-table-footer {
          padding-left: 0px;
          padding-right: 0px;
        }
        .project-type-wrapper {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          background: #c7d4e3;
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;
          .text {
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: left;
            color: #fff;
            z-index: 1;
          }
          .percentage {
            background-color: #477ffd;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 0;
          }
        }
        .btn-wrap {
          display: flex;
          flex-direction: row;
        }
        .btn {
          padding: 0.25rem 0.75rem 0.25rem 0.25rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          text-align: left;
          color: #616477;
          background-color: #fff;
          transition: all ease 0.25s;
          display: flex;
          align-items: center;
          &:not(.btn-edit) {
            margin-right: 0.25rem;
            svg {
              margin-left: 0.25rem;
            }
          }
          
          &.open-button {
            svg {
              color: #657991;
              fill: #657991;
              margin-right: 0.25rem;
            }
            &:hover {
              background-color: #657991;
              color: #fff;
              svg {
                fill: #fff;
                color: #fff;
              }
            }
          }
          &.resume-button {
            svg {
              color: #00a8ff;
              fill: #00a8ff;
              margin-right: 0.25rem;
            }
            &:hover {
              background-color: #00a8ff;
              color: #fff;
              svg {
                fill: #fff;
                color: #fff;
              }
            }
          }
          &.duplicate-button {
            svg {
              color: #24ccb8;
              fill: #007e75;
              margin-right: 0.25rem;
            }
            &:hover {
              background-color: #24ccb8;
              color: #fff;
              svg {
                fill: #fff;
                color: #fff;
              }
            }
          }
          &.create-report-button {
            svg {
              color: #ff6700;
              margin-right: 0.25rem;
              fill: #ff6700;
            }
            &:hover {
              background-color: #ff6700;
              color: #fff;
              svg {
                color: #fff;
                fill: #fff;
              }
            }

            &:disabled {
              cursor: not-allowed;
              color: #D7DAE2;
              svg {
                fill: #D7DAE2;
              }
              &:hover {
                background-color: unset;
                color: #D7DAE2;
                svg {
                  fill: #D7DAE2;
                }
              }
            }
          }
          &.remove-button {
            svg {
              color: #ff3939;
              margin-right: 0.25rem;
              fill: #ff3939;
            }
            &:hover {
              background-color: #ff3939;
              color: #fff;
              svg {
                color: #fff;
                fill: #fff;
              }
            }
          }
          &.btn-edit {
            svg {
              fill: transparent;
              &:hover {
                fill: #00a8ff;
              }
            }
          }
        }
      }
    }
    &.btn-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &.btn {
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      display: flex;
      align-items: center;
      transition: 0.1s linear;
      &.some-white {
        background: #fff;
        border: 1px solid #caced5;
        color: #007e75;
        &:hover {
          box-shadow: 0px 0.25rem 0.5rem #caced5;
        }
        &.ant-dropdown-open {
          svg {
            transition: 0.1s linear;
            transform: rotate(180deg);
          }
        }
        svg {
          transition: 0.1s linear;
          margin-left: 0.4rem;
          margin-right: 0.25rem;
          margin-top: 0.125rem;
          g {
            fill: #007e75;
            path {
              fill: #007e75;
            }
          }
        }
      }
      &.some-green {
        background: #007e75;
        border: 1px solid #007e75;
        color: #fff;
        &:hover {
          box-shadow: 0px 0.25rem 0.5rem rgba(0, 126, 117, 0.4);
        }
        svg {
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          g {
            fill: #fff;
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  &.filter-dropdown-menu {
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 0px 0.5rem 1.25rem rgba(0, 0, 0, 0.2);
    .ant-dropdown-menu-item {
      &.ant-dropdown-menu-item-active {
        .ant-dropdown-menu-title-content {
          div {
            display: flex;
            font-weight: 600;
            align-items: center;
            position: relative;
            flex: 1;
            width: 7.5rem;
            svg {
              position: absolute;
              right: 0;
            }
          }
        }
      }
      &:not(.ant-dropdown-menu-item-active) {
        .ant-dropdown-menu-title-content {
          div {
            svg {
              display: none;
            }
          }
        }
      }
      .ant-dropdown-menu-title-content {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: #4d4f5c;
      }
    }
    .btn-apply {
      border-radius: 0.25rem;
      padding: 0.25rem 2.25rem;
      background: #fff;
      border: 1px solid #caced5;
    }
  }
}

.ant-table {
 .ant-table-thead {
   .ant-table-cell {
     background: none;
     border: none;
     font-weight: bold;
     font-size: 0.875rem;
   }

   .action {
     background-color: aqua;
   }
 }

 .ant-table-tbody {
   .ant-table-cell {
     font-size: 0.875rem;
   }
 }

 table {
   border-collapse: separate;
   border-spacing: 0 0.35rem;
   .ant-table-row {
     background: #ffff;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     font-size: 0.875rem;
   }
 }
 // .ant-pagination-item {
 //   display: none !important;
 // }
}

