// Bagian ini akan di hapus hari Senin (21-02) //
$background-color: #f7f7f7;
$background-color-white: #ffffff;
$background-color-dark: #f0f0f3;
$background-color-dark-light: #f8f8fb;

$color-dark: #000000;
$color-dark-light: #f8f8fb;
$color-danger: #ff4141;
$color-disabled: #caced5;
$color-default: #0b827c;
$color-white: #ffffff;
$color-green: #007e75;
$color-grey: #bfbfbf;

$font-color-dark: #000000;
$font-color-danger: #ff4141;
$font-color-disabled: #caced5;
$font-color-default: #0b827c;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-dark: 900;
// Bagian ini akan di hapus hari Senin (21-02) //

// Color
$green: #007e75;
$green-ultra-light: #fafcfd;
$green-ultra-light-2: #cedee2;
$green-ultra-light-3: #c9d8dc;
$green-ultra-light-4: #e1e9f6;
$green-ultra-light-5: #ccdce0;
$green-mint: #86db05;
$green-tosca: #12b2a7;

$grey: #caced5;
$grey-light: #f8f8fb;
$grey-light-2: #f6f6f9;
$grey-ultra-light: #f0f0f3;
$grey-ultra-light-2: #f2f2f2;
$grey-dark: #364961;
$grey-dark-2: #657991;

$red: #f44e4a;
$red-light: #fee7e7;
$black: #000000;
$white: #ffffff;

// Weight
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
$dark: 900;
