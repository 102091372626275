.icon {
  &__close {
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid $color-disabled;
    padding: 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: $white;

    &--bg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__notif-popover {
    width: 1rem;
    height: 1rem;

    &--bg {
      background-color: $green-tosca;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--mint-green {
      &--bg {
        background-color: $green-mint;
      }
    }
  }

  &__search {
    width: 0.74rem;
    display: flex;
    align-items: center;

    &--active {
      filter: contrast(3); // #fff
    }
  }
}
