.menu-bar__project-name {
  padding: 0.35rem 0.75rem;
  border-radius: 0.25rem;
  gap: 0.25rem;
  display: flex;
  align-items: center;

  svg {
    fill: #caced5;
  }

  &--name {
    font-size: 0.875rem;
    padding: 0rem 0.25rem;

    p {
      margin: 0;
    }
  }
  .features {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    color: #888888;
  }

  .title {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
    color: #101010;
  }

  .features {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    color: #888888;
  }

  .title {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
    color: #101010;
  }

  &--icon {
    fill: #4d4f5c;
  }
}
