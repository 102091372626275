$green: #007e75;

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.ant-popover {
  z-index: 10000;
  padding: 0;
}

.module-bar {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  margin-top: 3rem;
  z-index: 1000;
  width: 100%;

  &__menu {
    position: relative;
    background-color: #eeeef3;
    width: 100%;
    display: flex;
    padding: 0 1.5rem;
    height: 2.5rem;

    &__col {
      display: flex;
      align-items: center;

      &.left {
        justify-content: space-around;

        .ant-divider-vertical {
          height: 1.75rem;
          margin: 0.37rem;
        }
      }
    }

    &__divider {
      font-size: 1.7rem;
      display: flex;
      color: #d7dae2;
      align-items: flex-end;
    }

    &__button {
      background-color: #eeeef3;
      border-radius: 0;
      width: 8rem;
      display: flex;
      justify-content: space-evenly;
      border: 1px solid #eeeef3;
      height: 100%;

      &.opened {
        background-color: $green;

        .module-bar__menu__text {
          color: white;
        }

        &:hover {
          background-color: $green;
          cursor: unset;
        }

        .module-bar__menu__icon {
          fill: white;
        }
      }

      &.inactive {
        display: none;
      }

      &.save {
        justify-content: center;
        cursor: unset;

        .module-bar__menu__text {
          color: #caced5;
        }

        &:hover {
          background-color: #f8f8fb;

          .module-bar__menu__text {
            color: #caced5;
          }
        }
      }

      &:hover {
        background-color: #139289;

        .module-bar__menu__text {
          color: white;
        }

        .module-bar__menu__icon {
          fill: white;
        }
      }
    }

    &__icon {
      fill: #364961;
    }

    &__text {
      font-size: 0.8rem;
      color: #364961;
    }
  }

  &__submenu {
    position: relative;
    width: 100%;
    background-color: #007d74;
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    align-items: center;
    padding: 0 1rem;
    &__right{
      display: flex;
      gap: .5rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      left: 0;
      margin-top: 0rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.swiper-button-disabled {
        .module-bar__submenu__text {
          color: #06534d;
        }
        svg {
          fill: #06534d;
        }
      }

      .module-bar__submenu__text {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      content: '';
    }

    &__list {
      display: flex;
      align-items: center;
      width: 100%;

      .swiper {
        &.module-bar__swiper {
          display: flex;
          align-items: center;
          width: 100%;
          
          .swiper-wrapper {
            position: relative;
            background-color: transparent;
            display: flex;
            height: 2.5rem;
            
            .swiper-slide {
              &.module-bar__submenu__button {
                background-color: transparent;
                border-radius: 0;
                margin: 0rem 0.5rem;
                display: flex;
                align-items: center;
                cursor: unset;
                border: 1px solid transparent;
                pointer-events: none;
                white-space: nowrap;

                &.opened {
                  border: 1px solid white;
                  border-radius: 1rem;
                  background-color: white;
                  padding: 0rem;

                  .module-bar__submenu__text {
                    color: $green;
                    margin: 0rem 0.5rem 0rem 0rem;
                  }

                  &.exit {
                    background-color: #f8f8fb;
                  }
                }

                width: fit-content !important;
                padding-right: 1rem !important;
                padding-left: 1rem !important;

                &.active {
                  cursor: pointer;
                  padding: 0.2rem;
                  margin: 0.35rem;
                  height: fit-content;
                  pointer-events: all;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &:hover {
                    border-color: white;
                    border-radius: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__button {
      background-color: #007d74;
      border-radius: 0;
      margin: 0rem 0.5rem;
      display: flex;
      cursor: unset;
      border: 1px solid #007d74;

      &.close {
        border-radius: 50%;
        cursor: pointer;
        width: 1.75rem;
        height: 1.75rem;

        &:hover {
          border-color: white;
        }
      }

      &--arrow--prev,
      &--arrow--next,
      &--status {
        background-color: #007d74;
        padding: 0rem 0.5rem;
        height: 2.5rem;
        display: flex;
        cursor: unset;
        border: 1px solid #06534d;
        border-radius: 0;
        width: 4.5rem;
      }

      &--status {
        width: 5.5rem;
        cursor: pointer;
        justify-content: space-between;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #14948b;
        }

        .module-bar__submenu__text {
          color: white;
        }
      }

      &.info {
        &:hover {
          border-color: #007d74;
        }
      }

      &.active {
        cursor: pointer;
        padding: 0.2rem;

        &:hover {
          border-color: white;
          border-radius: 1rem;
        }
      }
    }

    &__text {
      font-size: 0.8rem;
      color: #06534d;

      &.active {
        color: white;
      }

      &.info {
        color: #8fc6c2;
        margin-left: 0.5rem;
      }
    }

    &__icon {
      fill: #06534d;
      margin-right: 0.25rem;

      &.active {
        fill: white;
      }

      &.close {
        fill: white;
        margin: 0;
      }

      &--arrow {
        &--prev {
          height: 0.75rem;
          width: 0.75rem;
          transform: rotate(90deg);
          fill: #06534d;
          margin: 0.5rem;
        }

        &--next {
          height: 0.75rem;
          width: 0.75rem;
          transform: rotate(-90deg);
          fill: #06534d;
          margin: 0.5rem;
        }
      }

      &--status {
        fill: white;

        &.rotate-180 {
          transform: rotate(180deg);
        }
      }

      &--wrapper {
        border: 1px solid #f8f8fb;
        border-radius: 1rem;
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .module-bar__submenu__icon {
          margin: 0rem;
        }
      }
    }

    &__selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 1rem;
      background-color: white;
      color: $green;
      font-size: 0.813rem;
      width: fit-content;
      margin: 0 1rem 0 0;

      &--wrapper {
        display: flex;
      }

      @include keyframes(fadeInRightBig) {
        0% {
          transform: translateX(1rem);
          width: fit-content;
        }

        100% {
          transform: translateX(0);
          width: fit-content;
        }
      }

      @include animate(fadeInRightBig, 500ms, linear, 1);

      &__name {
        display: flex;
        align-items: center;
      }

      &__icon {
        fill: $green;
        margin: 0.25rem 0.5rem;
      }

      &__close {
        border-radius: 1rem;
        width: 1.75rem;
        height: 1.75rem;
        background-color: #f8f8fb;
        fill: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    &__breadcrumbs {
      display: flex;
      align-items: center;
    }
  }

  &__info {
    background-color: #007d74;
    border-radius: 0;
    display: flex;
    cursor: unset;
    border: 1px solid #007d74;
    align-items: center;
  }

  &__spinner {
    @keyframes moduleBarSpinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    animation-name: spin;
    animation-duration: 0.25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.processing-list-status {
  margin: 0rem 0rem 0.625rem 0rem;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f2f2f2;
    border-top: none;
    width: 24rem;
    padding: 0.25rem;
    cursor: pointer;
  }

  &__icon {
    margin: 0.5rem;

    &-process {
      display: inline-block;
      position: relative;
      width: rem(50);
      height: rem(50);

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 2rem;
        height: 2rem;
        margin: 8px;
        border: 8px solid #00a8ff;
        border-radius: 50%;
        animation: processing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #00a8ff transparent transparent transparent;
      }

      div:nth-child(1) {
        animation-delay: -0.45s;
      }

      div:nth-child(2) {
        animation-delay: -0.3s;
      }

      div:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes processing {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__detail {
    &-left {
      display: flex;
      align-items: center;
      margin: 0rem 0.5rem 0rem 0rem;
    }

    &-type {
      font-size: 0.875rem;
      color: #364961;
    }

    &-name,
    &-label {
      font-size: 0.75rem;
    }

    &-name {
      color: #657991;
    }

    &-label {
      color: white;
      border-radius: 9px;
      display: flex;
      justify-content: center;

      &.success {
        border: 1px solid #12b2a7;
        background-color: #12b2a7;
      }

      &.processing {
        border: 1px solid #00a8ff;
        background-color: #00a8ff;
      }

      &.failed {
        border: 1px solid #f44e4a;
        background-color: #f44e4a;
      }
    }
  }

  &__action {
    &-date {
      color: #657991;
      font-size: 0.75rem;
    }

    &-btn {
      background-color: white;
      border: 1px solid #caced5;
      border-radius: 4px;
      padding: 0.25rem;
      margin: 0rem 1rem;

      &:hover {
        box-shadow: #caced5 0px 7px 29px 0px;
      }

      &.success {
        &::after {
          content: 'Show';
        }

        color: #12b2a7;
      }

      &.processing {
        &::after {
          content: 'Cancel';
        }

        color: #00a8ff;
      }

      &.failed {
        &::after {
          content: 'Delete';
        }

        color: #f44e4a;
      }

      &.success::after,
      &.processing::after,
      &.failed::after {
        font-size: 0.75rem;
      }
    }
  }
}

.empty-list {
  margin: 0rem 0rem 0.625rem 0rem;
  width: 24rem;
}
