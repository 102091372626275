$color-tags: #ff9999, #37cf93, #32b3c1, #4593f2, #7b94ed, #ae78f1, #e253fa,
  #a17db1, #57ac61, #73c250, #d1e24b, #e0c858, #e7966e, #ee6485, #f62c9e,
  #a81869, #ff6700, #4880ff, #07827c, #ffca28, #24ccb8;

.link-box {
  cursor: pointer;
  height: auto;
  width: auto;
  position: relative;
  transition: all 0.25s;
  top: 0;

  &:hover .image-link-box-card__blue,
  &:hover .image-link-box-card__orange,
  &:hover .image-link-box-card__tosca,
  &:hover .image-link-box-card__yellow,
  &:hover .image-link-box-card__tosca-light,
  &:hover .image-link-box-card {
    top: -0.45rem;
    margin-top: 0;
    transition: all 0.25s;
  }

  &--landscape {
    width: 100%;
    display: flex;
    height: 100%;
  }
  .image-link-box-card {
    &--landscape {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      text-align: left;
      justify-content: flex-start;
      img,
      svg {
        width: 3.25em;
        height: 3.25em;
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
    }

    position: relative;
    top: 0;
    line-height: 0;
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 5%);

    &__orange {
      position: relative;
      top: 0;
      line-height: 0;
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: #ff6700;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(255, 103, 0, 0.35);
      -moz-box-shadow: 0px 0px 20px 0px rgba(255, 103, 0, 0.35);

      &.link-box--disabled {
        background-color: #c4c4c4;
        -webkit-box-shadow: 0px 0px 20px 0px #c4c4c480;
        -moz-box-shadow: 0px 0px 20px 0px #c4c4c480;
        box-shadow: 0px 0px 20px 0px #c4c4c480;
      }
    }
    &__blue {
      position: relative;
      top: 0;
      line-height: 0;
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: #4880ff;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(72, 128, 255, 0.35);
      -moz-box-shadow: 0px 0px 20px 0px rgba(72, 128, 255, 0.35);
      box-shadow: 0px 0px 20px 0px rgba(72, 128, 255, 0.35);

      &.link-box--disabled {
        background-color: #c4c4c4;
        -webkit-box-shadow: 0px 0px 20px 0px #c4c4c480;
        -moz-box-shadow: 0px 0px 20px 0px #c4c4c480;
        box-shadow: 0px 0px 20px 0px #c4c4c480;
      }
    }
    &__tosca {
      position: relative;
      top: 0;
      line-height: 0;
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: #07827c;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(7, 130, 124, 0.35);
      -moz-box-shadow: 0px 0px 20px 0px rgba(7, 130, 124, 0.35);
      box-shadow: 0px 0px 20px 0px rgba(7, 130, 124, 0.35);

      &.link-box--disabled {
        background-color: #c4c4c4;
        -webkit-box-shadow: 0px 0px 20px 0px #c4c4c480;
        -moz-box-shadow: 0px 0px 20px 0px #c4c4c480;
        box-shadow: 0px 0px 20px 0px #c4c4c480;
      }
    }
    &__yellow {
      position: relative;
      top: 0;
      line-height: 0;
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: #ffca28;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(255, 202, 40, 0.35);
      -moz-box-shadow: 0px 0px 20px 0px rgba(255, 202, 40, 0.35);
      box-shadow: 0px 0px 20px 0px rgba(255, 202, 40, 0.35);

      &.link-box--disabled {
        background-color: #c4c4c4;
        -webkit-box-shadow: 0px 0px 20px 0px #c4c4c480;
        -moz-box-shadow: 0px 0px 20px 0px #c4c4c480;
        box-shadow: 0px 0px 20px 0px #c4c4c480;
      }
    }
    &__tosca-light {
      position: relative;
      top: 0;
      line-height: 0;
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: #24ccb8;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(36, 204, 184, 0.35);
      -moz-box-shadow: 0px 0px 20px 0px rgba(36, 204, 184, 0.35);
      box-shadow: 0px 0px 20px 0px rgba(36, 204, 184, 0.35);

      &.link-box--disabled {
        background-color: #c4c4c4;
        -webkit-box-shadow: 0px 0px 20px 0px #c4c4c480;
        -moz-box-shadow: 0px 0px 20px 0px #c4c4c480;
        box-shadow: 0px 0px 20px 0px #c4c4c480;
      }
    }
  }

  .link-text-skeleton {
    position: absolute;
    top: 6rem;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  &--disabled {
    opacity: .3;
    cursor: not-allowed;
    filter: grayscale(2);
    transition: unset !important;
    &:hover {
      top: 0rem !important;
      transition: unset !important;
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__popover-wrapper {
    display: block;
  }

  &.arrow {
    text-align: center;
    width: 200px;
  }
  
  &.arrow---down {
    position: absolute;
  }
  
  &.arrow---down:after,
  &.arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  &.arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 45%;
    margin-left: -10px;
    bottom: -50px;
  }

}

.image-link-box-loading {
  margin-top: 0.5rem;
}
.image-link-box-text {
  color: black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.875rem;
  margin-top: 0.625rem;
  width: 5.75rem;
  text-align: center;
  line-height: normal;
  position: relative;
  &--landscape {
    word-break: break-word;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1rem;
    &.hasColor {
      color: white;
    }
  }
  &.disabled{
    &:hover {
      top: 0 !important;
    }
  }
}

// @mixin generate-box-color() {
//   @each $color-tag in $color-tags {
//     .image-link-box-card #{$color-tag} {
//       background-color: $color-tag;
//     }
//   }
// }
// @include generate-box-color();
