$base-color: #007d74;

.bvt-mapsearch {
  top: 7.5rem;
  z-index: 1099;
  position: fixed;
  right: 0.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 1rem;
  transition: 0.25s ease-in-out;

  &__card {
    display: flex;
    background: rgba(255, 255, 255, 0.45);
    padding: 0.35rem 0.35rem 0.35rem 0.35rem;
    border-radius: 0.25rem;
    margin: 1rem 0.5rem 0 0;
    box-shadow: rgb(99 99 99 / 20%) 0px 0.125rem 0.5rem 0px;
    z-index: 2000;

    .close-advanced {
      margin: 0.35rem 0 0 -1.6rem;
      background: #fff;
      color: #4d4f5c;
      width: 0.9rem;
    }
    .search {
      color: #4d4f5c;
      width: 0.9rem;
    }
  }

  &__input[type='text'] {
    transition: width 0.4s ease-in-out;
    width: 16rem;
    box-sizing: border-box;
    border-radius: 0.25rem;
    border: 1px solid #caced5;
    padding: 0.15rem 0.75rem;
    caret-color: $base-color;

    &:focus {
      width: 18rem !important;
      border: 1px solid $base-color;
    }

    &::placeholder {
      color: #b1b7c1;
    }
  }

  &__autocomplete {
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border: 1px solid $base-color !important;
      box-shadow: 0 0 0 2px rgba(4, 34, 14, 0.2);
    }
    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
  }

  &__list {
    top: 3.5rem;
    cursor: pointer;
    position: absolute;
    padding: 0.5rem 1rem 1rem 1rem;
    width: 18rem;
    background: #fff;
    border-radius: 0.5rem 0 0 0.5rem;
    max-height: 12rem;
    overflow-y: auto;

    &:active {
      overflow-y: none;
    }

    &__item {
      &:hover {
        background: #007d74;
        color: #fff;
        border-radius: 0.25rem;
        padding: 0 0.25rem;
      }
    }

    &__notfound {
      text-align: center;
    }
  }

  &__button {
    background: #fff;
    margin: 0 0 0 0.5rem;
    border-radius: 0.25rem;
    padding: 0.15rem 0.75rem;
    color: #007e75;
    &:disabled {
      opacity: 0.5;
    }
  }

  &__select {
    margin: -1px 0 0 0.25rem;
    width: 6rem;
    height: 2rem;
    transition: all ease-in-out 0.5s;
    .ant-select-selector {
      border-radius: 4px !important;
      padding: 0 0.875rem;
      height: 2.096rem !important;

    }
    .ant-select-selector:hover, .ant-select-selector:focus-within {
      border: 1px solid $base-color !important;
      box-shadow: 0 0 0 2px rgba(4, 34, 14, 0.2) !important;
    }
    .ant-select-selection-item {
      color: #6c757d;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem !important; /* 142.857% */
    }
    &.search-poi {
      margin: -1px 0 0 0.5rem;
    }
  }
  .icon-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
