.LayoutSideBarMainApp {
    position: fixed;
    width: 126px;
    top: 0;
    z-index: 1;

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 7.875rem;
        height: 4.5rem;
        padding: 1.35rem .375rem;
        gap: 8px;
        background: #ffffff;
    }

    &__container_menu_list {
        overflow-y: scroll;
        overflow-x: hidden;
        position: fixed;
        height: calc(100% - 4.5rem);
        background: #f8f8fb;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__menu-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 4px;
        margin-top: 24px;
        position: relative;
    }

    &__menu-nav-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: .625rem;
        margin-right: .625rem;
        width: 106px;
        height: 72px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.25s cubic-bezier(.5, 0, .5, 1);
        border: 1px solid transparent;
    }

    &__menu-nav-item:hover {
        background-color: #f0f0f3;
        transition: all 0.25s cubic-bezier(.5, 0, .5, 1);
    }

    &__menu-nav-item-active {
        box-sizing: border-box;
        background: #f0f0f3;
        border: 1px solid #ced4da;
        border-radius: 8px;
        transition: all 0.25s cubic-bezier(.5, 0, .5, 1);
    }

    &__menu-nav-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__menu-nav-label {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__text-version {
        display: flex;
        margin-bottom: 0.825rem;
        margin-left: 0.825rem;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6C757D;
    }

    &__active-indicator {
        position: absolute;
        width: 8px;
        height: 24px;
        left: 6px;
        z-index: 9999;
        border-radius: 8px;
        background: #07827C;
        transform: translateY(24px);
        transition: all 0.25s cubic-bezier(.5, 0, .5, 1.5);
    }
}