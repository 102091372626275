.feature-auth {
  &.ResetPasswordForm {
    .ant-alert.ant-alert-error {
      background: #fee7e7;
      border: 1px solid #f44e4a;
      margin-bottom: 1rem;
      .ant-alert-message {
        color: #f44e4a;
        border-radius: 0.25rem;
      }
    }
    &__heading {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.625rem;
      text-align: left;
      color: #007e75;
      width: 400px;
      padding: 1rem 1.5rem;
      background: #fafcfd;
      margin-bottom: 0.75rem;
    }
    &__container {
      padding: 1.5rem;
    }
    &__reset-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.625rem;
    }
    &__reset-title {
      display: flex;
      flex-direction: column;
      .title {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.25rem;
        line-height: 1.25rem;
        text-align: left;
        color: #000;
        margin-bottom: 0.5rem;
      }
      .description {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: #000;
      }
    }
    &__reset-lottie {
      margin-left: auto;
      // width: 6.375rem;
      width: 6.375rem;
      height: 3.875rem;
      transform: scale(2);
    }
    .pwd-bar {
      margin-bottom: 0.5rem;
    }
    &__password-warner {
      border-radius: 0.25rem;
      padding: 0.5rem;
      background: #f8f8fb;
      margin-bottom: 2.625rem;
      color: #caced5;
      .ant-alert-close-icon{
        .anticon.anticon-close{
          color: #f44e4a;

        }
      }
      &.has-error {
        background: #fee7e7;
        border: 1px solid #f44e4a;
        color: #f44e4a;
      }
    }
    &__bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__submit-button {
      padding: 0.2rem 1.5rem;
      border-radius: 0.25rem;
      background: #007e75;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #fff;
      flex: 0;
      transition: 0.25s ease-in-out;
      align-items: center;
      word-wrap: normal;

      &.back-to-login {
        background: #fff;
        border: 1px solid #caced5;
        color: #007e75;
      }

      svg {
        animation-name: button-svg;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      &--not-valid {
        color: #caced5;
        background: #f8f8fb;
        cursor: not-allowed;
      }
      &--loading {
        background: #007e75;
        cursor: not-allowed;
      }
    }
  }
}
