.setup-poi-selector {
  position: relative;
  border: 1px solid #c9d8dc;
  border-radius: 0.5rem;
  background-color: #f8f8fb;
  width: 100%;
  margin-right: 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-rows: 7% 86% 7%;

  .box-skeleton {
    border-radius: 0.45rem;
    margin: 0 0.8rem;
  }

  &__info {
    display: flex;
    align-items: center;
    background: #ffff;
    gap: 2rem;
    &__bold {
      font-weight: 600;
    }
  }

  .picture-ads {
    margin: 3rem;
  }

  &-none {
    .setup-poi-selector__body__content__header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background-color: #ffffff;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom: 1px solid #c9d8dc;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: #ffffff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #c9d8dc;

    &__search-box {
      margin: auto;
    }

    &__info {
      color: #caced5;
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;

        &:hover {
          color: #ffd500;
        }
      }
    }

    &__popover-wrapper {
      background-color: #fff3b7;
      border-color: #fff3b7;
    }

    &__popover-anchor {
      position: absolute;
      background-color: #ffd500;
    }

    &__popover-container {
      display: flex;
      padding: 0.25rem;

      &-active {
        padding: 0.25rem;
        display: flex;
        background-color: #fff3b7;
      }
    }

    &__popover-content {
      padding: 0.25rem;

      p {
        margin: 0;
        font-size: 0.625rem;
        color: #4d4f5c;
      }
    }

    &__popover-icon {
      fill: #caced5;

      &-active {
        fill: #ffd500;
      }
    }

    &__popover-icon:hover {
      fill: #ffd500;
    }

    &__button {
      border-radius: 0.25rem;
      background-color: #f8f8fb;
      color: #007e75;
      padding: 0.125rem;
      width: 4.5rem;
      border: 1px solid #caced5;

      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }
  }

  &__body {
    overflow: hidden;

    &__sweep {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      margin: 0.5rem;
      display: flex;

      .poi-category-swipe {
        display: flex;
        width: 100%;
      }
    }

    &__content {
      background-color: #ffffff;
      border-radius: 0.5rem;
      border: 1px solid #c9d8dc;
      margin: 0.5rem;

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        background-color: #f0f0f3;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom: 1px solid #c9d8dc;
      }

      &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background-color: #f8f8fb;

        p {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
        }

        .ant-col {
          margin: 0.25rem;
        }
      }

      &__body__col {
        .ColorSelectedCard__container {
          width: 6rem;
        }
      }

      &__body__sub_kategori {
        display: flex;
        min-height: 15rem;
        max-height: 18rem;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: scroll;
      }

      &__body__brand {
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background-color: #f8f8f8;
        min-height: 15rem;
        overflow-y: scroll;
        max-height: 18rem;

        &__wrapper {
          width: 100%;
        }

        .selectedBox {
          display: flex;
          flex-wrap: wrap;

          .selectedBox__default-box {
            margin: 0.25rem 0.5rem;
          }
        }
      }

      &__footer {
        flex-direction: row;
        bottom: 0;
        position: sticky;
        background-color: #f8f8f8;
        height: 2.5rem;
        display: flex;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-top: 1px solid #c9d8dc;
        justify-content: space-between;
        padding: 0.5rem;
      }

      &__back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        border-radius: 0.5rem;
        width: 27rem;
        height: 2.5rem;
        padding: 0.25rem 0.625rem;
        position: relative;

        &__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f8f8fb;
          padding: 0.5rem;
          border-bottom: 1px solid #c9d8dc;
        }

        &-arrow {
          transform: rotate(180deg);
        }

        &-label {
          position: relative;
          padding-left: 4rem;

          &--bubble {
            z-index: 1;
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            left: 5%;
            transform: translate(-50%, -0.75rem);
            top: 40%;
            border-radius: 99999px;
            display: flex;
            align-items: center;
            justify-content: center;

            img,
            svg {
              width: 1rem;
              height: 1rem;
            }

            &:before {
              content: '';
              position: absolute;
              z-index: 1;
              left: 50%;

              transform: translate(-50%, -0.6rem);
              top: 1.425rem;
              width: 0;
              height: 0;
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-top: 0.5rem solid var(--ColorSelectedCardColor);
              opacity: 0.7;
            }
          }

          color: #657991;
          width: 70%;
        }

        &-count {
          color: #657991;
          width: 15%;
        }

        &-text {
          width: 15%;
          color: #657991;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
          cursor: pointer;

          svg {
            fill: #657991;
          }
        }
      }
    }
  }

  &__footer {
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 1px solid #c9d8dc;

    &__button {
      border-radius: 0.25rem;
      background-color: #f8f8fb;
      color: #caced5;
      padding: 0.25rem;
      width: auto;
      border: 1px solid #f8f8fb;

      &.active:nth-child(1) {
        background-color: #007e75;
        color: #ffffff;
        border-color: #007e75;
      }

      &.active:nth-child(2) {
        color: #ffffff;
        background-color: #007e75;
        border-color: #007e75;
      }
    }
  }

  &__page-desc {
    color: #616477;
  }

  &__page-list {

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: white;
      border-radius: 0.25rem;
      box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    }

    .ant-pagination-item {
      background-color: #ffffff;

      a {
        color: #007e75;
      }
    }

    .ant-pagination-item-active {
      background-color: #007e75;

      a {
        color: #ffffff;
      }
    }
  }

  .image-link-box-card,
  .poi-category-swipe .link-box {
    width: 4.5rem;
    height: 4.5rem;
  }

}