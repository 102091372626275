.BaseOptionListItemThematic {
    .ant-radio-group.BaseOptionListItemThematic__radio-group {
      width: 100%;
      display: flex;
      padding: 0;
      .BaseOptionListItemThematic__radio-group--space {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 0.75rem;
        gap: 0.75rem;
        align-items: center;
        justify-self: center;
        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
          border-color: #07827c !important;
        }
        .BaseOptionListItemThematic__radio--wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .ant-radio-wrapper.BaseOptionListItemThematic__radio--item {
          display: flex;
          width: 100%;
          height: 3rem;
          align-items: center;
          background: #f4f4f7;
          border: 1px solid #f4f4f7;
          border-radius: 6px;
          padding: 0.625rem 0.625rem 0.625rem 1.25rem;
          gap: 0.875rem;
          justify-content: flex-start;
  
          &.ant-radio-wrapper {
            margin: 0;
  
            &::after {
              display: none;
            }
          }
          &.checked {
            border-color: #07827c;
          }
          .ant-radio {
            top: 0;
            .ant-radio-inner {
              width: 16px;
            }
            .ant-radio-inner::after {
              background-color: #07827c;
            }
          }
          span {
            padding-left: 0;
            padding-right: 0;
          }
          span.ant-radio {
            width: 16px;
            .ant-radio-input {
              width: 16px;
              height: 16px;
            }
          }
          span:last-child {
            width: 100%;
          }
          .BaseOptionListItemThematic__radio-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            .BaseOptionListItemThematic__radio-wrapper--left,
            .BaseOptionListItemThematic__radio-wrapper--right {
              display: flex;
              align-items: center;
            }
            .BaseOptionListItemThematic__radio-wrapper--left {
              gap: 0.875rem;
              .BaseOptionListItemThematic__radio-item--icon {
                display: flex;
                img {
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
              .BaseOptionListItemThematic__radio-item--title {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Source Sans Pro', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.25rem;
                display: flex;
                align-items: center;
                color: #1d1e31;
              }
            }
            .BaseOptionListItemThematic__radio-wrapper--right {
              gap: 0.5rem;
              .BaseOptionListItemThematic__radio-item--total {
                font-family: 'Source Sans Pro', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.5rem;
                display: flex;
                align-items: center;
                color: #20a49d;
              }
              .BaseOptionListItemThematic__radio-item--action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0.125rem 0.5rem;
                gap: 0.625rem;
                border: 1px solid #ced4da;
                border-radius: 4px;
                flex: none;
                flex-grow: 0;
                font-family: 'Source Sans Pro', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: #07827c;
                background: #f9fbfc;
                &:disabled {
                  color: #ced4da;
                  pointer-events: none;
                  background: #f9fbfc;
                }
              }
            }
          }
        }
      }
    }
    &.container-popover{
      height: 30px;
      align-items: center;
      background-color: black;
      color: white;
      box-shadow: none !important;
      border-radius: 8px;
      &-text {
        padding: 6px 12px 0;
        font-family: sans-serif, 'Source Sans Pro';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &__group {
      &--wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        width: 100%;
      }
      &--title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.625rem;
        color: #000;
      }
    }
  
    &__popover-wrapper {
      display: block;
    }

    &.arrow {
      text-align: center;
      width: 200px;
    }
    
    &.arrow---down {
      position: absolute;
    }
    
    &.arrow---down:after,
    &.arrow---down:before {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border: solid transparent;
      pointer-events: none;
    }
    
    &.arrow---down:after {
      border-top-color: #000;
      border-width: 10px;
      left: 15%;
      margin-left: -10px;
      bottom: -50px;
    }
    
  }
  