.PertaminaTableRecomendation {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .title {
    color: #101010;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .table {
    .ant-table-thead {
      background-color: #f7f7f7;
      color: #616161;
    }
    .ant-table-thead > tr > th {
      padding: 0.62rem 0.75rem;
      border-bottom: 1px solid #f7f7f7;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    }
    .ant-table-tbody {
      tr {
        padding: 0;
        box-shadow: none;
        td {
          text-align: left;
          box-shadow: none;
        }
      }
    }
    .ant-table table {
      border-spacing: 0;
    }
    .ant-table {
      border: 1px solid #f7f7f7;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .notes {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.25rem;
    background: #fff0b0;
    .description {
      flex: 1 ;
      color: #616161;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    }
    .list {
        display: block;
        list-style-type: decimal;
        padding-left: 0.5rem;
    }
  }
}
