.feature-datex-telco {
  &.CardListLayerDatex {
    &__container {
      background: #FFF;
      border-radius: 0.25rem;
      border: 1px solid #CED4DA;
      width: 100%;
    }
    &__list {
      display: flex;
      gap: 1rem;
      padding: 0.38rem 0.75rem;
      &--color {
        width: 1rem;
        height: 1rem;
        border-radius: 0.25rem;
      }
      &--text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #07827c;
      }
    }
  }
}
