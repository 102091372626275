.text {
  // Color
  &-default {
    color: $green;
  }

  &-disabled {
    color: $grey;
  }

  &-grey {
    color: $grey;
  }

  &-white {
    color: $white;
  }

  // Weight
  &-light {
    font-weight: $light;
  }

  &-regular {
    font-weight: $regular;
  }

  &-semi-bold {
    font-weight: $semi-bold;
  }

  &-bold {
    font-weight: $bold;
  }

  &-dark {
    font-weight: $dark;
  }

  // Center
  &-center {
    text-align: center;
  }
}
