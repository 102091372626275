.AccountPhoto {
  box-sizing: border-box;
  left: 1rem;
  top: 2.75rem;
  background: #1d5271;
  border: 1px solid #1c1a1a;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.375rem 2rem;
  &--item {
    width: 7.5rem;
    height: 7.5rem;
    position: absolute;
    border: 1px solid #1c1a1a;
    border-radius: 50%;
    &__photo {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
    }
  }
}
