.feature-mainmap{
    &.MainMapRightSideTopBar{
        display: flex;
        padding: 0.5rem;
        background-color: #f8f8fb;
    }
    .MainMapRightSideTopBar{
        &__btn-close{
            background: transparent;
            padding: 0.5rem;
        }
        &__btn-group{
            background-color: #ebebf1;
            display: flex;
            padding: 0.25rem;
            flex-grow: 1;
            border-radius: 0.25rem;
            margin-right: 0.5rem;
        }
        &__btn{
            flex-grow: 1;
            padding: 0.25rem;
            font-family: 'Source Sans Pro',sans-serif;
            color: #6C757D;
            span{
                font-family: 'Source Sans Pro',sans-serif;
                font-size: 0.875rem;
            }
            :nth-child(1){
                margin-right: 0.5rem;
            }
            &--active{
                background-color: #fff;
                color: #007d74;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
            }
        }
    }

}