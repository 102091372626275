.BaseSelectedPOICategoryCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0.75rem;
  gap: 0.5rem;
  isolation: isolate;
  width: 100%;
  background: #f8f8fb;
  box-shadow: 0px 0.3125rem 0.625rem rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;
  overflow-x: hidden;

  .BaseSelectedPOICategoryCard__header {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6c757d;
    width: 100%;
  }

  .BaseSelectedPOICategoryCard__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 0.5rem;
    isolation: isolate;
    width: 100%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;

    .BaseSelectedPOICategoryCard__icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 2rem;
        height: 2rem;
      }
    }

    .BaseSelectedPOICategoryCard__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      flex: none;
      order: 1;
      flex-grow: 0;
      z-index: 0;
      .BaseSelectedPOICategoryCard__text--title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.625rem;
        display: flex;
        align-items: center;
        color: #6c757d;
      }
      .BaseSelectedPOICategoryCard__text--description {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: #12b2a7;
      }
      .BaseSelectedPOICategoryCard__text--action {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: #12b2a7;
      }
      .BaseSelectedPOICategoryCard__text--action-item {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #12b2a7;
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }

  .BaseSelectedPOICategoryCard__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 0.5rem;
    width: 100%;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.3125rem 0.25rem;
      gap: 0.625rem;
      width: 3.875rem;
      height: 2.25rem;
      border-radius: 0.25rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #07827c;
    }
  }
}
