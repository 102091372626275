.DatexPOIMap {
  &__popup {
    padding: 10px 20px;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: .5rem;
      margin-bottom: .25rem;
      .icon {
        width: 36px;
        height: 36px;
      }
      .wrapper-title {
        .title {
          width: 90%;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
        .subtitle {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #A0A6A9;
        }
      }
    }
    .body {
      margin-top: 1rem;
      .detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &--text {
          max-width: 7.5rem;
          word-wrap: break-word;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #495057;
          text-align: end;
        }
      }
      .divider {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important;
      }
    }
  }
}