.AsmanCardPropertyPriceComponent {
	&__content {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		margin-top: 1rem;
		border: 1px solid #caced5;
		border-radius: 4px;

		table {
			width: 100%;
			border-collapse: collapse;
			thead {
				tr {
					background-color: #fafafa;
					border-bottom: #caced5 1px solid;
					th {
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #1d1e31;
						text-align: left;
						padding: 1.125rem 0.25rem;
					}
				}
			}
		}
	}
	&__modal {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 0.875rem;
		color: #4d4f5c;

		.ant-modal-content {
			position: relative;
			background-color: #ffffff;
			background-clip: padding-box;
			border: 0;
			border-radius: 10px;
			pointer-events: auto;
			padding: 20px 24px;
		}
		.ant-modal-header {
			height: auto;
			color: rgba(0, 0, 0, 0.88);
			background: #ffffff;
			border-radius: 10px 10px 0 0;
			border: none;
			padding: 0;
		}
		.ant-modal-footer {
			border: none;
		}
		.ant-modal-body{
			max-height: 25.75rem;
			overflow-y: auto;
			padding: 0;
		}
		
	}
	&__modal-title {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
		color: #1d1e31;
		margin-bottom: 1.625rem;
	}
	&__modal-list {
		border: 1px solid #caced5;
		padding: 0.5rem;
		border-radius: 0.25rem;
		margin-bottom: 0.5rem;
	}
	&__modal-list-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	&__modal-list-address {
		margin-bottom: 0.5rem;
	}
	&__text {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #616477;
		text-align: left;
		padding: 1.625rem 0.5rem;
	}
	&__line {
		width: 1px;
		height: 1.25rem;
		background-color: #caced5;
		margin-right: 4px;
	}
	&__row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__grow {
		flex-grow: 1;
	}
	&__unit {
		padding-right: 2rem;
	}
}
