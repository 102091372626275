.AsmanCardProfessionChartComponent {
  &__wrapper {
    border: 1px solid #BEBEBE;
    display: flex;
    margin-top: 0.75rem;

    &--chart {
      width: 60%;
      padding: 1rem;

      &-title {
        color: #474747;
        font-weight: 600;
        margin-bottom: 2rem;

      }
    }

    &--others {
      width: 40%;
      border-left: 1px solid #BEBEBE;

      &-header, &-item {
        display: flex;
        justify-content: space-between;
        color: #474747;
        font-weight: 600;
        padding: 1rem;
        border-bottom: 1px solid #BEBEBE;
        align-items: center;
      }

      &-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}
