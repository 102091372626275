.AsmanCardRadiusInfoComponent {
	&__list {
		border: 1px solid #bebebe;
		border-radius: 4px;
	}
	&__title-accent {
		width: 1rem;
		height: 1rem;
		background: rgba(123, 232, 168, 0.3);
		border: 1px solid #07827c;
		border-radius: 4px;
		display: block;
		margin-left: 0.5rem;
	}

	&__list--item {
		color: #696969;
		font-weight: 600;
		display: flex;
		align-items: center;
		padding: 0.5rem;
		border-bottom: 1px solid #bebebe;

		&-title {
			width: 30%;
		}

		&-value {
			display: flex;
			align-items: center;
			width: 70%;
		}

		&-icon {
			margin-right: 1rem;
		}
	}

	&__list--item:last-child {
		border-bottom: none;
	}
	&__list--item:nth-of-type(even) {
		background: #f5f5f5;
	}
}
