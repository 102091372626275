.request-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: auto;
  margin: 0;
}

.request-popup .leaflet-popup-close-button {
  display: none;
}

.datex-popup-circle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 16.25rem;

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0.625rem;
    gap: 0.625rem;

    &-close {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &-button {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 1;
        margin-left: auto;
        padding: 0.25rem;
        border-radius: 0.25rem;
        background: #fff;
        border: 0.063rem solid #caced5;
        cursor: pointer;

        > svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    &-area-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem 0.75rem;
      gap: 0.5rem;

      background: #F9FBFC;
      border-radius: 0.375rem 0.375rem 0 0;

      &-text-bold {
        font-family: sans-serif, 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        color: #6C757D;
        padding: 0.125rem 0.25rem;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        &-title {
          display: flex;
          height: 1.25rem;
          align-items: center;
          font-family: sans-serif, 'Source Sans Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 0.875em;
          line-height: 1.25em;
          color: #6C757D;
        }

        &-sub-title {
          display: flex;
          height: 1rem;
          align-items: center;
          font-family: sans-serif, 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 0.75em;
          line-height: 1em;
          color: #6C757D;
        }
      }
    }

    &-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 0.75rem;

      &-column {
        display: flex;
        flex-direction: column;
        gap: 0.563rem;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
      }

      &-text {
        font-family: sans-serif, 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #6C757D;
      }

      &-text-bold {
        font-family: sans-serif, 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        color: #495057;
      }
    }
  }
}

