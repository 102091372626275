.feature-datex {
  &.DatexStepTelcoDataVisualization {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    .DatexStepTelcoDataVisualization {
      &__heading-wrapper {
        background-color: #f0f0f3;
        padding: 0.5rem 0.75rem;
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.25rem;
      }
      &__info-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #6c757d;
      }
      &__info-icon {
        color: #a0a6a9;
        display: flex;
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
      &__middle {
        display: flex;
        flex-direction: column;
        background-color: #f0f0f3;
        padding: 0.75rem;
        border-radius: 0.25rem;
      }
      &__btn-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 0.5rem;
      }
      &__btn {
        &:nth-child(1) {
          margin-right: 0.5rem;
        }
        flex-grow: 1;
        border: 1px solid #ced4da;
        padding: 0.375rem 1rem;
        border-radius: 0.25rem;
        color: #07827c;
        background-color: #fff;
        transition: 0.25s ease-in-out;
        &:disabled {
          color: #ced4da;
          pointer-events: none;
          background-color: #f0f0f3;
        }
        &--active {
          color: #f0f0f3;
          background-color: #07827c;
          border-color: #07827c;
          .DatexStepTelcoDataVisualization__btn-checkbox {
            border-color: #07827c;
            svg {
              display: block;
            }
          }
        }
      }
      &__btn-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: inherit;
      }
      &__btn-checkbox {
        transition: 0.25s ease-in-out;
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
        border: 1px solid #ced4da;
        background-color: transparent;
        border-radius: 0.25rem;
        svg {
          display: none;
          width: 0.875rem;
          height: 0.875rem;
        }
      }
      &__map-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
        border: 1px solid #ced4da;
        border-radius: 0.5rem;
      }
      &__map-text {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #6c757d;
        z-index: 1024;
      }
      &__map-bg{
        width: 100%;
        max-width: 100%;
        height: auto;
      }
      &__map-overlay{
        left: 0;
        top: 0;
        position: absolute;
      }
    }
  }
}
