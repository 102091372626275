.feature-auth {
  &.AuthForgotMessage {
    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: auto;
    }
    &__status-text {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.25rem;
      color: #000;
      z-index: 100;
    }
    &__status-text-2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.25rem;
      color: #000;
      z-index: 100;
      margin-top: 18rem;
    }
    &__bottom-container {
      padding: 1.5rem;
      .recovery-m,
      .recovery-m2,
      .recovery-mFail {
        font-size: 0.875rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
      }
      .recovery-m2 {
        color: #657991;
      }
      .recovery-mFail {
        color: red;
      }
      .recovery-reset {
        font-size: 0.875rem;
        text-align: center;
        color: #657991;
        span {
          font-size: 0.875rem;
          color: #007e75;
          font-weight: 600;
        }
      }
    }
  }
}
