.rst-pwd-pg {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;

  &__li-logo {
    height: 2.5rem;
  }

  &__version {
    color: $color-disabled;
  }

  &__bvt {
    display: flex;
    flex-direction: column;
    justify-self: start;

    &-logo {
      height: 2rem;
    }

    span {
      font-size: 0.55rem;
    }
  }
}
