button {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  // Size
  &__small {
    height: 1.25rem;
    width: 4rem;
  }

  &__medium {
    padding: 0.25rem 0.875rem;
  }

  &__large {
    height: 1.75rem;
    width: 6rem;
  }

  // Variant
  &__contained {
    background-color: $green;

    &--hover {
      background-color: $green;
      color: $white;

      &:hover {
        box-shadow: 0 4px 14px -5px $green;
      }
    }
  }

  &__outlined {
    background-color: $white;
    border: 1px solid $grey;
    color: $green;

    &:hover {
      box-shadow: 0 4px 14px -5px $grey;
    }
  }

  &__disabled {
    background-color: $grey-light;
    border: 1px solid $grey-light;
    cursor: default;

    &:hover {
      background-color: $grey-light;
      border: 1px solid $grey-light;
    }
  }
}

.loading {
  pointer-events: none;
  border-radius: 50%;
  border: 0.125rem solid transparent;
  animation: loader 1s ease infinite;
  height: 0.5rem;
  width: 0.5rem;
  border-top-color: $grey;

  &__size {
    &--small {
      height: 1rem;
      width: 1rem;
    }
    &--medium {
      height: 2rem;
      width: 2rem;
    }
  }

  &__color {
    &--green {
      border-top-color: $green;
    }
    &--white {
      border-top-color: $white;
    }
  }

  &-small {
    &-green {
      height: 1rem;
      width: 1rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.125rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 1rem;
      width: 1rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.125rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }

  &-medium {
    &-green {
      height: 2rem;
      width: 2rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 2rem;
      width: 2rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }

  &-large {
    &-green {
      height: 3rem;
      width: 3rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.375rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $green;
    }

    &-white {
      height: 3rem;
      width: 3rem;
      pointer-events: none;
      border-radius: 50%;
      border: 0.375rem solid transparent;
      animation: loader 1s ease infinite;
      border-top-color: $white;
    }
  }
}

.pwd-bar {
  &__content {
    border-radius: 0.25rem;
    margin-top: 0.65rem;
    position: relative;
    background-color: #f2f2f2;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 0.25rem;
    transition: all 0.75s;

    &__text {
      color: $black;
      display: flex;
      margin-top: rem(2);

      h5 {
        color: #caced5;
        font-size: rem(12);
      }
    }
  }
}

.popover-container {
  border-radius: 0.5rem;
  box-shadow: rgb(60 64 67 / 30%) 0px 0px 2px 0px,
    rgb(60 64 67 / 15%) 0px 10px 6px 2px;
  background-color: #ffffff;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafcfd;
    height: 2rem;
    padding-left: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding-right: 0.75rem;
    color: #007e75;
    font-weight: 600;
  }
  &__button {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    background: #fff;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    &__svg {
      fill: #007e75;
    }
  }
  &__content {
    padding: 1rem;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffff;
    height: 2rem;
    bottom: 0;
    border-radius: 0 0 0.5rem 0.5rem;
    position: absolute;
    width: 100%;
  }
}

@for $i from 1 through 15 {
  #bvt-portal {
    .popover-container {
      &:nth-child(#{$i}) {
        z-index: 1 + #{$i};
      }
    }
  }
}

.progress-bar {
  padding: 0.125rem;
  width: 100%;

  &__inner {
    border-radius: 0.313rem;
    min-height: 2rem;

    &.shadow {
      box-shadow: 0 10px 6px -6px #777;
    }
  }

  &__content {
    height: 1.75rem;
    color: #fff;
    padding: 0.125rem 0.25rem 0.25rem 0.25rem;
    transition: all 1s;
    background-color: #477ffd;
    text-align: center;
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      // border-bottom-left-radius: 0.313rem;
      // border-top-left-radius: 0.313rem;
      border-radius: 0.313rem;
      text-align: left !important;
    }

    &:last-of-type {
      // border-bottom-right-radius: 0.313rem;
      // border-top-right-radius: 0.313rem;
      border-radius: 0.313rem;
      text-align: right;
    }
  }

  &__txt-container {
    display: flex;
    align-items: flex-end;
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    margin: 0 0 0 0.313rem;
    font-weight: 700;
    color: #ffffff;
  }

  &__perCon-container {
    // position: absolute;
    // right: 7.35rem;
    // margin: auto;
    display: flex;
    align-items: center;
  }

  &__text-count {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    margin: 0 0 0 0.313rem;
    font-weight: 700;
    color: #ffffff;
  }

  &__text-percent {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    margin-left: 0.25rem;
    font-weight: 700;
    color: #ffffff;
  }
}

// .ant-row {
//   flex-flow: initial !important;
// }

.progress-box {
  padding: 0.125rem;

  &__inner {
    border-radius: 0.313rem;
    background-color: #c7d4e3;

    &.shadow {
      box-shadow: 0 10px 6px -6px #777;
    }
  }

  &__content {
    height: 1.75rem;
    color: #fff;
    padding: 0rem;
    transition: all 1s;
    background-color: #477ffd;
    text-align: center;

    &:first-of-type {
      border-bottom-left-radius: 0.313rem;
      border-top-left-radius: 0.313rem;
      text-align: left !important;
    }

    &:last-of-type {
      border-bottom-right-radius: 0.313rem;
      border-top-right-radius: 0.313rem;
      text-align: right;
    }
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    margin: 0 0.313rem;
  }
}

.RangeSlider {
  -webkit-appearance: none !important;
  // height: 0.375rem !important;
  border-radius: 0.25rem !important;
  background-repeat: no-repeat !important;
  // width: inherit !important;

  &-text {
    color: $green;

    &.disabled {
      color: $grey;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin: auto 0 !important;
    height: 0.75rem !important;
    width: 0.75rem !important;
    background: #fff !important; // bulatan
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px !important;
    border: 1px solid rgb(155, 155, 155) !important;
    border-radius: 50% !important;
    transition: background 0.3s linear !important;
  }

  &.disabled {
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin: auto 0 !important;
      height: 0.75rem !important;
      width: 0.75rem !important;
      background: #fff !important; // bulatan
      box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px !important;
      border: 1px solid rgb(155, 155, 155) !important;
      border-radius: 50% !important;
      transition: background 0.3s linear !important;
      cursor: auto;
    }
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  // &::-webkit-slider-thumb:hover {
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  // }

  // &::-webkit-slider-thumb:active {
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

  //   transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  //     left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  //     bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  // }
}
