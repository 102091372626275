@keyframes an-star {
  0% {
    top: 5rem;
    opacity: 0;
    transform: scale(0.2);
  }
  30% {
    top: 1.25rem;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    top: 2.5rem;
    opacity: 1;
    transform: scale(0.8);
  }
}
@keyframes an-star-after {
  0% {
    top: 2.5rem;
    opacity: 1;
    transform: scale(0.8);
  }
  25% {
    top: 1.25rem;
    opacity: 1;
    transform: scale(0.8);
  }
  100% {
    top: 5rem;
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes an-blink {
  0% {
    top: 2.5rem;
    transform: scale(0.3);
  }
  80% {
    top: -1.25rem;
    transform: scale(1.2);
  }
  100% {
    top: -1.25rem;
    transform: scale(1.2);
  }
}
@keyframes an-blink-fade-in-out {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes an-blink-after {
  0% {
    top: -1.25rem;
    transform: scale(1.2);
    opacity: 1;
  }
  80% {
    top: 5rem;
    transform: scale(0);
    opacity: 0;
  }
  100% {
    top: 3.125rem;
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes an-key {
  0% {
    top: 0;
  }
  80% {
    top: -6.25rem;
  }
  100% {
    top: -6.25rem;
  }
}
@keyframes an-key-after {
  0% {
    top: -6.25rem;
  }
  80% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
.anm-password-update {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .content {
    display: contents;
    width: 20rem;
    height: 25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1.5rem rgba(242, 242, 242, 0.75);
    -webkit-box-shadow: 0 0.5rem 1.5rem rgba(242, 242, 242, 0.75);
    -moz-box-shadow: 0 0.5rem 1.5rem rgba(242, 242, 242, 0.75);
    background: #fff;
  }
  .key {
    margin: 0 -0.375rem -1.938rem -1.25rem;
    z-index: 2;
    position: relative;
    top: 0;
    opacity: 1;
    &.active {
      animation: an-key 1.8s;
      top: -6.25rem;
      opacity: 1;
      &.after {
        animation: an-key-after 1.8s;
        top: 0;
        opacity: 0;
      }
    }
  }
  .blink {
    position: relative;
    z-index: 1;
    top: 2.5rem;
    opacity: 1;
    transform: scale(0.3);
    &.active {
      animation: an-blink-fade-in-out 0.8s infinite;
      transition: all 1.5s;
      transform: scale(1.2);
      top: -1.25rem;
      opacity: 1;
      &.after {
        animation: an-blink-after 4s;
        transform: scale(0);
        top: 5rem;
        opacity: 0;
      }
    }
  }
  .star {
    position: relative;
    z-index: 3;
    opacity: 0;
    &.before {
      animation: an-star 1.8s;
      transform: scale(0.8);
      top: 2.5rem;
      opacity: 1;
      &.after {
        animation: an-star-after 1.8s;
        transform: scale(0.8);
        top: 5rem;
        opacity: 0;
      }
    }
  }
  .box {
    opacity: 1;
    &.after {
      transition: all ease-in-out 1.3s;
      opacity: 0;
    }
  }
  .verified {
    z-index: 4;
    position: absolute;
    transform: scale(0);
    opacity: 0;
    &.after {
      transform: scale(1);
      transition: all ease-in-out 1.3s;
      opacity: 1;
    }
  }
  .text {
    text-align: center;
    padding: 4rem 0;
  }
}
