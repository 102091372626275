.PersonalDetailContainer {
  &--card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 2.5rem;
    background: #ffffff;
    box-shadow: 0 1.25rem 1.25rem rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;

    &__footer {
      width: 100%;
    }
  }
}
