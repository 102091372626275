.AsmanMapViewComponent {
	&__popup {
		.leaflet-popup-close-button {
			top: 0.75rem !important;
			right: 0.75rem !important;
			border: 1px solid #ced4da!important;
			border-radius: 0.25rem!important;
		}
		.leaflet-popup-content-wrapper {
			background: #f8f8fb;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			min-width: 15.75rem;
		}
		.leaflet-popup-content {
			margin: 0;
		}
	}
}
.AsmanMapViewPopupInternalComponent {
	&__wrapper {
		padding: 0.875rem;
		display: flex;
		flex-direction: column;
	}
	&__top {
		display: flex;
		margin-bottom: 0.75rem;
		align-items: center;
        max-width: 13rem;
		img {
			width: 2rem;
			height: 2rem;
		}
	}
	&__top-wrap {
		margin-left: 0.5rem;
		display: flex;
		flex-direction: column;
	}
	&__top-title {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 0.875rem;
		color: #000000;
	}
	&__top-sub-title {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 0.75rem;
		color: #a0a6a9;
	}
	&__middle-section {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	&__middle-section-inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}
	&__middle-section-left,
	&__middle-section-right {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 0.75rem;
		color: #495057;
	}
    &__middle-section-left{
        text-align: left;
        flex-grow: 1;
    }
    &__middle-section-right{
        text-align: right;
        flex-grow: 1;
    }

    &__middle-section-divider{
        height: 1px;
        width: 100%;
        background-color: #ECEDEE;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
}
