.sort-table-popover {
  position: absolute;
  margin: 0.2504rem 0rem;
  padding: 0.2rem 0rem 0.4rem;
  width: 6.7rem;
  background-color: $background-color-white;
  border-radius: 0.2rem;
  z-index: 1;
  top: 40rem;
  left: 12rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

  &__option {
    margin: 0rem;
    padding: 0rem 0.614rem 0rem 0.414rem;
    font-size: 0.813rem;
    line-height: 1.5rem;
    cursor: pointer;
    display: table;

    span {
      display: table-cell;

      &:first-of-type {
        width: 90%;
      }

      &:last-of-type {
        width: 10%;
      }
    }

    &.checked {
      background-color: $background-color-dark-light;
    }
  }

  &--selected {
    font-weight: 800;
    background-color: $background-color-dark-light;
  }

  &__btn {
    margin: 0.5rem auto;
    background-color: $background-color-white;
    border: 0.0504rem solid #caced5;
    width: 5.1rem;
    font-size: 0.813rem;
  }

  &__check {
    float: right;
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
}
