.header-segment {
  border-radius: 0.5rem;
  padding: 0.45rem 2.5rem;
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--default,
  &--gray {
    background-color: #f0f0f3;
    border: 0.063rem solid #caced5;
  }

  &--outlined {
    background: #f8f8fb;
    border: 0.063rem solid #c9d8dc;
  }

  &__title-container {
    display: flex;
    align-items: center;
  }
  &__right-container {
    display: flex;
    align-items: center;
  }
  &__title {
    &.ant-typography {
      margin-bottom: 0rem;
      font-weight: 400;
      font-size: 1.875rem;
    }
    &--default,
    &--gray {
      &.ant-typography {
        color: #657991;
      }
    }
    &--outlined {
      &.ant-typography {
        color: #c9d8dc;
        font-size: 1.5rem;
      }
    }
  }
  &__right-wrapper {
    display: flex;
  }
  &__back-button {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background: #f8f8fb;
    border: 0.063rem solid #d7dae2;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    transition: 0.1s linear;
    color: #364961;
    &:hover {
      border-color: #007e75;
      color: #007e75;
      transition: 0.1s linear;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba(0, 126, 117, 0.5);
      transition: 0.1s linear;
    }
  }
}
