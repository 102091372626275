.sample {
  background: $grey;
  padding: 1rem;
}

.btn-lg-out {
  display: flex;

  &:hover {
    background: #ffefef;
    fill: red;

    h5 {
      color: red;
    }
  }
}
