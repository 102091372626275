.menu-bar__home-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 1rem 0 0;
  }

  &__back-home {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3rem;

    &:hover {
      background-color: #f8f8fb;
    }

    svg {
      margin: 0 0.25rem 0 0.5rem;
      fill: #4d4f5c;
    }

    p {
      margin: 0 0.5rem 0 0.25rem;
    }
  }
}
