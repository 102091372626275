.AnalyzeCard {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  background-color: #fff;
  position: relative;

  &--header {
    color: #007e75;
    background-color: #fafcfd;
    padding: 0.5rem 0.625rem;
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &_icon {
      fill: #007e75;
      display: flex;
      align-items: center;
    }

    &_text {
      font-size: 0.875rem;
      color: #007e75;
    }
  }

  &--body {
    padding: 0 0 0.125rem 0;
  }
}

.inputField {
  &__outlined {
    margin: 0 0 0.5rem 0;

    .label {
      margin: 0 0 0.25rem 0;
    }

    .inputContainer {
      border: 1.875px solid $grey;
      border-radius: 0.25rem;
      height: 1.75rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .input {
        font-size: 0.75rem;
        border: transparent;
        padding: 0.25rem;
        color: $green;
        width: 100%;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: #f8f8f8;
        }

        &::placeholder {
          color: $grey;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $grey;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $grey;
        }
      }

      .adornment {
        display: flex;
        align-items: center;
        padding: 0 0.25rem 0 0;
        border-radius: 4px;
      }
    }
  }

  &__contained {
    margin: 0 0 0.5rem 0;

    .label {
      margin: 0 0 0.25rem 0;
    }

    .inputContainer {
      border: 1.875px solid $grey;
      border-radius: 0.25rem;
      height: 1.75rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .input {
        font-size: 0.75rem;
        border: transparent;
        padding: 0.25rem;
        color: $green;
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: #f8f8f8;
        }

        &::placeholder {
          color: $grey;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $grey;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $grey;
        }
      }

      .adornment {
        display: flex;
        align-items: center;
        padding: 0 0.25rem 0 0;
        background: #ffffff;
        border-radius: 4px;
      }
    }
  }
}

.radioGroup {
  background-color: #f0f0f3;
  height: rem(30);
  display: inline-flex;
  border-radius: rem(4);
  padding: rem(2);
  align-items: center;

  > label {
    display: inline-flex;
    border-radius: rem(4);
    font-size: rem(13);
    align-items: center;
    width: rem(124);
    justify-content: center;
    height: rem(24);
    font-weight: $bold;
    cursor: pointer;

    &.disable {
      cursor: not-allowed;
    }
  }

  > input[type='radio'] {
    display: none;

    &:checked + label {
      background-color: #fff;
    }
  }
}
