.PertaminaAssetDetail {
  height: 100%;
  overflow: hidden;

  .header-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0.75rem;
    .title {
      color: #495057;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;
    }
    .button {
      background: #fff;
    }
  }

  .header-title {
    padding: 0.5rem 0.75rem;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    .text {
      color: #07827c;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
  .content-container {
    overflow: auto;
    padding: 0.75rem 0.75rem 4rem 0.75rem;
    height: calc(100% - 4.75rem - 2.5rem);
    .content {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  .footer-sidebar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0.75rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    .button {
      padding: 0.31rem 1rem;
      &.close {
        background: #f8f9fa;
        border: 1px solid #ced4da;
        color: #07827c;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
      &.dashboard {
        background: #07827c;
        border: 1px solid #07827c;
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }
}
