.search-box {
  &__wrapper {
    color: #616477;
    display: flex;
    align-items: center;
  }
  &__ant-input-wrapper {
    .ant-input-affix-wrapper {
      padding: 0.25rem 0.688rem;

      .ant-input {
        font-size: rem(14);
      }
    }
    .ant-input-affix-wrapper-focused:not(:disabled) {
      border-color: #007e75;
      box-shadow: none;
      outline: none;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .ant-input-affix-wrapper:not(:disabled),
    .ant-input-affix-wrapper-focused:not(:disabled) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      &:hover:not(:disabled) {
        border-color: #007e75;
        box-shadow: none;
      }
      &:focus:not(:disabled) {
        border-color: #007e75;
        box-shadow: none;
        outline: none;
      }
    }
    .ant-input-group-addon {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &--filled {
      .ant-input-group-addon {
        background-color: #007e75;
        border-color: #007e75;
        color: #fff;
      }
      .search-box__button-search {
        color: #fff;
      }
    }
  }
  &__button-search {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #caced5;
    &--disabled {
      cursor: not-allowed;
    }
    &--filled {
      background-color: red;
    }

    span {
      font-size: rem(14);
    }
  }
  &__clear-button {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.25rem;
    background: #fff;
    border: 0.063rem solid #caced5;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:disabled) {
      cursor: pointer;
    }
    &:hover:not(:disabled) {
      color: #007e75;
      border-color: #007e75;
    }
  }
}
