.ColorSelectedCard {
  &__container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 100%;
    min-width: 8rem;
    position: relative;
    overflow: hidden;
    transition: all ease-out 0.3s;
    &:hover {
      transition: all ease-out 0.3s;
      box-shadow: 0px 0.375rem 0.5rem rgba(0, 0, 0, 0.1);
    }
    &.disabled{
      cursor: not-allowed;
      filter: grayscale(1);
      &:hover{
        transition: unset;
        box-shadow: unset;
      }
    }
  }
  &__wrapper-top {
    position: absolute;
    width: 100%;
    min-height: 1.875rem;
    transition: all ease-out 0.3s;
    background-color: var(--ColorSelectedCardColorLighter);
    height: var(--ColorSelectedCardHeight);
    z-index: 0;
    opacity: 0.2;
  }
  &__checklist-wrapper {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    width: 1rem;
    height: 1rem;
    background: #3c3;
    border-radius: 1rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out 0.3s;
    opacity: var(--ColorSelectedCardOpacity);
    .checklist-svg {
      width: 0.5rem;
      height: 0.5rem;
      path {
        fill: #b8fccf;
        transform: translate(-14081.324px, 5344.009px) rotate(-45deg);
      }
    }
  }
  &__icon-bubble {
    z-index: 1;
    position: absolute;
    right: 3rem;
    margin-top: 0.5rem;
  }
  &__wrapper-bottom {
    margin-top: 1.375rem;
    min-height: 9rem;
    z-index: 1;
    padding: 0.5rem;
    display: flex;
    max-width: 8rem;
    flex-direction: column;
  }
  &__middle-text {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0.875rem;
    text-align: center;
    color: var(--ColorSelectedCardTextColor);
    margin-top: 1.25rem;
  }
  &__total-text {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.1875rem;
    text-align: center;
    color: #495057;
    margin-top: 0.3rem;
  }
  &__total-container {
    margin-top: 2.3rem;
  }
  &__more-text {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-align: center;
    color: var(--ColorSelectedCardTextColor);
    margin-top: auto;
    .dropdown-svg {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.25rem;
      path {
        fill: var(--ColorSelectedCardTextColor);
        transform: translate(0px, 4.447px) rotate(-45deg);
      }
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__popover-wrapper {
    display: block;
  }

  &.arrow {
    text-align: center;
    width: 200px;
  }
  
  &.arrow---down {
    position: absolute;
  }
  
  &.arrow---down:after,
  &.arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  &.arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 44%;
    margin-left: -10px;
    bottom: -50px;
  }
  
}
