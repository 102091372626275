.AsmanCardAnalysisResultErrorComponent {
	&__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		min-height: 30.875rem;
	}
	&__title {
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 1.75rem;
		line-height: 2.5rem;
		color: #006c64;
		margin-bottom: 1rem;
	}
	&__body {
		max-width: 40.5625rem;
		font-family: 'Source Sans Pro', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		text-align: center;
		color: #1d1e31;
        margin-bottom: 1.5rem;
	}
}
