.BusinessDetailSummary {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 1.25rem;
  background: #1d5271;
  border-radius: 0.5rem;
  justify-content: space-between;
  &--left,
  &--right {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  &--right {
    .BusinessDetailSummary--label,
    .BusinessDetailSummary--value {
      text-align: right;
      width: 100%;
    }
  }
  &--item {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
  }
  &--label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
  }
  &--value {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
  }
}
