.AdvanceSearchMapSearchInputComponent {
    &__wrapper {
      cursor: default;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 0.5rem 1.25rem rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      padding: 0.5rem;
      .ant-row {
        flex-grow: 1;
      }
    }
    &__select {
      border-radius: .5rem !important;

      .ant-select-selector {
        border-radius: .5rem !important;
      }
    }
    &__button {
      display: block;
      width: 100%;
      background: #ffffff;
      border: 1px solid #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.102);
      border-radius: 4px;
      &:not(:disabled):hover, &:focus{
        border-color: #ffffff;
        color: #05827b;
      }
    }
  }
  