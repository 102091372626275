.feature-auth {
  &.AuthLayout {
    > * {
      z-index: 1;
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 100vh;
      background: #f9fbfc;
      position: relative;
      overflow: hidden;
    }
    &__background {
      position: absolute;
      z-index: 0;
      width: auto;
      height: calc(100vh + 16rem);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &__card {
      max-width: 100%;
      width: 25rem;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(150, 150, 150, 0.1);
      border-radius: 0.5rem;
      overflow: hidden;
      background: #fff;
      box-shadow: 0px 20px 50px #f2f2f2;
      position: relative;
      margin-bottom: auto;
      min-height: 32rem;
    }
    &__logo-li {
      width: 14.25rem;
      height: auto;
      margin-bottom: 2rem;
      margin-top: 4rem;
    }
    &__version-text {
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #caced5;
      margin-bottom: 3.875rem;
    }
    &__logo-bvt {
      width: 128px;
      margin-bottom: 1rem;
      height: auto;
    }
    &__success {
      &-txt {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 30%;
      }
    }
  }
}
