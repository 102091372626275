.AsmanCardZoningComponent {
  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #696969;
    margin-top: 1rem;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    background: #E8E8E8;
    border-radius: 0.25rem;
    margin-top: 0.75rem;
    gap: 0.5rem;
  }

  &__content-item {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #07827C;
    box-sizing: border-box;
    align-items: center;
    padding: 4px 8px;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: 4px;
  }
}
