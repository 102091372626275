.AccountItemInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &--item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    &:nth-of-type(1) {
      .AccountItemInfo--value {
        font-size: 1.125rem;
      }
    }
  }
  &--label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #6c757d;
  }
  &--value {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #495057;
    background: #ffffff;
    border-bottom: 1px solid #ced4da;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0.5rem;
    gap: 0.5rem;
  }
}
