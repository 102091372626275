.dropdown {
  border: 1px solid #caced5;
  border-radius: 4px;
  width: 50px;
  display: flex;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  .icon-arrow-up svg > path,
  .icon-arrow-down svg > path {
    fill: #4d4f5c;
  }

  &__popover {
    width: 3.125rem;
    margin-top: 0.125rem;
    border: 1px solid #caced5;
    border-radius: 4px;
    box-shadow: none;
    padding: 0;

    .popover-container__title {
      display: none;
    }

    &--empty {
      display: none;
    }
  }

  &__list-text {
    cursor: pointer;
    padding: 0.125rem 0.25rem;

    &:hover {
      background-color: #f8f8fb;
      border-radius: 4px;
    }
  }
}
