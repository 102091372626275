.checkBoxField {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__label {
    color: $black;
  }

  &__input {
    margin: 0 0.25rem 0 0;
  }
}
