.header-segment {
  &__title-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    &-divider {
      width: 1px;
      height: 1rem;
      background-color: #657991;
      margin: 0rem 1rem;
    }
  }
}
