.CardListItemDatex{
  &__container {
    padding: 12px;
  }
  &__card {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    background: none;
    border-radius: 6px;
    margin-top: 8px;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.06));
    &-drag-handler {
      border-radius: 6px 0px 0px 6px;
      padding: 1rem 0.5rem;
      background: #fbfbfb;
      border: 1px solid #d7dae2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
