.BaseOptionListItemMultipleThematic__list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 2.5rem;
  .ant-checkbox-wrapper-checked {
    border: 1px solid #07827c !important;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 100%;
    background: #f8f8fb;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    gap: 1.8125rem;
    border: 1px solid transparent;


    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #1d1e31;
    }
    .ant-checkbox-inner {
      border: 1px solid #ced4da;
      border-radius: 4px;
      background: #ffffff;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: 1px solid #07827c;
      border-radius: 4px;
    }
    .ant-checkbox-checked::after {
      border-color: #07827c;
      border-radius: 4px;
    }
    &.ant-checkbox-wrapper-checked {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #07827c;
        border-color: #07827c;
        border-radius: 4px;
      }
    }
  }
  .ant-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
