.AdvanceSearchMapSearchModal {
    .title {
        color: #05827b;
    }
    .button {
        border-radius: .5rem;
        border: 1px solid #ebebeb;
    }
    .button-active {
        background-color: #05827b;
    }
}