.MarkerPopup {
  &__popup {
    &.leaflet-popup {
      margin-bottom: 0 !important;
    }
    .leaflet-popup-content {
			width: auto !important;
			padding: 0px;
			margin: 0px !important;
		}
		.leaflet-popup-content-wrapper {
			border-radius: 0.25rem;
			padding: 0px;
		}
    .leaflet-popup-tip-container {
      visibility: hidden !important;
    }
	}
	&__popup-wrap {
		min-width: 13.75rem;
		display: flex;
		flex-direction: column;

    &-dashboard {
      min-width: 10rem;
      padding: 0.5rem;

      .detail {
        text-align: left;
  
        span {
          font-weight: 600;
        }

        .info {
          width: max-content;
        }
      }
    }

    .img {
      &-wrapper {
        text-align: center;
      }

      height: 7.5rem;
      width: 100%;
    }
    .detail {
      padding: 0.5rem;
      
      .title {
        font-weight: 600;
        font-size: 1.25rem;
        margin-bottom: 0.25rem;
        line-break: anywhere;
      }

      .address {
        color: #878A92;
      }

      .coords {
        width: max-content;
        margin-bottom: 0.25rem;
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.125rem 0.5rem;
        border-radius: 0.5rem;

        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.25rem;
        }

        &.exist {
          background: #eee;
          border: 1px solid #e1e1e1;
          span {
            color: #616161;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
          }
        }
        &.new {
          border: 1px solid #8cd7b6;
          background: #e6f6ef;
          span {
            color: #047845;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
          }
        }
      }
    }
	}
}
