.feature-mainmap {
  &.MainMapLayerVisibilityItemList {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  .MainMapLayerVisibilityItemList {
    &__not-found-wrap {
      display: flex;
      flex-direction: column;
      height: 20rem;
      justify-content: center;
      align-items: center;
    }
    &__not-found-title {
      font-family: 'Source Sans Pro',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      display: flex;
      color: #6c757d;
      text-align: center;
    }
    &__not-found-image{
        width: 7.25rem;
        height: auto;
        margin-bottom: 0.5rem;
    }
  }
}
