.userLogoGreetings {
  display: flex;
  margin: 0 0 0 4px;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    background-color: #ffffff;
  }

  &__info {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__info-text {
    height: 2rem;
    font-size: 28px;
    margin: 0 4px 0 0;
    color: #4d4f5c;
    display: flex;
    justify-content: center;
    align-items: center;

    &--user {
      height: 32px;
      font-size: 28px;
      font-weight: 600;
      margin: 0 8px 0 0;
      color: #4d4f5c;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__info-image {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 496px) {
  .userLogoGreetings__info {
    display: none;
  }
}
