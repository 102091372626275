.menu-bar__menu-list {
  margin: 0rem;
  display: flex;
  align-items: center;
  position: relative;

  &__li {
    padding: 0.25rem 0.75rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
    color: #616161;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3rem;
    position: relative;

    &:hover {
      background-color: #f7f7fa;
    }

    &__text {
      p {
        margin: 0;
      }
    }

    &.selected {
      background-color: #eeeef3;

      & > ::after {
        background-color: #007e75;
        border: 1px solid #007e75;
        border-radius: 0.125rem;
        position: absolute;
        content: '';
        width: 70%;
        top: 2.75rem;
        -webkit-animation: scale-up-hor-center 0.25s;
        animation: scale-up-hor-center 0.25s;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }
  }

  &__popover {
    display: flex;
  }

  &__submenu {
    &__list {
      padding: 0.25rem 0rem;
      margin: 0rem;

      li {
        padding: 0.25rem 0.75rem;
        font-size: 0.875rem;
        color: #4d4f5c;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: #f7f7fa;
          color: #007e75;

          & > svg {
            fill: #007e75;
          }
        }

        svg {
          margin: 0rem 0.5rem 0rem 0rem;
          fill: #4d4f5c;
        }
      }

      &--container {
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }
}

@-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
