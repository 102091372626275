.GensetLayout {
  margin-top: 3.125rem;
  position: relative;

  &--content {
    display: flex;

    .SubMenu__wrapper {
      overflow-y: hidden;
    }
  }

  &--side_menu {
    margin-top: 0.6875rem;
    min-width: 15.625rem;
    margin-left: 0.3125rem;
  }

  &--main {
    width: 100%;
    overflow: hidden;
    padding: 0.675rem 0.5rem 0 0.5rem;
  }
}
