.AsmanCardTopPoiGetComponent{
  th {
    background: unset !important;
    border-bottom: none !important;
    color: #BEBEBE !important;
  }
  th:before {
    background-color: unset !important;
  }

  &__row td{
    color: #696969;
    font-weight: 600;
    border-bottom: 1px solid #BEBEBE !important;
  }
  &__wrapper-name{
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__cell-near{
    font-weight: 400 !important;
  }
}
