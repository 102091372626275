.menu-bar__about-modal {
  width: 35rem;
  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--logo {
    padding: 0.25rem 0rem;
    margin: 0rem 1rem;
  }

  &--body {
    &--table {
      width: 100%;
      margin: 1.125rem 0rem;
      table {
        width: 100%;
      }

      tr {
        td {
          border: 0 none transparent;
          border-left: 0.75rem solid transparent;
          border-right: 0.75rem solid transparent;
        }

        td:first-child {
          text-align: right;
          width: 48%;
          color: #657991;
        }

        td:last-child {
          font-weight: bold;
          width: 52%;
          color: #657991;
        }
      }
    }

    &--license {
      display: flex;
      position: relative;
      flex-direction: column;
      &--about {
        border: 1px solid #caced5;
        background-color: #ffffff;
        color: #007e75;
        border-radius: 0.25rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        margin: 1.125rem 0rem;
        padding: 0rem 1rem;
      }
    }
  }
}

.popup__content {
  .popup__body {
    padding: 0.5rem;
  }

  .popup__footer {
    padding: 0.5rem 0rem 1rem;
  }

  .ant-divider-horizontal {
    margin: 0rem;
  }
}
