@import '../../../../sass/bvt/_global/variables';
body {
  background-color: $color-dark-light !important;
}
.search-result-container {
  padding: 1.25rem 0rem;
  background-color: $color-dark-light;

  .row-main-search {
    &-wrapper {
      margin: 0;
    }

    display: grid;
    grid-template-columns: 7.875rem calc(100% - 7.875rem);
    align-items: center;
    padding-left: 0;
    padding-top: 4rem;
  }

  .row-main-search-lottie {
    .search-file-lottie {
      width: 50%;
      margin: 7rem auto;
    }
  }
}

.col-main-search {
  align-self: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.375rem;
  &-back {
    width: 1.875rem;
    height: 1.875rem;
    border: 1px solid #d7dae2;
    background-color: #f8f8fb;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
      transform: rotate(90deg);
      fill: #364961;
    }
  }

  &-text {
    margin-left: 0.5938rem;
    color: #657991;
    font-size: 0.875rem;
    padding: 0;
  }

  &-searchbox {
    width: 20.125rem !important;
    margin-left: auto;
  }

  .header-segment--outlined {
    background-color: #f0f0f3;
  }
}
