.BaseCardDatex {
  &__container {
    width: 100%;
    background: none;
  }
  &__main {
    display: flex;
    flex-direction: row;
    flex: 1;
    background: transparent;
    border: 1px solid #d7dae2;
    border-radius: 0.375rem;
  }
  &__left {
    padding: 1rem 0.5rem;
    border-right: 1px solid #d7dae2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.375rem 0 0 0.375rem;
    background: #fff;
    &--disabled {
      padding: 1rem 0.5rem;
      border-right: 1px solid #d7dae2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0.375rem 0 0 0.375rem;
      background: #f0f0f3;
      filter: grayscale(1);
    }
  }
  &__right {
    background: none;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__header {
    background: #f0f0f3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0 0.375rem 0 0;
    padding: 0.25rem 0.75rem;
    &-left {
      &-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #07827c;
        &--disabled {
          color: #ced4da;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      &--disabled {
        display: flex;
        flex-direction: row;
        align-items: center;
        filter: grayscale(1);
      }
    }
    &-right {
      &-btn {
        border: 1px solid #ced4da;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 0.375rem;
        padding: 0;
        background: transparent;
        &:hover {
          border-color: #055a55;
          color: #055a55;
        }
        &--disabled {
          color: #6c757d;
          background: #a0a6a9 !important;
          border: 1px solid #ced4da;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-radius: 0.375rem;
          padding: 0;
        }
        &--vsb-lock {
          color: #ced4da;
          background: #055a55;
          border: 1px solid #ced4da;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-radius: 0.375rem;
          padding: 0;
          &:hover {
            background: #055a55;
            border-color: transparent !important;
            color: #ffffff;
          }
        }
      }
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      background: transparent;
    }
  }
  &__body {
    &-top {
      background: #FFFFFF !important;
      padding: .25rem 1rem;
      &-title {
        display: flex;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6C757D;
        margin-top: .25rem;
        margin-bottom: .75rem;
      }
      &-years {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: .25rem;
        margin-top: .75rem;
        span {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #6C757D;
        }
      }
    }
    &-left {
      &-thematic {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        padding-right: .5rem;
        &-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          flex-wrap: wrap;
          gap: 0.375rem;
          &-left {
            display: flex;
            gap: 0.5rem;
            align-items: center;
          }
        }
      }
      &-info {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        &-text-title {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.5rem;
          color: #495057;
        }
        &-text-subtitle {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          color: #a0a6a9;
        }
        display: flex;
        flex-direction: column;
        &--label {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.125rem 0.5rem;
          background: #24ccb8;
          border-radius: 1.5rem;
          svg {
            margin-right: .25rem;
          }
          span {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: #f8f8fb;
          }
        }
      }
      &__select-option {
        z-index: 1024;
        &__dropdown-wrap {
          z-index: 1024;
          width: max-content !important;
        }
      }
      &__icon {
        width: 2.25rem !important;
        height: 2.25rem;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.375rem;
      flex: 1;
      // TODO: Fix the selector cc. Malik
      div .ant-select-selection-item {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      div .ant-select-selection-item div span {
        display: none;
      }

      div .ant-select-selection-item div {
        min-width: 1.5rem !important;
        min-height: 1.5rem !important;
        border-radius: '0.375rem';
      }
    }
    &-right {
      &-icon {
        margin-right: 0.75rem;
      }
      &-loading svg {
        animation: BaseCardDatex__body-right-loading 1s linear infinite;
      }

      @keyframes BaseCardDatex__body-right-loading {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    &-bottom {
      &-info {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        display: flex;
        flex-direction: column;
        &--label {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.125rem 0.5rem;
          background: #24ccb8;
          border-radius: 1.5rem;
          width: fit-content;
          margin-top: .25rem;
          span {
            margin-left: .375rem;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: #f8f8fb;
          }
        }
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0 0 0.375rem 0;
    background: #ffffff;
  }
}
