.AsmanCardEducationComponent {
  &__chart-wrapper{
    display: flex;
    flex-direction: column;
  }

  &__chart-bar-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;
    align-items: center;
  }
  &__chart-wrap{
    width: 100%;
    margin-left: 1rem;
  }
  &__chart-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1d1e31;
  }
  &__chart-bar-item {
    width: 100%;
    height: 2rem;
    background: #e8e8e8;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  &__chart-bar {
    left: 0;
    width: 0;
    background-color: #07827c;
    padding: 0.375rem;
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
