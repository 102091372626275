.SoundPlayerCard {
  &__container {
    width: 100%;
    background: #fff;
    border: 1px solid #caced5;
    border-radius: 0.4rem;
    flex-direction: column;
    overflow: hidden;
    display: flex;
    position: relative;
    &-soundwave-overlay {
      position: absolute;
      background: #dcdcdc;
    }
    &-soundwave {
      padding-left: 16px;
      padding-right: 16px;
    }
    &--active {
      border: 1px solid #007e75;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14);
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.75rem;
    &-text {
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #4d4f5c;
    }
    &-btn-wrapper {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
    &-btn {
      padding: 0.275rem 14px;
      border-radius: 4px;
      font-weight: normal;
      font-size: 14px;
      color: #007e75;
      cursor: pointer;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      &--test {
        background: #fff;
        border: 1px solid #caced5;
        color: #007e75;
      }
      &--play {
        background: #007e75;
        border: 1px solid #007e75;
        color: #fff;
      }
      &--play-disabled {
        background: #caced5;
        border: 1px solid #caced5;
        color: #fff;
        cursor: not-allowed;
      }
    }
  }
}
