.BaseCollapsePOI {
  &.ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    border: none;
    .ant-collapse-item {
      &.BaseCollapsePOI__panel {
        border-bottom: none;
      }
      .ant-collapse-header {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem;
        gap: 1.8125rem;
        width: 100%;
        background: #fafafa;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        flex: none;
        flex-grow: 0;
        flex-direction: row-reverse;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #064f4b;
        .BaseCollapsePOI__panel--header {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          img {
            width: 24px;
          }
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.5rem;
          color: #616477;
          &.disabled{
            cursor: not-allowed;
            filter: grayscale(1);
          }
        }
      }
      .ant-collapse-content {
        border-top: none;
        .ant-radio-group.BaseCollapsePOI__radio-group {
          width: 100%;
          display: flex;
          .BaseCollapsePOI__radio-group--space {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            align-items: center;
            justify-self: center;
            .ant-radio-wrapper:hover .ant-radio,
            .ant-radio:hover .ant-radio-inner,
            .ant-radio-input:focus + .ant-radio-inner {
              border-color: #07827c !important;
            }
            .BaseCollapsePOI__radio--wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
            .ant-radio-wrapper.BaseCollapsePOI__radio--item {
              display: flex;
              width: 100%;
              height: 4rem;
              align-items: center;
              background: #f4f4f7;
              border: 1px solid #f4f4f7;
              border-radius: 6px;
              padding: 0.625rem;
              gap: 0.5rem;
              justify-content: flex-start;

              &.ant-radio-wrapper {
                margin: 0;

                &::after {
                  display: none;
                }
              }
              &.checked {
                border-color: #07827c;
              }
              .ant-radio {
                top: 0;
                .ant-radio-inner {
                  width: 16px;
                }
                .ant-radio-inner::after {
                  background-color: #07827c;
                }
              }
              span {
                padding-left: 0;
                padding-right: 0;
              }
              span.ant-radio {
                width: 16px;
                .ant-radio-input {
                  width: 16px;
                  height: 16px;
                }
              }
              span:last-child {
                width: 100%;
              }
              .BaseCollapsePOI__radio-wrapper {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;
                .BaseCollapsePOI__radio-wrapper--left,
                .BaseCollapsePOI__radio-wrapper--right {
                  display: flex;
                  align-items: center;
                }
                .BaseCollapsePOI__radio-wrapper--left {
                  gap: 0.5rem;
                  .BaseCollapsePOI__radio-item--icon {
                    display: flex;
                    img {
                      width: 1.5rem;
                      height: 1.5rem;
                    }
                  }
                  .BaseCollapsePOI__radio-item--title {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: normal;
                    align-items: center;
                    color: #1d1e31;
                  }
                }
                .BaseCollapsePOI__radio-wrapper--right {
                  gap: 0.5rem;
                  .BaseCollapsePOI__radio-item--total {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                    color: #20a49d;
                  }
                  .BaseCollapsePOI__radio-item--action {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0.125rem 0.5rem;
                    gap: 0.625rem;
                    border: 1px solid #ced4da;
                    border-radius: 4px;
                    flex: none;
                    flex-grow: 0;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: #07827c;
                    background: #f9fbfc;
                    &:disabled {
                      color: #ced4da;
                      pointer-events: none;
                      background: #f9fbfc;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.container-popover{
    height: 30px;
    align-items: center;
    background-color: black;
    color: white;
    box-shadow: none !important;
    border-radius: 8px;
    &-text {
      padding: 6px 12px 0;
      font-family: sans-serif, 'Source Sans Pro';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
      overflow: hidden; /* Ensures the overflow is hidden */
      text-overflow: ellipsis; /* Adds an ellipsis to signify clipped text */
      width: 100%;
    }
  }
  &.__popover-wrapper {
    display: block;
  }
  
  &.arrow {
    text-align: center;
    width: 200px;
  }
  
  &.arrow---down {
    position: absolute;
  }
  
  &.arrow---down:after,
  &.arrow---down:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: solid transparent;
    pointer-events: none;
  }
  
  &.arrow---down:after {
    border-top-color: #000;
    border-width: 10px;
    left: 44%;
    margin-left: -10px;
    bottom: -50px;
  }
}
