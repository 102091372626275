.DatexTelcoStepOneTelco__container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .DatexTelcoStepOneTelco__search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 12px;
    gap: 8px;
    isolation: isolate;
    width: 100%;
    background: #f0f0f3;
    border-radius: 6px;

    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6c757d;
    }
  }

  .DatexTelcoDataType {
    &_title {
      color: #6C757D;
      margin-bottom: 8px;
    }

    &_select {
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .DatexTelcoDataType_date {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #caced5;
    border-radius: 8px;
    &-info {
      display: flex;
      padding: 4px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #00A8FE;
      background: rgba(0, 161, 241, 0.10);
      color: #00A8FF;
      margin-top: 0.75rem;
      text-align: center;
    }
    table {
      tr {
        th {
          color: #007d74;
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: normal;
          font-size: 14px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
    .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
      pointer-events: none;
    }
    .ant-picker-panel {
      border-top: none;
      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-content {
            tbody {
              background-color: #F8F8FB;
            }
            .ant-picker-cell {
              .ant-picker-cell-inner {
                min-width: 48px;
                height: 48px;
                line-height: 48px;
                padding-top: 8px;
                &:hover {
                  transition: 0.15s ease-out;
                  background-color: #007d74;
                  border-radius: 4px;
                  color: #fff;
                }
                .ant-picker-calendar-date-value {
                  font-family: 'Source Sans Pro', sans-serif;
                  font-weight: normal;
                  font-size: 14px;
                  text-align: center;
                }
                &.ant-picker-calendar-date.ant-picker-calendar-date-today {
                  &::before {
                    content: "";
                    position: absolute;
                    border: none;
                    background-color: #FFCA28;
                    width: 6px;
                    height: 6px;
                    top: 10px;
                    left: 21px;
                    border-radius: 50%;
                  }
                }
              }
              &.ant-picker-cell-selected {
                .ant-picker-cell-inner.ant-picker-calendar-date {
                  background: #007d74;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
    .ant-picker-cell.ant-picker-cell-disabled::before {
      display: none;
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      background: #ffffff;
      border: 1px solid #ced4da;
      border-radius: 4px;

      .ant-select-selection-placeholder {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #6C757D;
      }

      .ant-select-selection-item {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #6c757d;
      }
    }
  }

  .ant-radio-inner::after {
    background: #007e75;
  }

  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
  }
}

.ant-select-item {
  &.ant-select-item-option {
    &.ant-select-item-option-active {
      background: #07827c;
      color: #ffffff;
    }
    &.ant-select-item-option-selected {
      background: rgb(7, 130, 124, 0.1);
      color: #6c757d;
    }
  }
}

.ant-select-dropdown {
  padding: 0px;
  .DatexTelcoStepOneTelco__select--search {
    background: #f8f8fb;
    border-bottom: 1px solid #ced4da;
    border-radius: 4px 4px 0px 0px;
    .ant-input {
      background: #f8f8fb;
    }
  }
}
