$base-color: #007d74;
.bvt-dropdown {
  position: relative;
  &__container {
    height: 2rem;
    border: 1px solid #caced5;
    background: #fff;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0;
    display: flex;
    border-radius: 0.4rem;
    &:hover {
        background: #f8f8fb;
    }
  }
  &__placeholder {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0.35rem 0.8rem;
    vertical-align: middle;
    color: #CACED5;
    font-weight: 400;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  &__selected-item {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0.18rem 0.4rem;
    vertical-align: middle;
    background: #ffff;
    border-radius: 0.4rem;
  }
  &__arrow {
    width: 0.4rem;
    padding: 0 0.8rem;
    display: inline-block;
    position: relative;
    cursor: pointer;
    border-radius: 0 0.5rem 0.5rem 0;
  }

  &__arrow-down {
    position: absolute;
    top: 0.7rem;
    left: 0.1rem;
    width: 0;
    height: 0;
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    border-top: 0.4rem solid #4d4f5c;
  }

  &__arrow-up {
    position: absolute;
    top: 0.7rem;
    left: 0.1rem;
    width: 0;
    height: 0;
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    border-bottom: 0.4rem solid #4d4f5c;
  }
  &__select-box {
    z-index: 1;
    width: 100%;
    position: absolute;
    margin-top: 1.9rem;
    margin-left: -0.1rem;
    background: #f8f8fb;
    border: 1px solid #caced5;
    border-radius: 0.4rem;
    box-shadow: rgb(99 99 99 / 20%) 0px 0.125rem 0.5rem 0px;
    line-height: 1;
  }
  &__tag {
    background: #f8f8fb;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0.1rem 0.3rem;
    border-radius: 0.3rem 0 0 0.3rem;
    color: #4d4f5c;
    border-top: 1px solid #b1b7c1;
    font-weight: 400;
    border-left: 1px solid #b1b7c1;
    border-bottom: 1px solid #b1b7c1;
    p {
      position: absolute;
      min-width: 15rem;
    }
    &__button {
      font-weight: 500;
      border: 1px solid #b1b7c1;
      padding: 0.17rem;
      background: #fff;
      color: #b1b7c1;
      border-radius: 0 0.3rem 0.3rem 0;
      cursor: pointer;
      line-height: 1;
      &:hover {
        color: $base-color;
      }
    }
  }
  &__list {
    cursor: pointer;
    padding: 0.5rem 1rem 1rem 1rem;
    font-weight: 500;
    background: #fff;
    max-height: 10rem;
    overflow-y: auto;
    box-shadow: rgb(99 99 99 / 20%) 0px 0.125rem 0.5rem 0px;
    border-radius: 0.4rem;
    &:active {
      overflow-y: none;
    }
    &__item {
      &:hover {
        background: #007d74;
        color: #fff;
        border-radius: 0.25rem;
        padding: 0.1rem 0.25rem;
        
      }
      p{
        margin: 0.1rem;
      }
    }

    &__notfound {
      text-align: center;
    }
  }
  &__input {
    outline: none;
    border: none;
    background: none;
    padding: 0.3rem 0.7rem;
    width: 100%;
    font-size: 0.9rem;
  }
  &__header {
    border-bottom: 1px solid #caced5;
    background: #f8f8fb;
    border-radius: 0.3rem 0.3rem 0 0;
  }
  &__content {
    max-height: 12rem;
    min-height: 0.4rem;
  }
}
