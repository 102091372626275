.feature-genset-parameter {
  &.RenameForm {
    min-width: 30rem;
    padding-top: 1.5rem;
  }
  &--btn_group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
  }
  .btn {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
  }
  &--btn_outlined {
    @extend .btn;
    background-color: #fff;
    border: 1px solid #007e75;
    color: #007e75;
  }
  &--btn_filled {
    @extend .btn;
    background-color: #007e75;
    border: 1px solid #007e75;
    color: #fff;

    &_disabled {
      @extend .btn;
      pointer-events: none;
      color: #caced5;
      background-color: #f8f8fb;
      border: 1px solid #f8f8fb;
    }
  }
}
