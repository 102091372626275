.DatexStepPOIEditBrand {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .DatexStepPOIEditBrand__search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0.5rem;
    gap: 05rem;
    isolation: isolate;
    width: 100%;
    background: #ebeff5;
  }

  .ant-input-group-wrapper {
    background: #ffffff;
    opacity: 0.6;
    border-radius: 4px;
    .ant-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ant-input-group-addon {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .icon-addonAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }
  .DatexStepPOIEditBrand__tab {
    display: flex;
    width: 100%;
    padding: 0.25rem;
    gap: 0.5rem;
    background: #f0f0f3;
    border-radius: 4px;
    .DatexStepPOIEditBrand__tab---item {
      flex-grow: 1;
      border-color: #f0f0f3;
      border-right-color: #f0f0f3 !important;
      background-color: #f0f0f3;
      padding: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        background-color: #f0f0f3;
      }
      span.ant-radio-button {
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.ant-radio-button-wrapper-checked {
        span {
          color: #07827c;
        }
      }
      span {
        color: #6c757d;
      }
      .ant-radio-button-checked {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 1.5rem;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
        border-radius: 4px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #07827c;
      }
    }
  }
  .DatexStepPOIEditBrand__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 2.5rem;
    .ant-checkbox-group-item {
      margin: 0;
      width: 100%;
      background: #f8f8fb;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.625rem 0.625rem 0.625rem 1.25rem;
      gap: 1.8125rem;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #1d1e31;
      }
      .ant-checkbox-inner {
        border: 1px solid #ced4da;
        border-radius: 4px;
        background: #ffffff;
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid #07827c;
        border-radius: 4px;
      }
      .ant-checkbox-checked::after {
        border-color: #07827c;
        border-radius: 4px;
      }
      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #07827c;
          border-color: #07827c;
          border-radius: 4px;
        }
      }
    }
    .ant-checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
