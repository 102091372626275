.progress-box {
  padding: 0.125rem;

  &__inner {
    border-radius: 0.313rem;
    background-color: #c7d4e3;

    &.shadow {
      box-shadow: 0 10px 6px -6px #777;
    }
  }

  &__content {
    height: 1.75rem;
    color: #fff;
    padding: 0rem;
    transition: all 1s;
    background-color: #477ffd;
    text-align: center;

    &:first-of-type {
      border-bottom-left-radius: 0.313rem;
      border-top-left-radius: 0.313rem;
      text-align: left !important;
    }

    &:last-of-type {
      border-bottom-right-radius: 0.313rem;
      border-top-right-radius: 0.313rem;
      text-align: right;
    }
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    margin: 0 0.313rem;
  }
}
