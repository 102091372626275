.inputField {
  &__outlined {
    margin: 0 0 0.5rem 0;

    .label {
      margin: 0 0 0.25rem 0;
    }

    .inputContainer {
      border: 1.875px solid $grey;
      border-radius: 0.25rem;
      height: 1.75rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .input {
        font-size: 0.75rem;
        border: transparent;
        padding: 0.25rem;
        color: $green;
        width: 100%;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: #f8f8f8;
        }

        &::placeholder {
          color: $grey;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $grey;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $grey;
        }
      }

      .adornment {
        display: flex;
        align-items: center;
        padding: 0 0.25rem 0 0;
        border-radius: 4px;
      }
    }
  }

  &__contained {
    margin: 0 0 0.5rem 0;

    .label {
      margin: 0 0 0.25rem 0;
    }

    .inputContainer {
      border: 1.875px solid $grey;
      border-radius: 0.25rem;
      height: 1.75rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .input {
        font-size: 0.75rem;
        border: transparent;
        padding: 0.25rem;
        color: $green;
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: #f8f8f8;
        }
        &::placeholder {
          color: $grey;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $grey;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $grey;
        }
      }

      .adornment {
        display: flex;
        align-items: center;
        padding: 0 0.25rem 0 0;
        background: #ffffff;
        border-radius: 4px;
      }
    }
  }
}
