.SecurityAndLoginContainer {
  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;
  }
  &--info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
    background: #1d5271;
    border: 1px solid #1d5271;
    border-radius: 0.25rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #f8f8fb;
  }
  &--button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.3125rem 1rem;
    gap: 0.625rem;
    background: #07827c;
    border: 1px solid #07827c;
    border-radius: 0.25rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    color: #ffffff;

    &:hover,
    &:focus {
      background: #07827c;
      border: 1px solid #07827c;
      color: #ffffff;
    }
  }
  &--item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 1rem;
    width: 100%;
  }
  &--form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.5rem;
    width: 100%;
    &__label {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #495057;
    }
    &__input {
      width: 100%;
      &.custom-input {
        padding: 0.5rem 0.75rem;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        .ant-input-suffix {
          display: flex;
          flex-direction: row-reverse;
        }
        .suffix-show {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0.125rem 0.5rem;
          gap: 0.625rem;
          background: #f9fbfc;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #07827c;
          &:disabled {
            color: #ced4da;
            cursor: not-allowed;
          }
        }
        .ant-input-password-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0.125rem 0.5rem;
          gap: 0.625rem;
          background: #f9fbfc;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #07827c;
        }
        .ant-input-clear-icon-hidden {
          display: none;
        }
      }
    }
    &__error-message {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: red;
    }
  }
  &--confirmation {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__divider {
      margin: 0;
      padding: 0;
    }
    &__label {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: #6c757d;
    }
    &__input {
      display: flex;
      gap: 0.625rem;
      align-items: center;
      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #6c757d;
      }
      &-switch {
        &.ant-switch-checked {
          background-color: #07827c;
        }
      }
    }
  }
}
