.feature-map.LayoutDatexSidebarWrapper {
  background-color: #f9fbfc;
  position: fixed;
  top: 8.0262rem;
  right: 24rem;
  z-index: 1024;
  display: flex;
  flex-direction: column;
  width: 23.875rem;
  height: calc(100vh - 8.0262rem);
  max-height: 100vh;

  &__header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    z-index: 3;
    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem .75rem;
      gap: 1rem;
      background: #FFFFFF;
      justify-content: space-between;
      &__text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.5rem;
        color: #495057;
        margin: 0;
      }
    }
    &__subtitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: .625rem .75rem;
      gap: 1.5rem;
      background: #F0F0F3;
      &__text {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: #6C757D;
      }
      &__btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .3125rem 1rem;
        gap: .625rem;
        background: #07827C;        
        border: 1px solid #07827C;
        border-radius: .25rem;
        color: #FFFFFF;
        &--disabled{
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: .3125rem 1rem;
          gap: .625rem;
          background: #f5f5f5;        
          border: 1px solid #d9d9d9;
          border-radius: .25rem;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    &__subheader {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 .75rem;
      gap: 1rem;
      justify-content: space-between;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      background: #FFFFFF;
      flex: none;
      order: 2;
      &-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 0.5rem;
        &__step {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: .8125rem;
          line-height: 1rem;
          display: flex;
          align-items: center;
          text-align: center;
          box-sizing: border-box;
          background: #07827C;
          border: 1px solid #07827C;
          border-radius: .25rem;
          color: #FFFFFF;
          padding: .4375rem .75rem;
        }
        &__title {          
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: .875rem;
          line-height: 1.25rem;
          color: #07827C;          
        }
      }
      &-right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &.mainmap-sl-step-name-info-wrapper {
          background: transparent;
          padding: 0.5rem;
          &--active {
            background: #fff3b7;
          }
        }
        &.mainmap-sl-step-name-info {
          display: flex;
          align-items: center;
          margin-top: .125rem;
          color: #caced5;
          cursor: pointer;
          border-radius: 1.5rem;
          position: relative;
          width: 1.5rem;
          height: 1.5rem;
          padding: 0;

          &:hover {
            color: #ffd500;
          }

          .mainmap-sl-step-name-info-popup {
            position: absolute;
            right: 0%;
            top: 0rem;
            background: #fff3b7;
            transform: translate(0.5rem, 2rem);
            width: 24rem;
            border-radius: 0.25rem;
            padding: 0.5rem;
            color: #000;
            font-size: 0.875rem;
            display: none;
          }

          &--active {
            background-color: #ffd500;

            .mainmap-sl-step-name-info-popup {
              display: block;
              z-index: 1024;
              font-family: 'Source Sans Pro', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: .75rem;
              line-height: 1rem;
              color: #6C757D;

            }
          }
        }
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .25rem;
    gap: 0.5rem;

    /* Neutral/grey-4 */

    border-radius: .25rem;
    background-color: #f0f0f3;

    /* Inside auto layout */

    flex: none;
    flex-grow: 1;
    &-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: .25rem 1.5rem;
      gap: .625rem;

      /* Neutral/grey-4 */

      background: #f0f0f3;
      border-radius: .25rem;

      /* Inside auto layout */

      flex: none;
      flex-grow: 1;
      border: 0;
    }
    &-btn.active {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: .25rem 1.5rem;
      gap: .625rem;

      /* Neutral/grey-1 */

      background: #ffffff;
      color: #07827c;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
      border-radius: .25rem;

      /* Inside auto layout */

      flex: none;
      flex-grow: 1;
    }
  }

  &__body {
    flex-grow: 4;
    position: relative;
    background: #FFFFFF;
    overflow-y: scroll;
    &__no-layer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #6C757D;
        text-align: center;
      }
      &__desc {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #6C757D;
        text-align: center;
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem .75rem .625rem;
    background: #FFFFFF;
    &-btn-next {
      box-sizing: border-box;
      padding: .3125rem 1rem;
      gap: .625rem;
      background: #07827c;
      border: 1px solid #07827c;
      border-radius: .25rem;
      color: #fff;
      &-disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);        
      }
    }
    &-btn-back {
      box-sizing: border-box;
      padding: .3125rem 1rem;
      gap: .625rem;
      background: #f9fbfc;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      color: #07827c;
      &-disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);        
      }
    }
  }
}
