.DraggableList {
  &__card {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    background: #fff;
    border: 1px solid #d7dae2;
    &-drag-handler {
      padding: 1rem 0.5rem;
      background: #fbfbfb;
      border: 1px solid #d7dae2;
    }
    &-inside {
      padding: 1rem;
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      &-text {
        margin-left: 1rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        color: #4d4f5c;
        .bvt-skeleton__square {
          display: block;
          margin-right: 1rem;
        }
      }
      &-brand {
        margin-left: auto;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: left;
        color: #4d4f5c;
        span {
          font-size: 0.75rem;
          line-height: 1.25rem;
          text-align: left;
          color: #4d4f5c;
          font-weight: bold;
        }
      }
      &-trash-icon {
        margin-left: 1rem;
        width: 1.875rem;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff3939;
        transition: all 0.25s ease-out;
        cursor: pointer;
        border: 1px solid #D7DAE2;
        svg {
          width: 1rem;
          height: 1rem;
        }
        &:hover {
          transition: all 0.25s ease-out;
          background-color: #ff3939;
          color: #fff;
        }
      }
      &-edit-icon{
        margin-left: 1rem;
        width: 1.875rem;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00A8FF;
        transition: all 0.25s ease-out;
        cursor: pointer;
        border: 1px solid #D7DAE2;
        svg {
          width: 1rem;
          height: 1rem;
        }
        &:hover {
          transition: all 0.25s ease-out;
          background-color: #00A8FF;
          color: #fff;
        }
      }
    }
  }
}
