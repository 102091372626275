.CardDatexPOIDataDetail {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 12px;
    gap: 8px;
    background: #f0f0f3;
    border: 1px solid #ced4da;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    top: 5rem;
    left: 0;
    flex: 1;
    width: 100%;
    z-index: 4102;
    margin-top: 6px;
  }

  &__btn-summary {
    &-img {
      width: 12px;
      height: 12px;
    }
    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #07827c;
    }
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 10px;
    background: #f9fbfc;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 100%;
    &:hover {
      border: 1px solid #07827c;
    }
  }

  &__more {
    width: 100%;
    margin-top: 15px;
    &-wrapper {
      display: flex;
      flex-direction: row;
      border-bottom: #07827c;
      border-bottom: 1px solid #ced4da;
    }
    &-left {
      flex: 4;
      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6c757d;
      }
    }
    &-right {
      flex: 5;
      &-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #6c757d;
      }
    }
  }

  &__counter {
    width: 100%;
    display: flex;
    align-self: stretch;
    flex-grow: 0;
    position: relative;
    margin-bottom: 15px;
    &-layer1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2px;
      background: #07827c;
      border: 1px solid rgba(160, 166, 169, 0.5);
      border-radius: 6px;
      z-index: 2;
      flex: 1;
      &--left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 6px;
        &-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
        &-devices {
          font-weight: 700;
          color: #ffffff;
          &-total {
            font-size: 18px;
            line-height: 28px;
          }
          &-measurement {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      &--right {
        img {
          width: 63px;
          height: 57px;
        }
      }
    }
    &-layer2 {
      position: absolute;
      left: 3.15%;
      right: 3.15%;
      top: 15.58%;
      bottom: 6.49%;
      height: 96.6%;
      width: auto;
      z-index: 1;

      background: rgba(32, 125, 120, 0.4);
      border-radius: 6px;
    }
    &-layer3 {
      position: absolute;
      left: 7.69%;
      right: 7.69%;
      top: 35.06%;
      bottom: 0%;
      height: 87%;
      width: auto;
      z-index: 0;

      background: rgba(82, 210, 204, 0.3);
      border-radius: 8px;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    box-shadow: -1px 2px 2px -3px rgba(0, 0, 0, 0.07);

    &-header {
      padding: 8px 12px;
      gap: 8px;
      background: #f8f8fb;
      border-radius: 6px 6px 0px 0px;
      width: 100%;

      &-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #07827c;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      background: #ffffff;
      border-radius: 0px 0px 6px 6px;
      width: 100%;
    }
    &-adt {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      width: 100%;

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6c757d;
      }
    }
    &-count {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &__table {
    width: 100%;
    .ant-table-row,
    .ant-table-row-level-0 {
      box-shadow: none;
    }
    &-header {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #a0a6a9;
    }
    &-date {
      font-weight: 600;
      font-size: 16px;
      min-width: max-content;
      line-height: 26px;
      color: #495057;
      margin-left: 4px;
    }
    &-bar {
      background: #00b4e8;
      border-radius: 0px 4px 4px 0px;
      min-width: fit-content;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      padding: 8px 6px;
    }
  }
  &__page-list {
    &__page-item {
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-next,
      .ant-pagination-jump-prev,
      .ant-pagination-item:not(.ant-pagination-item-active) {
        background: #ffffff !important;
        height: 1.813rem !important;
        width: 1.813rem !important;

        .anticon svg {
          fill: #07827c;
        }
      }

      .ant-pagination-item-active {
        background-color: #07827c;
        height: 1.813rem !important;
        width: 1.813rem !important;
        border: none;

        a {
          color: #ffffff;
        }
      }

      .ant-pagination-disabled {
        .anticon svg {
          fill: #a0a6a9;
        }
      }

      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 14px;
    }
  }
  &__modal {
    padding: 0;
    width: 364px !important;
    &-more {
      margin-top: 16px;
    }
    &-device {
      margin-top: 16px;
    }
    &-adt {
      margin-top: 32px;
      box-shadow: -1px 2px 2px -3px rgba(0, 0, 0, 0.07);
    }
    &-layer-title {
      display: flex;
      align-items: center;
      gap: 6px;
      border-bottom: 1px solid #ced4da;
      padding: 10px 0px;
      &-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        color: #495057;
      }
    }
  }
}

.ant-modal-wrap.ant-modal-centered:has(> .CardDatexPOIDataDetail__modal) {
  z-index: 2000 !important;
}
