.MainMapControlcomponent {
  &__wrapper {
    position: absolute;
    right: 20px;
    top: 9rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    box-shadow: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.051));
  }

  &__button {
    padding: 0.725rem;
    background-color: #fff;
    border-radius: 0.5rem;
    border: 1px solid #fff;
    transition: 0.25s ease-in-out;
    color: #495057;
    display: flex;
    align-items: center;
    height: auto;
    cursor: pointer;
    .ant-wave{
      display: none;
    }
    &::after {
      all: unset;
    }
    &--middle {
      border-radius: 0rem;
    }
    &--top {
      border-radius: 0.5rem 0.5rem 0px 0px;
    }
    &--bottom {
      border-radius: 0px 0px 0.5rem 0.5rem;
    }
    &:hover{
      &.ant-btn{
        border: 1px solid #fff;
        color: #495057;
      }
    }
    &:hover:not(.MainMapControlcomponent__button--disabled) {
      color: #007d74;
      border: 1px solid #fff;
      transition: 0.25s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    }
    &:disabled,
    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
