.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;

  &__input {
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
  }
}
