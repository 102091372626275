.PreviewDetailPOIData {
  &__container {
    background: #f8f8fb;
    border-radius: 0px;
    width: 100%;
    padding-top: 0.75rem;
  }
  &__dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem;
    gap: 0.5rem;
    isolation: isolate;
    width: 100%;
  }
  &__menu {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
      background-color: #ffffff;
    }
    padding: 0.5rem 1rem;
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    &--item {
      padding: 0;
      &:last-of-type {
        .PreviewDetailPOIData__menu--item-header,
        .PreviewDetailPOIData__menu--item-content {
          color: #07827c;
        }
      }
      .ant-dropdown-menu-title-content {
        display: flex;
        width: 100%;
      }
      &-header {
        width: 50%;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: #4d4f5c;
      }
      &-content {
        width: 50%;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: #4d4f5c;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &-icon {
          margin-right: 0.375rem;
        }
      }
    }
  }
  &__space {
    width: 100%;
    background: #f8f8fb;
    height: fit-content;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.625rem;
    display: flex;
    align-items: center;
    color: #6c757d;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    span {
      display: flex;
      gap: 0.5rem;
    }
  }
  &__type {
    background: #ffffff;
    display: flex;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    &--show-as {
      flex-grow: 1;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      display: flex;
      align-items: center;
      color: #6c757d;
    }
    &--container {
      display: flex;
      width: 100%;
      padding: 0.25rem 0.75rem;
    }
    &--tab {
      background-color: #f0f0f3;
      padding: 0.25rem;
      flex-grow: 1;
      border-radius: 4px;
      display: flex;
      gap: 0.5rem;
      border: 0.594958px solid #cdced2;
    }
    &--item {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      gap: 0.625rem;
      width: 100%;
      &.disabled {
        box-shadow: none;
        color: #6c757d;
        background: #f0f0f3;
        pointer-events: none;
        cursor: not-allowed;
      }
      &.active {
        color: #07827c;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.102);
        background-color: #ffffff;
        svg path {
          fill: #07827c;
        }
      }
      span {
        display: flex;
        align-items: center;
      }
      svg {
        display: flex;
        align-items: center;
      }
    }
  }
  &__sort {
    display: flex;
    background: #ffffff;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &--item {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  &__result {
    display: flex;
    flex-direction: column;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    &__table {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      &--item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        font-size: 0.875rem;
        span {
          &:first-of-type {
            color: #1d1e31;
          }
          &:last-of-type {
            color: #07827c;
            font-weight: 700;
          }
        }
        &--container {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: flex-start;
          padding: 0.625rem 0.625rem 0.625rem 1.5rem;
          background: #ffffff;
          border-radius: 6px;
        }
      }
    }
    &__chart {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      &--item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 1.5rem;
        background: #f8f8fb;
        border: 1px solid #d7dae2;
        border-radius: 4px;
        &--container {
          position: relative;
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: flex-start;
          padding: 0.625rem 1.5rem;
          background: #ffffff;
          border-radius: 6px;
        }
        &--color {
          position: absolute;
          left: -1px;
          background: #976ada;
          border: 1px solid #976ada;
          border-radius: 4px;
          width: var(--ChartItemWidth);
          height: 1.5rem;
        }
        &--content {
          display: flex;
          z-index: 10;
          justify-content: space-between;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          font-size: 0.875rem;
          width: 100%;
          padding: 0.25rem 0.5rem;
          span {
            &:first-of-type {
              color: #ffffff;
              filter: invert(.1);
            }
            &:last-of-type {
              color: #07827c;
              font-weight: 700;
            }
          }
        }
      }
    }
    &--total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #d8fffd;
      box-shadow: 0px 4px 10px rgba(122, 136, 147, 0.2);
      padding: 0.5rem 1rem 0.5rem 2.5rem;
      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #0d8c84;
        &:first-of-type {
          font-weight: 400;
        }
        &:last-of-type {
          font-weight: 700;
        }
      }
    }
  }
}
