.task-analyze {
  &.StatusButton {
    height: 100%;
    color: black;
    width: 8rem;
    background-color: transparent;
    border-radius: 0px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
 
    .statusButton {
      &__text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem;
        color: #616161;
        margin-right: 1.5rem;
      }

      &__loading-icon {
        margin-right: 0.5rem;
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &__drop-icon {
        transform: rotate(0deg);
        transition: 0.25s ease-in;

        &--active {
          transform: rotate(180deg);
          transition: 0.25s ease-in;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

